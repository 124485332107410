import React from 'react'

import Border from '../Border'

import PortalModal from "./PortalModal"

import LazyLoadingList from "../common/LazyLoadingList"

import { List, Grid } from "react-virtualized";

import Image from '../blocks/editable/Image'

import General from '../../utils/General'
import FetchHelper from '../../utils/FetchHelper'
import Notify from '../../utils/Notify'

export default class ImagePickerModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      images: [],
      searchTerm: '',
      type: props.type,
      endpoint: `${window.Api.ImagesSearch}?type=${props.type}`,
      showLoadMore: false
    }
  }

  componentDidMount(){
    this._loadImages()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _search = General.debounce(searchTerm => {
    console.log("3333", searchTerm)
    this.setState({
      searchTerm,
      images: [],
      endpoint: `${window.Api.ImagesSearch}?search_term=${searchTerm}&type=${this.state.type}` ,
      showLoadMore: false,
    }, () => this._loadImages())
  }, 500, false)

  _loadImages(){
    let {
      type,
      images,
      endpoint
    } = this.state

    this.setState({ loading: true })

    FetchHelper.get(endpoint, false)
    .then(response => {
      endpoint = response.next
      let showLoadMore = endpoint != null
      images = [
        ...images,
        ...response.results
      ]
      this.setState({
        images,
        endpoint,
        showLoadMore,
        loading: false
      })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _renderLoadMore(){
    let {
      loading,
      showLoadMore,
    } = this.state

    if(loading){
      return <p className="w-100 text-center">Loading...</p>
    }

    if(!showLoadMore){
      return null
    }

    return (
      <div className="col-12">
        <a
          className="btn btn--secondary w-100"
          style={{
            color: "#222",
            borderColor: "#222",
            backgroundColor: "transparent"
          }}
          onClick={() => this._loadImages()}
          disabled={!showLoadMore}
        >
            Load More
        </a>
      </div>
    )
  }

  _renderImage(image, attribution){
    let {
      type
    } = this.state

    return (
      <div className="col-md-6 mb-3 tile-img">
        <Image
          className="image-search-thumbnail"
          src={image.banner}
          type={this.props.type}
          primaryColor={this.props.primaryColor}
          onClick={() => this.props.onSelected(image)}
        />
        { type !== "illustration" &&
          <span>
            <span className="image-search-thumbnail-text">{ "by " }</span>
            <a
              href={image.photographer.url}
              target="_blank"
              className="image-search-thumbnail-text"
            >
             {image.photographer.full_name}
            </a> <br></br>
            <span className="image-search-thumbnail-text">{ " on " }</span>
            <a
              href={`//unsplash.com${attribution}`}
              target="_blank"
              className="image-search-thumbnail-text"
            >
            Unsplash
            </a>
          </span>
        }
      </div>
    )
  }

  render() {
    let {
      show,
      images,
      loading,
      searchTerm,
      showLoadMore,
      type
    } = this.state

    let attribution = "?utm_source=amply&utm_medium=referral"

    let title = "Amply Image Library"
    if(type === "illustration"){
      title = "Amply Illustration Library"
    }

    return (
      <PortalModal
        show={show}
        id='icon-picker-modal'
        contentSuperContainerClassName="image-picker-modal"
        onHide={() => this.props.onHide()}
      >
        <h3>{title}</h3>

        <Border
          className="mb-0"
        >

            <div className="form-group">
              <input
                type="text"
                name="search"
                placeholder="Search"
                className="form-control form-control-lg mb-3"
                defaultValue={searchTerm}
                onChange={e => {
                  let searchTerm = e.target.value
                  this._search(searchTerm)
                }}
              />
            </div>

            <div className={"row box-images mx-0 type-" + type}>
              { images.map(image => this._renderImage(image, attribution)) }
              { this._renderLoadMore() }
            </div>


            <div className="">
              <a
                className="btn btn--primary w-100 mt-4 ml-0"
                style={{
                  color: "white",
                  borderColor: "#222",
                  backgroundColor: "#222"
                }}
                onClick={() => this.props.onHide()}
              >
                  Cancel
              </a>

            </div>


        </Border>
      </PortalModal>
    )
  }
}

ImagePickerModal.defaultProps = {
  type: "photo",
  primaryColor: "#6c63ff"
}
