import React from "react";

import renderHTML from "react-render-html";

import Search from "../components/Search";

import JobUtils from "../utils/Job";
import Backend from "../utils/Backend";
import General from "../utils/General";
import {Helmet} from "react-helmet";

export default class Job extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job: null,
      loading: true,
      website: props.website,
    };
  }

  componentDidMount() {
    let pathname = window.location.pathname;

    let parts = pathname.split("/jobs/");
    let businessSlug = parts[0].replace("/companies");
    let jobSlug = parts[1];

    Backend.getJob(jobSlug, businessSlug)
      .then((job) => {
        this.setState({ job, loading: false });
      })
      .catch((error) => {});
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _formattedSalary = (salary) => {
    return salary.toLocaleString();
  };

  _renderMeta() {
    let { job, website } = this.state;

    if (website == null || job == null) {
      return null;
    }

    let name = `Apply for ${job.title} at ${website.name} in ${JobUtils.getLocation(job)}`;
    let description = `Are you looking for a job in ${website.name}? Here you can view the full job description and apply to ${job.title} with a couple of clicks.`

    let favIconUrl = website.favicon
      ? website.favicon.original
      : "/amply_favicon_32x32px.png";

    return (
      <Helmet>
        <title>{name}</title>
        <meta
          name="description"
          content={description}
        />
        <meta name="keywords" content={website.keywords} />
        <link rel="icon" href={favIconUrl} />
        <meta property="og:title" content={name} />

        <meta property="og:image" content={favIconUrl} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
    );
  }

  render() {
    let { job, loading } = this.state;
    if (loading) {
      // todo show loading state
      return (
        <div class="row">

          {/* starts, column right, sticky sidebar  */}
          <div class="col-md-2"/>

          <div class="col-md-8">

            <div class="w-100 shimmer" style={{ marginTop: '0vh', height: '8vh'}}/>

            <div class="w-100" style={{ height: '5vh'}}/>

            <div class="shimmer" style={{ height: '7vh', width: '50%' }}/>

            <div class="shimmer" style={{ height: '5vh', width: '35%', marginTop: '3vh' }}/>

            <div class="w-100" style={{ height: '5vh'}}/>

            <div class="shimmer" style={{ height: '15vh', width: '80%' }}/>

            <div class="w-100" style={{ height: '5vh'}}/>

            <div class="w-100 shimmer" style={{ height: '22vh', marginBottom: '50vh'}}/>


          </div>

          <div class="col-md-2"/>

        </div>
      );
    }

    const hasJobContractAndSalary = !!(
      job.contract &&
      job.salary_from &&
      job.salary_to
    );

    return (
      <div className="pages jobs job">
        {this._renderMeta()}
        <Search variation={"full-with-sticky"} />

        <div class="container main-container job-container">
          <div className="row mx-0">
            <div className="col-md-9 description">
              <h1 className="banner-h3 my-4 title-wrap">{job.title}</h1>
              <h4 className="text-muted">{JobUtils.getLocation(job)}</h4>
              <h4
                className={`text-dark ${
                  !hasJobContractAndSalary && "my-2"
                }`}
                style={{ marginTop: "-20px" }}
              >
                {hasJobContractAndSalary
                  ? `€${this._formattedSalary(
                      job.salary_from
                    )} -  €${this._formattedSalary(
                      job.salary_to
                    )} a year - ${General.toTitleCase(
                      job.contract.replace("-", " ")
                    )}`
                  : ""}
              </h4>
              <div className="my-4">
                <button
                  className="btn c-btn c-btn-secondary"
                  target="_blank"
                  href={"javascript:;"}
                  onClick={() => {
                    Backend.saveExternalJobApplication(job);
                    window.open(job.external_application_url, "_blank");
                  }}
                >
                  {window.Branding.Terminology.apply}
                </button>
              </div>
              <hr />
              <h2>{window.Branding.Terminology.job_description}</h2>
              <div className="content">
                {job.description && renderHTML(job.description)}
              </div>
              <button
                className="btn c-btn c-btn-secondary"
                target="_blank"
                href={"javascript:;"}
                onClick={() => {
                  Backend.saveExternalJobApplication(job);
                  window.open(job.external_application_url, "_blank");
                }}
              >
                {window.Branding.Terminology.apply}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
