import React from 'react'

import Field from './Field'

export default class Row extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: props.data,
      fieldStartIndex: props.fieldStartIndex,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _renderFields(){
    let { fields, fieldStartIndex } = this.state

    return fields.map((field, mapIndex) => {
      if(mapIndex > 0){
        fieldStartIndex += 1
      }
      return (
        <Field
          key={ field.id }
          data={ field }
          weight={ fields.length }
          index={ mapIndex }
          startIndex={ fieldStartIndex }
          onUpdated={(index, value) => {
            this.props.onUpdated(index, value)
          }}
        />
      )
    })
  }

  render() {
    let {
      fields,
    } = this.state

    return (
      <div
        className="form-row-container"
      >
        <div
          key={fields.length}
          className="row form-row align-items-center"
        >
          {this._renderFields()}
        </div>
      </div>
    )
  }
}
