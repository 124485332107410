import React from 'react'
import Collapsible from 'react-collapsible';

import Border from '../Border'

import H5 from './editable/H5'
import Img from './editable/Img'
import P from './editable/P'
import Span from './editable/Span'
import BlockQuote from './editable/BlockQuote'
import Section from './editable/Section'

import Dropdown from "../Dropdown";

import TextInfoInputItem from '../sectioninputs/TextInfoInputItem'

import JobUtils from "../../utils/Job";
import General from '../../utils/General'
import Backend from '../../utils/Backend'
import FetchHelper from '../../utils/FetchHelper'

import Search from "../Search";

import Titles from './editable/Titles';

let VARIANTS = [
  {
    value: "list",
    label: "List View"
  },
  {
    value: "card",
    label: "Card View"
  },
]

export default class Block58 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable,
      jobs: [],
      isLoading: true,
      uuid: "cb_"+General.uuid()
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)

      this.setState({
        key: this.state.key + 1
      }, () => General.updateSlides())
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount(){
    let { data } = this.state
    let companySlug = window.CURRENT_WEBSITE.extra?.company?.slug
    let url = null
    if(!companySlug){
      url = `${window.Api.Jobs}?website_id=${window.CURRENT_WEBSITE.id}&page_size=6`
    }
    else{
      url = `${window.Api.JobbioJobs}/${companySlug}/feed?&page_size=6`
    }

    FetchHelper.get(url, false, false)
    .then(response => {
      this.setState({ jobs: response.results, isLoading: false })
    })
  }

  _update(data, callback){
    let {
      key
    } = this.state

    this.setState({ ...data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _getModalContent(data){

    let variation = data.variation || "list"

    return (
        <Border>
          <Collapsible trigger="Jobs Style">
            <br/>
            <span>Form Style</span>
            <Dropdown
              options={VARIANTS}
              value={VARIANTS.find(mode => mode.value === data.variation)}
              onChange={option => {
                data.variation = option.value
                this._update(data)
              }}
              placeholder="Please select an option"
            />
          </Collapsible>
        </Border>
      )
    }



  render(){
    let { data, editable, uuid, key, jobs, isLoading } = this.state

    let cssClasses = "results items "
    let variation = data.variation || "list"

    cssClasses += variation == "list" ? "list" : "grid"

    return (
      <Section
          ref={this.section}
          data={ data }
          index={ this.props.index }
          className={ " block-58 text-center " + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() => this._getModalContent(data)}
          primaryColor={false}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div class="container">

            <Titles
              data={ data }
              editable={ editable }
              headingNumber={2}
              onUpdated={data => this._update(data)}
            />

            <div className={cssClasses}>
              {
                jobs.map(job => {

                  let url = job.external_application_url

                  if(!window.CURRENT_WEBSITE.search_result_redirect && job.business){
                    url = `/companies/${job.business.slug}/jobs/${job.slug}`
                  }
                  else if(!url){
                    url = job.redirect || `https://jobbiodev.com/talent/register?search_job&job=${job.id}&source=amply`
                  }

                  return (
                    <a
                      className='item'
                      href={url}
                      onClick={e => {
                        if(!window.CURRENT_WEBSITE.search_result_redirect || !job.external_application_url){
                          return
                        }
                        e.preventDefault();
                        Backend.saveExternalJobApplication(job)
                        window.open(job.external_application_url, '_blank')
                      }}
                    >
                      { job.external_impression_url &&
                        <img
                          width={1}
                          height={1}
                          className="indeed-tmn-pxl"
                          data-src={job.external_impression_url}
                          style={{display: 'block', marginBottom:-1}}
                        />
                      }
                      <span className='c1' style={{color: data.settings.secondary_color || "#666666"}}>
                        <span className='title'>{job.title}</span>
                        {!window.CURRENT_WEBSITE.hide_company_names &&
                          <span className='name'>{job.business?.name || job.company?.name}</span>
                        }
                        <span className='location'>{JobUtils.getLocation(job)}</span>
                        <span className='published'>{job.published}</span>
                      </span>
                      <button className='btn c-btn'>{window.Branding.Terminology.view_job}</button>
                    </a>
                  )
                })
              }
            </div>
            <div className='all'>
              <button
                className='btn c-btn'
                onClick={() => {
                  window.location.href = "/jobs"
                }}
              >
                {window.Branding.Terminology.view_jobs}
              </button>
            </div>
          </div>
      </Section>
    )
  }
}
