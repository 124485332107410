import React from 'react'


export default class Socials extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      socials: props.socials || {},
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderMail(mail){
    if(!mail){
      return null
    }
  }

  _renderSocial(icon, url){
    if(!url){
      return null
    }

    if(icon === "mail"){
      url = "mailto:"+url
    }

    return (
      <li className="list-inline-item">
        <a href={url} target="_blank">
          <i style={{ color: this.props.color }} className= {"socicon socicon-"+icon+" icon icon--xs"}>
          </i>
        </a>
      </li>
    )
  }

  _renderSocialSvg(icon, url){
    if(!url){
      return null
    }
    return (
      <li className="list-inline-item">
        <a href={url} className="svg-socicon" target="_blank">
          {icon === "twitter" &&
            <svg className= {"svg-socicon-"+icon+""} fill={this.props.color} viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve"><path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z"></path></svg>
          }
          {icon === "tiktok" &&
            <svg className= {"svg-socicon-"+icon+""} fill={this.props.color} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve"><path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z"/></svg>
          }
        </a>
      </li>
    )
  }

  render(){
    let {
      socials,
    } = this.state

    if(!socials){
      return null
    }

    return (
      <ul className="social-list list-inline list--hover">
          { this._renderSocial("mail", socials.mail) }
          { this._renderSocial("internet", socials.website) }
          { this._renderSocialSvg("twitter", socials.twitter) }
          { this._renderSocial("instagram", socials.instagram) }
          { this._renderSocial("facebook", socials.facebook) }
          { this._renderSocial("linkedin", socials.linkedIn) }
          { this._renderSocialSvg("tiktok", socials.tiktok) }
          { this._renderSocial("youtube", socials.youtube) }
      </ul>
    )
  }
}
