import React from 'react'

import moment from 'moment'
import Datetime from 'react-datetime'

import General from '../utils/General'

import PortalModal from './modal/PortalModal'

export default class CountdownTimer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      time: props.time ? new Date(props.time) : null,
      color: props.color,
      minDate: new moment(),
      editable: props.editable,
      showModal: false,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      ...nextProps,
      time: nextProps.time ? new Date(nextProps.time) : null,
    }, () => {
      if(!window.mr){
        return
      }
      window.mr.countdown.documentReady(window.$)
    })
  }

  _renderModal(time, minDate){
    return (
      <PortalModal
        show={this.state.showModal}
        onHide={() => this.setState({ showModal: false})}
      >
          <h2>Countdown Settings</h2>
          <Datetime
            value={ time }
            input={false}
            timeFormat={false}
            onChange={date => {
              this.props.onUpdated(date.toDate())
              this.setState({ showModal: false })
            }}
            isValidDate={date => {
              return date > minDate
            }}
            renderDay={(props, currentDate, selectedDate) => {
              let classNames = currentDate.isSame(selectedDate) ? "selected-day" : ""
              return (
                <td {...props}>
                    <div>
                    { currentDate.date() }
                    </div>
                </td>
              );
            }}
            className="react-datetime"
          />
      </PortalModal>
    )
  }

  _renderEditable(time, minDate, editable){
    if(!editable){
      return null
    }

    return (
      <>
        <div className="block">
            <a onClick={() => this.setState({ showModal: true })}>
              <div className="edit edit-countdown">
                <i className="icon icon--xs icon-Pencil"/>
              </div>
            </a>
        </div>
        { this._renderModal(time, minDate)}
      </>
    )
  }

  render() {
    let {
      color,
      time,
      minDate,
      editable
    } = this.state
    if(!time){
      return null
    }

    let date = moment(time)
    let style = {
      color: color || "white"
    }

    return (
        <div>
          <div className="countdown-container inline-block">
              <span className="h2 countdown" data-date={ date.format("MM/DD/YYYY") } data-date-fallback="Event" style={style}/>
              { this._renderEditable(time, minDate, editable) }
          </div>
        </div>
    )
  }
}
