import React from 'react'

import TextField from '@material-ui/core/TextField';

export default class Text extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
      index: props.index
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let { data, index } = this.state

    let type = data.type
    let errorMessage = "This field is required."

    if(type === "phone" || type === "contact_phone"){
      errorMessage = "Please enter a valid phone number."
    }

    if(type === "email" || type === "contact_email"){
      errorMessage = "Please enter a valid email address."
    }

    return (
      <>
        <TextField
          className='field-text'
          error={data.error}
          helperText={data.error ? errorMessage : ""}
          type={ type }
          label={data.title}
          fullWidth
          variant={"outlined"}
          inputProps={{id: `form-input-${data.id}`}}
          value={data.answer?.value || ''}
          onChange={e => {
            let value = {field: data.id, value: e.target.value};
            data.error = false
            data.answer = value
            this.setState({data})
            this.props.onUpdated(index, value)
          }}
        />
      </>
    )
  }
}
