import React from "react";

export default class Youtube extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoId: props.videoId,
    };
  }

  render() {
    let { videoId } = this.state;
    return (
      <div class="yt-video-container">
        <iframe
          width="100%"
          height="100%"
          className="yt-video"
          src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1&mute=1`}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
      </div>
    );
  }

}