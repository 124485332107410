import React from 'react'

import Button from '../Button'

import H2 from './editable/H2'
import P from './editable/P'
import Section from './editable/Section'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

export default class Block35 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable
    }

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }
  
  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  render(){
    let { data, editable } = this.state
    let showTitle = data.title
    return (
      <Section
          data={ data }
          index={ this.props.index }
          className={ " block-35 text-center " + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          primaryColor={false}
          secondaryColor={false}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="container">
              <div className="row">
                  <div className="col-md-8 col-lg-6">
                      <div className="cta">

                        {showTitle &&
                          <H2
                            text={ data.title}
                            color={ data.title_color }
                            editable={ editable }
                            onUpdated={text => {
                              data.title = text
                              this._update(data)
                            }}
                          />
                        }
                        
                          <Button
                            data={ data.primary_button }
                            editable={ editable }
                            onUpdated={button => {
                              data.primary_button = button
                              this._update(data)
                            }}
                          />
                          <span className="block type--fine-print">
                            <Button
                              data={data.link_button}
                              editable={ editable }
                              type="text_underline"
                              onUpdated={button => {
                                data.link_button = button
                                this._update(data)
                              }}
                            />
                          </span>
                      </div>
                  </div>
              </div>
          </div>
      </Section>
    )
  }
}
