import React from "react";

import Border from "../Border";

import H4 from "./editable/H4";
import H5 from "./editable/H5";
import Img from "./editable/Img";
import P from "./editable/P";
import Span from "./editable/Span";
import BlockQuote from "./editable/BlockQuote";
import Section from "./editable/Section";

import Collapsible from "react-collapsible";
import ImageInputItem from "../sectioninputs/ImageInputItem";

import General from "../../utils/General";
import Backend from "../../utils/Backend";

import LinkSelect from "../LinkSelect";

import Titles from './editable/Titles';

export default class Block53 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable,
      uuid: "cb_" + General.uuid(),
    };

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(
      () => {
        console.log("3333", this.state.data);
        Backend.updateContentBlock(this.state.data);

        this.setState(
          {
            key: this.state.key + 1,
          },
          () => General.updateSlides()
        );
      },
      1000,
      false
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _update(data, callback) {
    let { key } = this.state;

    this.setState({ ...data }, () => {
      if (callback) {
        callback();
      }
      this.updateContentBlock();
    });
  }

  _renderModalContent(data) {
    return (
      <>
        <Border>{this._renderTilesContent(data)}</Border>
      </>
    );
  }

  _renderModalContentImages(data) {
    return data.image_infos.map((imageInfo, index) => {
      return (
        <>
          <Collapsible trigger={`Tile ${index + 1}`}>
            <br />

            <>
              <div className="row mb-2">
                <div className="col-md-8">
                  <div className="mt-2">Should This Tile Be Clickable?</div>
                </div>
                <div className="col-md-4 text-right">
                  <div className="input-checkbox input-checkbox--switch">
                    <input
                      id={`menu__cb_pb_${index}`}
                      type="checkbox"
                      checked={imageInfo.button != null}
                      onChange={(e) => {
                        if (e.target.checked) {
                          imageInfo.button = General.clone(
                            DEFAULT_PRIMARY_BUTTON
                          );
                        } else {
                          imageInfo.button = null;
                        }

                        data.image_infos[index] = imageInfo;

                        this._update(data);
                      }}
                    />
                    <label for={`menu__cb_pb_${index}`}></label>
                  </div>
                </div>
              </div>
            </>

            {imageInfo.button && (
              <div className="mb--1">
                <span>Link</span>
                <LinkSelect
                  url={imageInfo?.button?.url}
                  onUpdated={(url) => {
                    if (!imageInfo.button) {
                      imageInfo.button = DEFAULT_PRIMARY_BUTTON;
                    }
                    imageInfo.button.url = url;
                    data.image_infos[index] = imageInfo;

                    this._update(data);
                  }}
                />
              </div>
            )}

            {imageInfo.button && !imageInfo.button?.url?.startsWith("/#") && (
              <>
                <span>Open In New Tab</span>
                <select
                  className="mb-3"
                  value={imageInfo?.button?.open_in_new_tab}
                  onChange={(event) => {
                    if (!imageInfo.button) {
                      imageInfo.button = DEFAULT_PRIMARY_BUTTON;
                    }
                    imageInfo.button.open_in_new_tab =
                      event.target.value === "true";
                    data.image_infos[index] = imageInfo;

                    this._update(data);
                  }}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </>
            )}

            <ImageInputItem
              key={imageInfo.image.id}
              index={index}
              nameTag="span"
              image={imageInfo.image}
              onUpdated={(uploadedImage) => {
                data.image_infos[index].image = uploadedImage;
                this._update(
                  {
                    data,
                    key: this.state.key + 1,
                  },
                  () => General.updateImageBackgrounds()
                );
              }}
              deletable={false}
            />
          </Collapsible>
        </>
      );
    });
  }

  _renderTilesContent(data) {
    return (
      <Collapsible trigger="Tiles">
        <br />
        {this._renderModalContentImages(data)}
      </Collapsible>
    );
  }

  render() {
    let { data, editable, uuid, key } = this.state;
    let blockClassName = " block-53 unpad text-center ";
    let containerClassName;
    let rowClassName = " row--gapless row no-gutters ";
    if (window.IS_CARD_STYLE_WEBSITE) {
      blockClassName += " deep-cardify";
      containerClassName = "container items";
      rowClassName = " row ";
    }
    return (
      <Section
        ref={this.section}
        data={data}
        index={this.props.index}
        className={ blockClassName +this.props.className }
        editable={editable}
        onUpdated={(data) => this._update(data)}
        onDelete={() => this.props.onDelete()}
        primaryColorTitle="Text Colour"
        secondaryColor={false}
        onAddContentBlockPressed={(contentBlock) =>
          this.props.onAddContentBlockPressed(contentBlock)
        }
        renderModalContent={() => this._renderModalContent(data)}
        canMoveUp={this.props.canMoveUp}
        canMoveDown={this.props.canMoveDown}
        onMoveUpPressed={() => this.props.onMoveUpPressed()}
        onMoveDownPressed={() => this.props.onMoveDownPressed()}
      >
        <div class={containerClassName}>

          <Titles 
            data={ data }
            editable={ editable }
            onUpdated={data => this._update(data)}
            headingNumber={2}
          />

          <div className={rowClassName}>
            {data.image_infos.map((imageInfo, index) => {
              return (
                <div key={imageInfo.image.id} class="col-md item">
                  <a href="javascript:void(0)" class="block">
                    <div
                      class="feature feature-7 boxed mb-0 imagebg dc-padding dc-skip"
                      data-overlay="3"
                      onClick={() => {
                        if (!imageInfo.button?.url) {
                          return;
                        }

                        if (imageInfo.button?.open_in_new_tab) {
                          window.open(imageInfo.button?.url);
                        } else {
                          window.location.href = imageInfo.button?.url;
                        }
                      }}
                    >
                      <div class="background-image-holder">
                        <Img
                          key={imageInfo.image.id}
                          img={imageInfo.image}
                          alt="background"
                          block={"53"}
                          priority={this.props.index}
                        />
                      </div>

                      <H4
                        text={imageInfo.title}
                        className="pos-vertical-center"
                        color={data.settings.primary_color}
                        editable={editable}
                        onUpdated={(text) => {
                          data.image_infos[index].title = text;
                          this._update(data);
                        }}
                      />
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </Section>
    );
  }
}

export const DEFAULT_PRIMARY_BUTTON = {
  settings: {
    primary_color: "#ffffff",
  },
  badge_settings: {
    primary_color: "#ffffff",
    secondary_color: null,
    tertiary_color: "#007ec1",
  },
  title: null,
  url: "#",
  badge: null,
  open_in_new_tab: false,
};
