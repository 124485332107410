import React from "react";

import Button from "../Button";

import H2 from "./editable/H2";
import P from "./editable/P";
import Img, { Sizes } from "./editable/Img";
import Section from "./editable/Section";

import General from "../../utils/General";
import Backend from "../../utils/Backend";

export default class Block16 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      editable: props.editable,
    };

    this.updateContentBlock = General.debounce(
      () => {
        console.log("3333", this.state.data);
        Backend.updateContentBlock(this.state.data);
      },
      1000,
      false
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _update(data, callback) {
    this.setState({ data }, () => {
      if (callback) {
        callback();
      }
      this.updateContentBlock();
    });
  }

  render() {
    let { data, editable } = this.state;
    let showTitle = data.title

    let buttonGroupCSS = "button-group"
    if(!data.primary_button || !data.secondary_button){
      buttonGroupCSS += " btn-single"
    }

    return (
      <Section
        data={data}
        index={this.props.index}
        className={
          "imageblock block-16 switchable height-100 switchable--switch " +
          this.props.className
        }
        editable={editable}
        onUpdated={(data) => this._update(data)}
        onDelete={() => this.props.onDelete()}
        primaryButtonToggle={true}
        secondaryButtonToggle={true}
        onAddContentBlockPressed={(contentBlock) =>
          this.props.onAddContentBlockPressed(contentBlock)
        }
        image={data.foreground_image}
        imageTitle={"Right Image"}
        onImageUpdated={(image) => {
          data.foreground_image = image;
          this._update(data, () => General.updateImageBackgrounds());
        }}
        canMoveUp={this.props.canMoveUp}
        canMoveDown={this.props.canMoveDown}
        onMoveUpPressed={() => this.props.onMoveUpPressed()}
        onMoveDownPressed={() => this.props.onMoveDownPressed()}
        primaryColor={false}
      >
        <div className="imageblock__content col-lg-6 col-md-4 pos-right">
          <div className="background-image-holder align-right lazy-background">
            <Img
              alt=""
              img={data.foreground_image}
              block={16}
              priority={this.props.index}
            />
          </div>
        </div>
        <div className="container pos-vertical-center">
          <div className="row">
            <div className="col-lg-5 col-md-7">
              
              {showTitle && 
                <H2
                  text={data.title}
                  color={data.title_color}
                  editable={editable}
                  textAlign="left"
                  onUpdated={(text) => {
                    data.title = text;
                    this._update(data);
                  }}
                />
              }
              
              <P
                text={data.body}
                color={data.settings.secondary_color}
                editable={editable}
                textAlign="left"
                onUpdated={(text) => {
                  data.body = text;
                  this._update(data);
                }}
              />
              <div className={buttonGroupCSS}>
                <Button
                  data={data.primary_button}
                  editable={editable}
                  onUpdated={(button) => {
                    data.primary_button = button;
                    this._update(data);
                  }}
                />
                <Button
                  data={data.secondary_button}
                  editable={editable}
                  onUpdated={(button) => {
                    data.secondary_button = button;
                    this._update(data);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
    );
  }
}
