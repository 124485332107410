import React from 'react'

import H3 from './editable/H3'
import P from './editable/P'
import Section from './editable/Section'

import Map from '../Map'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

export default class Block33 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable
    }

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }
  
  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  render(){
    let { data, editable } = this.state
    return (
      <Section
          data={ data }
          index={ this.props.index }
          className={ "section-33 block-33 text-center " + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="container">

            <Titles 
              data={ data }
              editable={ editable }
              onUpdated={data => this._update(data)}
              headingNumber={2}
            />

              <div className="row justify-content-center">
                  <div className="col-md-12 col-12 mb-5">
                      <div className="map-container b-radius-0">
                          <Map
                            map={ data.map }
                            google={ this.props.google }/>
                      </div>
                  </div>
                  <div className="col-md-8 col-lg-6 col-12">
                      <H3
                        text={ data.title}
                        color={ data.settings.primary_color }
                        editable={ editable }
                        onUpdated={text => {
                          data.title = text
                          this._update(data)
                        }}
                      />
                      <P
                        text={ data.body }
                        color={ data.settings.secondary_color }
                        editable={ editable }
                        className="like-pre"
                        onUpdated={text => {
                          data.body = text
                          this._update(data)
                        }}
                      />
                  </div>
              </div>
          </div>
      </Section>
    )
  }
}
