import React from "react";
import Collapsible from "react-collapsible";
import Border from "./Border";

import NativeListener from "react-native-listener";

import ButtonSettingsModal from "./modal/settings/ButtonSettingsModal";

import PortalModal from "./modal/PortalModal";

import General from "../utils/General";

import Toggle from "./sectioninputs/Toggle";

import IconPickerGrid from "./IconPickerGrid";

import ImageUploader from "./blocks/editable/ImageUploader";

import Youtube from './Youtube'

import $ from "jquery";

export default class Play extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      overlay: props.overlay,
      icon: props.icon,
      iconImage: props.iconImage,
      videoUrl: props.videoUrl,
      className: props.className,
      editable: props.editable,
      showEditModal: false,
      showVideoModal: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderVideoModal(videoUrl) {
    let { overlay } = this.state;

    let videoId = General.getYoutubeVideoId(videoUrl);

    let videoSize = "yt-video-md";

    if (overlay == 3) {
      videoSize = "yt-video-sm";
    } else if (overlay == 9) {
      videoSize = "yt-video-lg";
    }

    return (
      <PortalModal
        show={this.state.showVideoModal}
        showAsSideMenu="false"
        contentSuperContainerClassName={`bg-dark ${videoSize}`}
        contentContainerClassName=""
        onHide={() => this.setState({ showVideoModal: false })}
      >
        <Youtube
          videoId={videoId}
        />
      </PortalModal>
    );
  }

  _renderEditModal(videoUrl) {
    let { overlay, icon, iconImage } = this.state;

    return (
      <PortalModal
        show={this.state.showEditModal}
        onHide={() => this.setState({ showEditModal: false })}
      >
        <h2>Video Settings</h2>

        <Border>
          <Collapsible trigger="Youtube Video Url">
            <br></br>
            <input
              type="text"
              defaultValue={videoUrl}
              placeholder="https://www.youtube.com/watch?v=DGQwd1_dpuc"
              onChange={(event) => {
                videoUrl = event.target.value;
                this.props.onUpdated(videoUrl, icon, iconImage, overlay);
              }}
            />
          </Collapsible>
        </Border>

        <Border>
          <Collapsible trigger="Youtube Video Size">
            <br></br>

            <select
              value={overlay || "6"}
              onChange={(event) => {
                overlay = event.target.value;
                this.props.onUpdated(videoUrl, icon, iconImage, overlay);
              }}
            >
              <option value="3">Small</option>
              <option value="6">Medium</option>
              <option value="9">Large</option>
            </select>
          </Collapsible>
        </Border>

        <Border>
          <Collapsible trigger="Icon">
            <br></br>
            <Toggle
              id="button_icon_toggle"
              checked={icon != null}
              title="Enable Icon"
              onChange={(checked) => {
                if (checked) {
                  iconImage = null;
                  icon=`icon ${this.props.iconSize || "icon--sm"} icon-Add-File`
                } else {
                  icon = null;
                }

                this.props.onUpdated(videoUrl, icon, iconImage, overlay);
              }}
            />

            {icon && (
              <>
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">Default / Selected Icon : </div>
                  <div className="col-auto pl-2">
                    <i
                      className={`${icon} color--dark`}
                      style={{ fontSize: 30 }}
                    />
                  </div>
                </div>

                <div className="button-icon-picker mt-4">
                  <IconPickerGrid
                    columnWidth={110}
                    className=""
                    width={356}
                    rowHeight={110}
                    onSelected={(icon) => {
                      icon = "icon icon--lg icon-" + icon.name;
                      iconImage = null;
                      this.props.onUpdated(videoUrl, icon, iconImage, overlay);

                      this.setState({ showIconPickerModal: false });
                    }}
                  />
                </div>
              </>
            )}
          </Collapsible>
        </Border>

        <Border>
          <Collapsible trigger={"Image"}>
            <br />
            <ImageUploader
              title={""}
              type="illustration"
              src={iconImage?.original}
              onUploaded={(image) => {
                icon = null;
                this.props.onUpdated(videoUrl, icon, image, overlay);
              }}
            />
          </Collapsible>
        </Border>
      </PortalModal>
    );
  }

  _renderEditable(editable, videoUrl) {
    if (!editable) {
      return null;
    }

    return (
      <>
        <a
          href="javascript:void(0)"
          className="edit edit-button edit-button-video min-width-auto"
          onClick={() => this.setState({ showEditModal: true })}
        >
          <i className="icon icon--xs icon-Pencil" />
        </a>
        {this._renderEditModal(videoUrl)}
      </>
    );
  }

  render() {
    let { videoUrl, className, editable, icon, overlay, iconImage } =
      this.state;

    if (!videoUrl && !editable) {
      return null;
    }

    className = "min-width-auto video-play-icon ";

    let playButton = (
      <a
        href="javascript:void(0)"
        onClick={() => this.setState({ showVideoModal: true })}
        className={className}
      ></a>
    );

    return (
      <div className="modal-instance block">
        <div className="video-play relative d-inline-block">
          {!icon && !iconImage && playButton}

          {icon && (
            <a
              href="javascript:void(0)"
              onClick={() => this.setState({ showVideoModal: true })}
              className={icon}
            ></a>
          )}

          {iconImage && (
            <a
              href="javascript:void(0)"
              onClick={() => this.setState({ showVideoModal: true })}
              className="min-width-auto"
            >
              <img
                src={iconImage?.original}
                alt="Button Image"
                loading="lazy"
                style={{ maxHeight: 200 }}
              />
            </a>
          )}

          {this._renderVideoModal(videoUrl)}
          {this._renderEditable(editable, videoUrl, icon, overlay)}
        </div>
      </div>
    );
  }
}
