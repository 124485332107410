import React from 'react'
import Collapsible from 'react-collapsible';
import H4 from './editable/H4'
import P from './editable/P'
import Section from './editable/Section'

import Button from '../Button'
import Border from '../Border'

import TextInfoInputItem from '../sectioninputs/TextInfoInputItem'

import DeleteModal from '../modal/settings/DeleteModal'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

export default class Block22 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _getModalContent(data){
    return (
      <Border>
        <Collapsible
          trigger="Events"
        >
          <br/>
          { this._getModalContentEvents(data) }
        </Collapsible>
      </Border>
    )
  }

  _getModalContentEvents(data){
    let textInfos = data.text_infos
    let textInfosContent = textInfos.map((textInfo, index) => {
      return (
        <TextInfoInputItem
          key={index}
          index={index}
          textInfo={textInfo}
          onUpdated={textInfo => {
            data.text_infos[index] = textInfo
            this._update(data)
          }}
          onDelete={() => {
            if(data.text_infos.length == 2){
              alert("There must be at least 2 Timeline items")
              return
            }
            textInfos.splice(index, 1)
            data.text_infos = textInfos
            this._update(data)
          }}
        />
      )
    })

    return (
      <Border>
          { textInfosContent }
          <div className="row">
            <div className="col-md-12">
              <a className="btn"
                onClick={() => this._addTextInfo(data)}
              >
                  <span className="btn__text">
                      Add Item
                  </span>
              </a>
            </div>
          </div>
      </Border>
    )
  }

  _renderLineStyle(settings, id){
    if(!settings){
      return null
    }

    return (
      // todo remove this as it's a security risk
      // regardless of below

      // colours are sanitized by the API so can
      // only contain hexcode / word base colors
      <style dangerouslySetInnerHTML={{__html: `
        #`+ id +`.process-1:before {
          background: ` + settings.secondary_color + `;
        }
        #`+ id +` .process__item:after {
          border-color: `+ settings.secondary_color + `;
          background: ` + settings.tertiary_color + `;
        }
      `}}/>
    )
  }

  _addTextInfo(data){
    let textInfo = {
      title: "Title goes here",
      subtitle: "Subtitle goes here",
      body: "Body goes here",
      settings: {}
    }

    data.text_infos.push(textInfo)
    this._update(data)
  }

  _renderTextInfos(data, editable){
    let textInfos = data.text_infos
    if(!textInfos){
      return null
    }

    return textInfos.map((textInfo, index) => {
      return (
        <div key={ textInfo.id } className="process__item">
            <H4
              text={ textInfo.title}
              color={ data.settings.primary_color }
              editable={ editable }
              className="m-b-0"
              onUpdated={text => {
                textInfo.title = text
                textInfos[index] = textInfo
                data.text_infos = textInfos
                this._update(data)
              }}
            />
            <H4
              text={ textInfo.subtitle}
              color={ data.settings.primary_color }
              editable={ editable }
              onUpdated={text => {
                textInfo.subtitle = text
                textInfos[index] = textInfo
                data.text_infos = textInfos
                this._update(data)
              }}
            />
            <P
              text={ textInfo.body }
              color={ data.settings.primary_color }
              lead={false}
              editable={ editable }
              className="m-b-0"
              onUpdated={text => {
                textInfo.body = text
                textInfos[index] = textInfo
                data.text_infos = textInfos
                this._update(data)
              }}
            />
        </div>
      )
    })
  }

  _renderAddItem(data, editable){
    if(!editable){
      return null
    }

    return (
      <div className="text-center edit-add-agenda-item">
          <a
            className="btn type--uppercase"
            style={{
              color: data.settings.secondary_color,
              borderColor: data.settings.secondary_color,
            }}
            onClick={() => {
              this._addTextInfo(data)
            }}>
              Add Item
          </a>
      </div>
    )
  }

  render(){
    let { data, editable } = this.state

    if(data.settings.msc_enabled){
      data.settings.secondary_color = window.CURRENT_PAGE_PRIMARY_COLOR
    }

    return (
      <Section
          ref={this.section}
          data={ data }
          index={ this.props.index }
          className={ " imagebg block-22 " + this.props.className }
          editable={ editable }
          onUpdated={data => {
            data.settings.msc_enabled = false
            this._update(data)
          }}
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() => this._getModalContent(data)}
          primaryColorTitle="Text Colour"
          secondaryColorTitle="Line Colour"
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="container">

              <Titles 
                data={ data }
                editable={ editable }
                onUpdated={data => this._update(data)}
                headingNumber={2}
              />

              <div className="row justify-content-center">
                  <div className="col-lg-8 agenda">
                      <div id={ "cb_"+data.id } className="process-1">
                          { this._renderTextInfos(data, editable) }
                          { this._renderLineStyle(data.settings, "cb_"+data.id)}
                      </div>
                  </div>
              </div>

          </div>
      </Section>
    )
  }
}
