import WebFont from "webfontloader";

import almarenaRegularPath from "../assets/fonts/Almarena-Display-Regular.otf";

import almarenaLightPath from "../assets/fonts/Almarena-Display-Light.otf";

import almarenaMonoPath from "../assets/fonts/Almarena-Mono-Display-Light.otf";

const WEIGHT_SKINNY = {
  name: "Skinny",
  value: 100,
};
const WEIGHT_REGULAR = {
  name: "Regular",
  value: 400,
};
const WEIGHT_THICK = {
  name: "Thick",
  value: 600,
};

const WEIGHT_SUPER_THICK = {
  name: "Super Thick",
  value: 900,
};

const STANDARD_WEIGHTS = [
  WEIGHT_SKINNY,
  WEIGHT_REGULAR,
  WEIGHT_THICK,
  WEIGHT_SUPER_THICK,
];

const OPEN_SANS = {
  familyName: "Open Sans",
  className: "open-sans",
  ref: "Open+Sans",
  weights: [
    {
      ...WEIGHT_SKINNY,
      value: 300,
    },
    WEIGHT_REGULAR,
    WEIGHT_THICK,
    {
      ...WEIGHT_SUPER_THICK,
      value: 800,
    },
  ],
};

const OSWALD = {
  familyName: "Oswald",
  className: "oswald",
  ref: "Oswald",
  weights: [
    {
      ...WEIGHT_SKINNY,
      value: 200,
    },
    WEIGHT_REGULAR,
    WEIGHT_THICK,
    {
      ...WEIGHT_SUPER_THICK,
      value: 700,
    },
  ],
};

const LATO = {
  familyName: "Lato",
  className: "lato",
  ref: "Lato",
  weights: [
    WEIGHT_SKINNY,
    WEIGHT_REGULAR,
    {
      ...WEIGHT_THICK,
      value: 700,
    },
    WEIGHT_SUPER_THICK,
  ],
};

const PT_SERIF = {
  familyName: "PT Serif",
  className: "pt-serif",
  ref: "PT+Serif",
  weights: [
    WEIGHT_REGULAR,
    {
      ...WEIGHT_THICK,
      value: 700,
    },
  ],
};

const TITTILIUM_WEB = {
  familyName: "Titillium Web",
  className: "titillium-web",
  ref: "Titillium+Web",
  weights: [
    {
      ...WEIGHT_SKINNY,
      value: 200,
    },
    WEIGHT_REGULAR,
    WEIGHT_THICK,
    WEIGHT_SUPER_THICK,
  ],
};

const RALEWAY = {
  familyName: "Raleway",
  className: "raleway",
  ref: "Raleway",
  weights: STANDARD_WEIGHTS,
};

const COMIC_NEUE = {
  familyName: "Comic Neue",
  className: "comic-neue",
  ref: "Comic+Neue",
  weights: [
    {
      ...WEIGHT_SKINNY,
      value: 300,
    },
    WEIGHT_REGULAR,
    {
      ...WEIGHT_THICK,
      value: 700,
    },
  ],
};

const MONTSERRAT = {
  familyName: "Montserrat",
  className: "montserrat",
  ref: "Montserrat",
  weights: STANDARD_WEIGHTS,
};

const ROBOTO = {
  familyName: "Roboto",
  className: "roboto",
  ref: "Roboto",
  weights: STANDARD_WEIGHTS,
};

const ROBOTO_MONO = {
  familyName: "Roboto Mono",
  className: "roboto-mono",
  ref: "Roboto+Mono",
  weights: [
    WEIGHT_SKINNY,
    WEIGHT_REGULAR,
    {
      ...WEIGHT_THICK,
      value: 500,
    },
    {
      ...WEIGHT_SUPER_THICK,
      value: 700,
    },
  ],
};

const WORK_SANS = {
  familyName: "Work Sans",
  className: "work-sans",
  ref: "Work+Sans",
  weights: STANDARD_WEIGHTS,
};

const BIG_SHOULDERS_DISPLAY = {
  familyName: "Big Shoulders Display",
  className: "big-shoulders-display",
  ref: "Big+Shoulders+Display",
  weights: STANDARD_WEIGHTS,
};

const RIGHTEOUS = {
  familyName: "Righteous",
  className: "righteous",
  ref: "Righteous",
  weights: [WEIGHT_REGULAR],
};

const PINYON_SCRIPT = {
  familyName: "Pinyon Script",
  className: "pinyon-script",
  ref: "Pinyon+Script",
  weights: [WEIGHT_REGULAR],
};

const SACREMENTO = {
  familyName: "Sacramento",
  className: "sacramento",
  ref: "Sacramento",
  weights: [WEIGHT_REGULAR],
};

const DANCING_SCRIPT = {
  familyName: "Dancing Script",
  className: "dancing_script",
  ref: "Dancing+Script",
  weights: [WEIGHT_REGULAR, WEIGHT_THICK],
};

const ALMARENA_MONO = {
  familyName: "Almarena Mono",
  className: "almarena mono",
  ref: "Almarena+Mono",
  weights: [WEIGHT_SKINNY],
  url: almarenaMonoPath,
};

const ALMARENA_DISPLAY_LIGHT = {
  familyName: "Almarena Display Light",
  className: "almarena display light",
  ref: "Almarena+Display+Light",
  weights: [WEIGHT_SKINNY],
  url: almarenaLightPath,
};

const ALMARENA_DISPLAY_REGULAR = {
  familyName: "Almarena Display Regular",
  className: "almarena display regular",
  ref: "Almarena+Display+Regular",
  weights: [WEIGHT_REGULAR],
  url: almarenaRegularPath,
};

const MODERN_FONTS = [
  LATO,
  OPEN_SANS,
  PT_SERIF,
  ROBOTO,
  TITTILIUM_WEB,
  WORK_SANS,
];

const EDGY_FONTS = [
  COMIC_NEUE,
  MONTSERRAT,
  BIG_SHOULDERS_DISPLAY,
  OSWALD,
  RALEWAY,
  ROBOTO_MONO,
  RIGHTEOUS,
];

const CURSIVE_FONTS = [PINYON_SCRIPT, SACREMENTO, DANCING_SCRIPT];

window.FONTS = [
  ALMARENA_MONO,
  ALMARENA_DISPLAY_LIGHT,
  ALMARENA_DISPLAY_REGULAR,
  LATO,
  COMIC_NEUE,
  OPEN_SANS,
  OSWALD,
  MONTSERRAT,
  ROBOTO,
  BIG_SHOULDERS_DISPLAY,
  RIGHTEOUS,
  PINYON_SCRIPT,
  RALEWAY,
  ROBOTO_MONO,
  SACREMENTO,
  TITTILIUM_WEB,
  DANCING_SCRIPT,
  WORK_SANS,
];

export const FONT_GROUPS = [
  {
    type: "group",
    name: "Modern",
    items: MODERN_FONTS,
  },
  {
    type: "group",
    name: "Edgy",
    items: EDGY_FONTS,
  },
  {
    type: "group",
    name: "Cursive",
    items: CURSIVE_FONTS,
  },
];

export default class Fonts {
  static loadAll() {
    window.FONTS.forEach((font) => {
      Fonts._load(font);
    });
  }

  static _load(font) {
    let weightValues = font.weights.map((weight) => weight.value);
    let weightNames = font.weights.map(
      (weight) => "n" + weight.value.toString().substring(0, 1)
    );

    if (font.url) {
      WebFont.load({
        custom: {
          families: [font.familyName],
          urls: [font.url],
        },
        fontinactive: (name, ff) => {
          console.error("Font loading failed", name, ff);
        },
      });
      console.log("bbbbbbb");
    } else {
      WebFont.load({
        custom: {
          families: [`${font.familyName}:${weightNames.join(",")}`],
          urls: [
            `https://fonts.googleapis.com/css?family=${
              font.ref
            }:${weightValues.join(",")}&display=swap`,
          ],
        },
        fontinactive: (name, ff) => {
          console.error("Font loading failed", name, ff);
        },
      });
    }
  }

  static addUploaded(font) {
    window.FONTS.push({
      familyName: font.family_name,
      className: "",
      url: font.url,
      weights: [
        {
          name: "Regular",
          value: 400,
        },
      ],
    });
  }
}
