import React from 'react'

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";

import InnerHTML from 'dangerously-set-html-content'
import { InView } from 'react-intersection-observer';

import Collapsible from 'react-collapsible';
import Border from '../Border'

import H5 from './editable/H5'
import Img from './editable/Img'
import P from './editable/P'
import Span from './editable/Span'
import BlockQuote from './editable/BlockQuote'
import Section from './editable/Section'

import TextInfoInputItem from '../sectioninputs/TextInfoInputItem'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

export default class Block54 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable,
      uuid: "cb_"+General.uuid()
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)

      this.setState({
        key: this.state.key + 1
      }, () => General.updateSlides())
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    let {
      key
    } = this.state

    this.setState({ ...data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _getModalContent(data){
    return (
      <>
        <Border>
          <Collapsible
            trigger="Code"
          >
            <br/>
            <span>Enter your custom HTML/CSS/JS here</span>
            <div
              style={{
                width: "100%",
                padding: 8,
                backgroundColor: "#f5f5f5"
              }}
            >
              <AceEditor
                mode="javascript"
                theme="xcode"
                style={{ width: "100%", backgroundColor: "transparent"}}
                defaultValue={data.body}
                showGutter={false}
                debounceChangePeriod={1000}
                onChange={body => {
                  data.body = body
                  this._update(data)
                }}
              />
            </div>
            <br/>
            <div class="row align-items-center">
              <div class="col-md-8">
                <h4 className='lh-1 mb-0'>Lazy Load<br></br><small className='fs-12 lh-1'>Only trigger code when section comes into view</small></h4>
              </div>
              <div class="col-md-4 text-right">
                <div class="input-checkbox input-checkbox--switch">
                  <input
                    id="custom_block_variation"
                    type="checkbox"
                    defaultChecked={data.variation}
                    onChange={(e) => {
                      if (e.target.checked) {
                        data.variation = "lazy_load"
                      } else {
                        data.variation = null
                      }
                      this._update(data);
                    }}
                  />
                  <label for="custom_block_variation"></label>
                </div>
              </div>
            </div>
          </Collapsible>
        </Border>
      </>
    )
  }


  render(){
    let { data, editable, uuid, key } = this.state
    return (
      <Section
          ref={this.section}
          data={ data }
          index={ this.props.index }
          className={ " block-54 text-center " + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          primaryColor={false}
          secondaryColor={false}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() => this._getModalContent(data)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
        <div class="container">

          <Titles 
            data={ data }
            editable={ editable }
            onUpdated={data => this._update(data)}
            headingNumber={2}
          />

          {!data.variation && data.body &&
            <InnerHTML html={data.body}/>
          }
          {data.variation==="lazy_load" && data.body &&
            <InView
                triggerOnce={true}
              >
              {({ inView, ref, entry }) => (
                <div ref={ref}>
                  { inView && 
                    <InnerHTML html={data.body}/>
                  }
                </div>
              )}
            </InView>
          }
        </div>
      </Section>
    )
  }
}
