import React from 'react'

import PortalModal from './PortalModal'

export default class SuccessModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      title: props.title,
      message: props.message,
      show: props.show,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }


  render() {
    let {
      show,
      title,
      message,
    } = this.state

    return (
      <PortalModal
        show={show}
        showAsSideMenu={"false"}
        onHide={() => this.props.onHide()}
      >

        <div className="modal-share">

          <div className="modal-head">
            <h2 className="mb-0">{ title }</h2>
          </div>

          <div className="row modal-body">
            <div className="col-md-12">
              <p>{message}</p>
            </div>
          </div>

          <div className="text-right modal-footer">
            <button type="button" className="btn btn-secondary" id="add-success"
                    onClick={() => this.props.onHide()}
            >
              Dismiss
            </button>
          </div>

        </div>

      </PortalModal>
    )
  }
}