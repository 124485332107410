import React from "react";

import Button from "../Button";
import Collapsible from "react-collapsible";
import Border from "../Border";
import Dropdown from "../Dropdown";
import AddProduct from "../AddProduct";

import { getImageUrl, Sizes } from "./editable/Img";
import Section from "./editable/Section";

import ProductVariantsModal from "./../modal/ProductVariantsModal";

import ScriptCache from "../../utils/ScriptCache";
import General from "../../utils/General";
import Backend from "../../utils/Backend";
import Currency from "../../utils/Currency";
import Cart from "../../utils/Cart";

export default class Block37 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable,
      products: [],
      images: this._getImages(props.data.featured_product),
    };

    this.updateContentBlock = General.debounce(
      () => {
        let data = { ...this.state.data };
        data.featured_product = data.featured_product.id;
        Backend.updateContentBlock(data);
      },
      1000,
      false
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  componentDidMount() {
    Backend.getProducts().then((products) => {
      this.setState({ products });
    });
  }

  _getImages(product) {
    if (product == null) {
      return [];
    }

    let images = product.images;
    images.unshift(product.featured_image);

    return images;
  }

  _renderPrice(product) {
    let { data } = this.state;

    if (!product) {
      return "";
    }

    if (product.on_sale) {
      return (
        <>
          <span
            class="h3 type--strikethrough m-0 pr-1"
            style={{ color: data.settings.secondary_color }}
          >
            {Currency.format(
              product.price,
              window.CURRENT_WEBSITE_SHOP_CURRENCY
            )}
          </span>
          <span
            class="h3 m-0 pr-2"
            style={{ color: data.settings.secondary_color }}
          >
            {Currency.format(
              product.sale_price,
              window.CURRENT_WEBSITE_SHOP_CURRENCY
            )}
          </span>
        </>
      );
    }

    return (
      <span
        class="h3 m-0 pr-2"
        style={{ color: data.settings.secondary_color }}
      >
        {Currency.format(product.price, window.CURRENT_WEBSITE_SHOP_CURRENCY)}
      </span>
    );
  }

  _update(data, callback) {
    this.setState({ ...data }, () => {
      if (callback) {
        callback();
      }
      this.updateContentBlock();
    });
  }

  _getModalContent(data) {
    let { products } = this.state;
    return (
      <Border>
        <Collapsible trigger="Select Product">
          <br />
          <div className="input-select">
            <select
              value={data.featured_product ? data.featured_product.id : null}
              onChange={(e) => {
                let id = parseInt(e.target.value);
                let product = products.find((p) => p.id == e.target.value);
                data.featured_product = product;
                this._update(
                  {
                    data,
                    key: this.state.key + 1,
                    images: this._getImages(product),
                  },
                  () => General.updateSlides()
                );
              }}
            >
              {data.featured_product == null && (
                <option value="null">Select a Product</option>
              )}
              {products.map((product) => {
                return <option value={product.id}>{product.name}</option>;
              })}
            </select>
          </div>
        </Collapsible>
      </Border>
    );
  }

  render() {
    let { data, editable, images, key, showProductVariantsModal } = this.state;

    let { featured_product } = data;

    if (!editable && (!featured_product || !featured_product.active)) {
      return null;
    }

    let outOfStock = featured_product
      ? Cart.outOfStock(featured_product)
      : false;

    return (
      <Section
        data={data}
        index={this.props.index}
        className="block-37 space--lg switchable"
        editable={editable}
        onUpdated={(data) => this._update({ data })}
        onDelete={() => this.props.onDelete()}
        onAddContentBlockPressed={(contentBlock) =>
          this.props.onAddContentBlockPressed(contentBlock)
        }
        renderModalContent={() => this._getModalContent(data)}
        canMoveUp={this.props.canMoveUp}
        canMoveDown={this.props.canMoveDown}
        onMoveUpPressed={() => this.props.onMoveUpPressed()}
        onMoveDownPressed={() => this.props.onMoveDownPressed()}
      >
        <div class="container">
          {(featured_product == null || !featured_product.active) && (
            <AddProduct />
          )}
          {featured_product && featured_product.active && (
            <div class="row justify-content-around">
              {/* starts, column right  */}
              <div class="col-md-5 col-lg-5">
                <h2 style={{ color: data.settings.primary_color }}>
                  {featured_product.name}
                </h2>
                <div class="text-block d-flex align-items-center pb-3">
                  {this._renderPrice(featured_product)}
                  {featured_product.on_sale && (
                    <span class="label position-static product-sale-price">
                      Sale
                    </span>
                  )}
                </div>
                <div
                  class="lead pre"
                  style={{ color: data.settings.secondary_color }}
                  dangerouslySetInnerHTML={{
                    __html: featured_product.description,
                  }}
                />
                <div>
                  {!outOfStock && (
                    <a
                      class="btn btn--primary mb-2 w-100"
                      onClick={() => {
                        if (
                          featured_product.options &&
                          featured_product.options.length > 0
                        ) {
                          this.setState({ showProductVariantsModal: true });
                        } else {
                          this.props.onAddToCartPressed(featured_product);
                        }
                      }}
                    >
                      <span class="btn__text">Add To Cart</span>
                    </a>
                  )}
                  {outOfStock && (
                    <a
                      class="btn btn--secondary w-100 mb-2"
                      disabled={true}
                      onClick={() => {}}
                    >
                      <span class="btn__text">Out Of Stock</span>
                    </a>
                  )}
                </div>
              </div>
              <div class="col-md-7 col-lg-6">
                <div
                  key={key}
                  class="slider border--round boxed--border"
                  data-paging="true"
                  data-arrows="true"
                >
                  <ul class="slides">
                    {images.map((image) => {
                      return (
                        <li
                          key={image.id}
                          style={{
                            backgroundImage:
                              "url(" + getImageUrl(image, 37) + ")",
                          }}
                          priority={this.props.index}
                        ></li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
        <ProductVariantsModal
          show={showProductVariantsModal}
          product={featured_product}
          onSelected={(variant) => {
            this.setState({ showProductVariantsModal: false });
            this.props.onAddToCartPressed(featured_product, variant);
          }}
          onHide={() => this.setState({ showProductVariantsModal: false })}
        />
      </Section>
    );
  }
}
