import React from "react";

import { Draggable } from "react-beautiful-dnd";

import General from "../utils/General";

import Block1 from "./blocks/1";
import Block2 from "./blocks/2";
import Block3 from "./blocks/3";
import Block4 from "./blocks/4";
import Block5 from "./blocks/5";
import Block6 from "./blocks/6";
import Block7 from "./blocks/7";
import Block8 from "./blocks/8";
import Block9 from "./blocks/9";
import Block10 from "./blocks/10";
import Block11 from "./blocks/11";
import Block12 from "./blocks/12";
import Block13 from "./blocks/13";
import Block14 from "./blocks/14";
import Block15 from "./blocks/15";
import Block16 from "./blocks/16";
import Block17 from "./blocks/17";
import Block18 from "./blocks/18";
import Block19 from "./blocks/19";
import Block20 from "./blocks/20";
import Block21 from "./blocks/21";
import Block22 from "./blocks/22";
import Block23 from "./blocks/23";
import Block24 from "./blocks/24";
import Block25 from "./blocks/25";
import Block26 from "./blocks/26";
import Block27 from "./blocks/27";
import Block28 from "./blocks/28";
import Block29 from "./blocks/29";
import Block30 from "./blocks/30";
import Block31 from "./blocks/31";
import Block32 from "./blocks/32";
import Block33 from "./blocks/33";
import Block34 from "./blocks/34";
import Block35 from "./blocks/35";
import Block36 from "./blocks/36";
import Block37 from "./blocks/37";
import Block38 from "./blocks/38";
import Block39 from "./blocks/39";
import Block40 from "./blocks/40";
import Block41 from "./blocks/41";
import Block42 from "./blocks/42";
import Block43 from "./blocks/43";
import Block44 from "./blocks/44";
import Block45 from "./blocks/45";
import Block46 from "./blocks/46";
import Block47 from "./blocks/47";
import Block48 from "./blocks/48";
import Block49 from "./blocks/49";
import Block50 from "./blocks/50";
import Block51 from "./blocks/51";
import Block52 from "./blocks/52";
import Block53 from "./blocks/53";
import Block54 from "./blocks/54";
import Block55 from "./blocks/55";
import Block56 from "./blocks/56";
import Block57 from "./blocks/57";
import Block58 from "./blocks/58";
import Block59 from "./blocks/59";
import Block60 from "./blocks/60";

export default class ContentBlock extends React.Component {
  blocks = {
    Block1,
    Block2,
    Block3,
    Block4,
    Block5,
    Block6,
    Block7,
    Block8,
    Block9,
    Block10,
    Block11,
    Block12,
    Block13,
    Block14,
    Block15,
    Block16,
    Block17,
    Block18,
    Block19,
    Block20,
    Block21,
    Block22,
    Block23,
    Block24,
    Block25,
    Block26,
    Block27,
    Block28,
    Block29,
    Block30,
    Block31,
    Block32,
    Block33,
    Block34,
    Block35,
    Block36,
    Block37,
    Block38,
    Block39,
    Block40,
    Block41,
    Block42,
    Block43,
    Block44,
    Block45,
    Block46,
    Block47,
    Block48,
    Block49,
    Block50,
    Block51,
    Block52,
    Block53,
    Block54,
    Block55,
    Block56,
    Block57,
    Block58,
    Block59,
    Block60,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      blockId: props.data.block_id,
      editable: props.editable,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
      blockId: nextProps.data.block_id,
    });
  }

  render() {
    let blockName = "Block" + this.state.blockId;
    const Block = this.blocks[blockName];

    return (
      <Block
        data={this.state.data}
        index={this.props.index}
        websiteId={this.props.websiteId}
        className={this.props.className}
        editable={this.state.editable}
        onDelete={() => this.props.onDelete()}
        onAddContentBlockPressed={(contentBlock) =>
          this.props.onAddContentBlockPressed(contentBlock)
        }
        onAddToCartPressed={(product, variant) =>
          this.props.onAddToCartPressed(product, variant)
        }
        canMoveUp={this.props.canMoveUp}
        canMoveDown={this.props.canMoveDown}
        onMoveUpPressed={() => this.props.onMoveUpPressed()}
        onMoveDownPressed={() => this.props.onMoveDownPressed()}
      />
    );
  }
}
