import React from 'react'

import H3 from './editable/H3'
import P from './editable/P'
import Section from './editable/Section'

import Map from '../Map'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

export default class Block32 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable
    }

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }
  
  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  render(){
    let { data, editable } = this.state
    let showTitle = data.title
    return (
      <Section
          data={ data }
          index={ this.props.index }
          className={ "section-32 block-32 imageblock switchable feature-large space--lg bg--secondary " + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          primaryColor={false}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="imageblock__content col-lg-6 col-md-4 col-12 pos-right">
              <div className="map-container align-left">
                  <Map
                    map={ data.map }
                    google={ this.props.google }/>
              </div>
          </div>
          <div className="container">
              <div className="row">
                  <div className="col-lg-5 col-md-7">
                    {showTitle && 
                      <H3
                        text={ data.title}
                        color={ data.title_color }
                        editable={ editable }
                        textAlign="left"
                        onUpdated={text => {
                          data.title = text
                          this._update(data)
                        }}
                      />
                    }
                      <P
                        text={ data.body }
                        color={ data.settings.secondary_color }
                        editable={ editable }
                        className="like-pre"
                        textAlign="left"
                        onUpdated={text => {
                          data.body = text
                          this._update(data)
                        }}
                      />
                  </div>
              </div>
          </div>
      </Section>
    )
  }
}
