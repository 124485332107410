import React from "react";

import General from "../../utils/General";
import PortalModal from "./PortalModal";
import Youtube from '../Youtube'

export default class VideoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoUrl: props.videoUrl,
      isLoading: false,
      showModal: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    const { videoUrl, showModal } = this.state;
    
    const videoId = General.getYoutubeVideoId(videoUrl);

    return (
      <PortalModal
        show={showModal}
        showAsSideMenu="false"
        contentSuperContainerClassName={`bg-dark yt-video-lg`}
        contentContainerClassName=""
        onHide={() => this.props.onHide()}
      >
        <Youtube
          videoId={videoId}
        />
      </PortalModal>
    );
  }
}
