import React from 'react'

import Collapsible from 'react-collapsible';
import Border from '../../Border'

import {isMobile} from 'react-device-detect';

import Image from './Image'

import ImageUploader from './ImageUploader'

import ColorPicker from '../../ColorPicker'

import PortalModal from '../../modal/PortalModal'

export default class Img extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      className: props.className,
      src: props.src || props.img?.original,
      img: props.img,
      alt: props.alt,
      type: props.type,
      block: props.block,
      priority: props.priority,
      editable: props.editable,
      showModal: false,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _onUploaded(image){
    this.props.onUpdated(image)
    this.setState({ isLoading: false, showModal: false })
  }

  _renderModalContent(src){
    return (
      <PortalModal
        show={this.state.showModal}
        onHide={() => this.setState({ showModal: false })}
      >
        <h2>Edit Image</h2>
        <Border>
          <Collapsible
            trigger="Image"
          >
            <br></br>
            <ImageUploader
              src={src}
              title={""}
              type={this.props.type}
              pickerType={this.props.pickerType || this.props.type}
              primaryColor={this.props.primaryColor}
              onUploaded={image => this._onUploaded(image)}
            />
          </Collapsible>
        </Border>

        { this.props.onColorUpdated &&
          <>
            <Border>
              <Collapsible
                trigger="Illustration Main Colour"
              >
                <br></br>
                <ColorPicker
                  title={ "" }
                  color={ this.props.primaryColor}
                  onColorSelected={color => this.props.onColorUpdated(color)}
                />
              </Collapsible>
            </Border>
          </>
        }

      </PortalModal>
    )
  }

  _renderContent(className, src, alt){
    let {
      type,
      img,
    } = this.state

    src = getImageUrl(img, this.props.block, this.props.size)

    return (
      <Image
        className={className}
        src={src}
        type={type}
        alt={alt}
        primaryColor={this.props.primaryColor}
      />
    )
  }

  _renderEditable(className, src, alt){
    return (
      <>
        <div className="img-container pos-relative">
            { this._renderContent(className, src, alt) }
            <div className="block">
                <a href="javascript:void(0);" onClick={() => this.setState({ showModal: true})}>
                    <div className="edit sz-3 edit-img">
                        <i className="icon icon-Pencil"/>
                    </div>
                </a>
            </div>
        </div>
        { this._renderModalContent(src) }
      </>
    )
  }

  render(){
    let {
      className,
      src,
      alt,
      editable
    } = this.state

    if(editable){
      return this._renderEditable(className, src, alt)
    }
    return this._renderContent(className, src, alt)
  }
}

Img.defaultProps = {
  type: "photo",
  primaryColor: "#6c63ff"
}

export function getImageUrl(image, block, size){
  if(image?.resizer_url){
    return `${image.resizer_url}/fit-in/${getResolution(block, size)}${image.path}`
  }

  if(image?.original?.indexOf('https://images.unsplash.com') > -1){
    let resolution = getResolution(size)
    let parts = resolution.split("x")
    let width = parts[0]
    let height = parts[1]
    return image.original.replace("w=2000&h=2000", `w=${width}&h=${height}`)
  }

  return image?.original
}

export function getResolution(block, size){
  let windowWidth = window.innerWidth

  if(windowWidth < 768){
    switch (block){
      case 13:     return "750x476";
      case 14:     return "750x476";
      case 15:     return "750x476";
      case 16:     return "750x476";
      case 18:     return "690x468";
      case 19:     return "750x476";
      case 23:     return "690x460";
      case 24:     return size === SMALL ? "572x380" :  "286x190";
      case 25:     return "380x380";
      case 26:     return "690x468";
      case 27:     return "690x468";
      case 28:     return "690x468";
      case 36:     return "690x676";
      case 37:     return "686x460";
      case "product":     return "686x464";
      case 41:     return size === LARGE ? "750x1200" :  "750x600";
      case 42:     return "750x476";
      case "articleitem":     return "690x468";
      case 44:     return "750x1200";
      case 45:     return "346x228";
      case 47:     return "690x720";
      case "product-page":     return "686x460";
      case "article":     return "750x534";
      case "article-gallery":     return "315x190";
      default:     return "750x1624";
    }
  }

  if(windowWidth < 1280){
    switch (block){
      case 13:     return "512x888";
      case 14:     return "512x888";
      case 15:     return "512x2048";
      case 16:     return "512x2048";
      case 18:     return "420x468";
      case 19:     return "512x888";
      case 23:     return "1380x1380";
      case 24:     return size === SMALL ? "800x452" :  "400x226";
      case 25:     return "380x380";
      case 26:     return "660x448";
      case 27:     return "660x448";
      case 28:     return "660x448";
      case 36:     return "660x646";
      case 37:     return "776x518";
      case "product":     return "416x464";
      case 41:     return size === LARGE ? "1536x2048" :  "1536x600";
      case 42:     return "512x888";
      case "articleitem":     return "420x468";
      case 44:     return "1536x2048";
      case 45:     return "346x228";
      case 47:     return "660x720";
      case "product-page":     return "1896x598";
      case "article":     return "1536x1024";
      case "article-gallery":     return "420x266";
      default:     return "1536x2048";
    }
  }

  switch (block){
    case 13:     return "1264x964";
    case 14:     return "1264x964";
    case 15:     return "1264x1600";
    case 16:     return "1264x1600";
    case 18:     return "700x468";
    case 19:     return "1264x964";
    case 23:     return "2220x1480";
    case 24:     return size === SMALL ? "1360x904" :  "680x452";
    case 25:     return "416x416";
    case 26:     return "1080x732";
    case 27:     return "1080x732";
    case 28:     return "1080x732";
    case 36:     return "890x872";
    case 37:     return "1076x720";
    case "product":     return "696x464";
    case 41:     return size === LARGE ? "2560x1600" :  "2560x600";
    case 42:     return "1264x964";
    case "articleitem":     return "700x468";
    case 44:     return "2560x800";
    case 45:     return "556x312";
    case 47:     return "1080x720";
    case "product-page":     return "1456x970";
    case "article":     return "2560x800";
    case "article-gallery":     return "700x452";
    default:     return "2560x1600";
  }
}

const SMALL = "small"
const MEDIUM = "medium"
const LARGE = "large"

export const Sizes = {SMALL, MEDIUM, LARGE}
