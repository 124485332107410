import React from 'react'

import H4 from './editable/H4'
import P from './editable/P'
import Section from './editable/Section'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

export default class Block12 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable
    }

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }


  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderTextInfos(data, editable){
    let textInfos = data.text_infos
    return textInfos.map((textInfo, index) => {
      return (
          <div key={ textInfo.id } className={ "col-md item" }>
            <div className="feature dc-padding">
                <H4
                  text={ textInfo.title }
                  color={ data.settings.primary_color }
                  editable={ editable }
                  textAlign="left"
                  onUpdated={text => {
                    textInfo.title = text
                    textInfos[index] = textInfo
                    data.text_infos = textInfos
                    this._update(data)
                  }}
                />

                <P
                  text={ textInfo.subtitle }
                  color={ data.settings.secondary_color }
                  editable={ editable }
                  lead={ false }
                  textAlign="left"
                  onUpdated={text => {
                    textInfo.subtitle = text
                    textInfos[index] = textInfo
                    data.text_infos = textInfos
                    this._update(data)
                  }}
                />
            </div>
        </div>
      )
    })
  }

  render(){
    let { data, editable } = this.state
    let blockClassName = " bg--secondary block-12 text-panel-v1 ";
    if (window.IS_CARD_STYLE_WEBSITE) {
      blockClassName += " deep-cardify";
    }
    return (
      <Section
          data={ data }
          index={ this.props.index }
          className={ blockClassName +this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="container items">
            <Titles 
              data={ data }
              editable={ editable }
              headingNumber={2}
            />
              <div className="row">
                  { this._renderTextInfos(data, editable) }
              </div>
          </div>
      </Section>
    )
  }
}
