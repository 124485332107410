import React from 'react'

import { Helmet } from "react-helmet";

import Button from '../components/Button'

import H2 from '../components/blocks/editable/H2'
import Img from '../components/blocks/editable/Img'
import P from '../components/blocks/editable/P'
import Section from '../components/blocks/editable/Section'

import LazyLoadingList from '../components/common/LazyLoadingList'

import Product from '../components/Product'
import ArticleItem from "../components/common/ArticleItem";

import General from '../utils/General'
import Backend from '../utils/Backend'
import ScriptCache from '../utils/ScriptCache'

export default class Blog extends React.Component {
  constructor(props) {
    super(props)

    let category = {
      label: "All",
      value: null
    }

    let orderBy = {
      label: "Latest",
      value: "-created_at"
    }

    this.state = {
      category,
      orderBy,
      loading: true,
      loadingBlog: true,
      initialArticlesLoading: true,
      categories: [category],
      website: props.website,
    }
  }

  componentDidMount() {
    this._setup()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _setup(){
    let {
      website
    } = this.state

    this.setState({
      website,
      loading: false,
    }, () => {
      this._loadJs()
    })
  }

  _loadJs(){
    ScriptCache.loadDefaults()
    setTimeout(() => {
      General.updateAll()
    }, 300)
  }

  _renderArticles(){
    let {
      website,
      loading,
    } = this.state

    if(loading){
      return this._renderArticlesLoading()
    }

    let endpoint = `${window.Api.Base}/articles?website_id=${website.id}&page_size=9`

    return (
      <LazyLoadingList
        ref={this.lazyLoadingList}
        grow={true}
        endpoint={endpoint}
        onItemUpdated={() => {
          this.setState({ initialArticlesLoading: false })
        }}
        renderItem={article => {
          return (
            <ArticleItem
              article={article}
              onAddToCartPressed={(article, variant) => this.props.onAddToCartPressed(article, variant)}
            />
          )
        }}
        renderInitialLoading={() => this._renderArticlesLoading()}
        renderLoadingMore={() => this._renderArticlesLoading()}
      />
    )
  }

  _renderArticlesLoading(){
    return (
      <>
        <Product/>
        <Product/>
        <Product/>
      </>
    )
  }

  _renderMeta(){
    let {
      website,
    } = this.state

    if(website == null){
       return null
    }

    let name = "Blog | "+website.name
    let favIconUrl = website.favicon ? website.favicon.original : "/amply_favicon_32x32px.png"

    return (
      <Helmet>
          <title>{ name }</title>
          <meta name="description" content={ website.descrption } />
          <meta name="keywords" content={ website.keywords } />
          <link rel="icon" href={ favIconUrl }/>
          <meta property="og:title" content={ website.name }/>
          <meta property="og:image" content={ favIconUrl }/>
          <meta property="og:description" content={ website.descrption }/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content={ window.location.href }/>
      </Helmet>
    )
  }

  render() {
    let {
    } = this.state

    return (
      <div className="main-container">
        { this._renderMeta() }
        <section class="article-blocks">
            <div class="container">

                <div class="row mt-5">

                  { this._renderArticles() }

                </div>

            </div>
        </section>
      </div>
    )
  }
}
