import React from 'react'
import $ from "jquery";
import Pagination from "react-js-pagination";

import Search from "../components/Search";

import JobUtils from "../utils/Job";
import Backend from '../utils/Backend'
import FetchHelper from '../utils/FetchHelper'

import {
  isMobile
} from "react-device-detect";
import {Helmet} from "react-helmet";

const PAGE_SIZE = 12

export default class Jobs extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      key: 0,
      jobs: [],
      loading: true,
      jobsMode: null,
      totalJobsNo: 0,
      website: props.website,
    }

  }

  componentDidMount(){
    let url = new URL(window.location.href)
    let params = new URLSearchParams(url.search)
    let pageNo = params.get("page") || "1"
    this._loadJobs(pageNo)
  }

  _handleNextPage(pageNo){
    let url = new URL(window.location.href)
    let params = new URLSearchParams(url.search)
    params.set("page", pageNo)
    window.location.search = params.toString()
  }

  _loadJobs(pageNo){
    let { jobs } = this.state

    const jobsMode = window.CURRENT_WEBSITE.extra?.company?.slug ? "amply" : null

    let url = this._getPageUrl(pageNo)

    this.setState({ isLoading: true  })
    FetchHelper.get(url, false, false)
    .then(response => {
      jobs = [
        ...jobs,
        ...response.results
      ]
      this.setState({
        jobs,
        pageNo,
        jobsMode,
        isLoading: false,
        nextPage: response.next,
        totalJobsNo: response.count
      })
    })
    .catch(() => {
      this.setState({ isLoading: false  })
    })
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _getPageUrl(pageNo){
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const searchTerm = params.get('searchTerm') || ""

    pageNo = pageNo || 1

    let jobsMode = null
    let companySlug = window.CURRENT_WEBSITE.extra?.company?.slug
    let url = null
    if(!companySlug){
      url = `${window.Api.Jobs}?website_id=${window.CURRENT_WEBSITE.id}&page_size=${PAGE_SIZE}&pagination_type=page`
    }
    else{
      url = `${window.Api.JobbioJobs}/${companySlug}/feed?&page_size=${PAGE_SIZE}`
    }

    if(searchTerm){
      url += `&search_term=${searchTerm}&search=${searchTerm}`
    }

    if(pageNo){
      url += `&page=${pageNo}`
    }

    return url
  }

  _renderMeta() {
    let { website } = this.state;

    if (website == null) {
      return null;
    }

    let name = `Search all the jobs at ${website.name}`;
    let description = `Looking to advance your career? Search all open roles and find your ideal role today. Once you've found your perfect job quickly apply to the job with a couple of clicks.`

    let favIconUrl = website.favicon
      ? website.favicon.original
      : "/amply_favicon_32x32px.png";

    return (
      <Helmet>
        <title>{name}</title>
        <meta
          name="description"
          content={description}
        />
        <meta name="keywords" content={website.keywords} />
        <link rel="icon" href={favIconUrl} />
        <meta property="og:title" content={name} />

        <meta property="og:image" content={favIconUrl} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
    );
  }

  render(){
    let {
      jobs,
      pageNo,
      nextPage,
      isLoading,
      totalJobsNo,
    } = this.state

    return (
      <div className='pages jobs main-container'>
        {this._renderMeta()}
        <div
          className='hero-banner'
          style={{
            backgroundColor: window.CURRENT_PAGE_PRIMARY_COLOR
          }}
        >
          <div class="container main-container">
            <h1 className='banner-h1'>{window.Branding.Terminology.browse_jobs}</h1>
          </div>
        </div>
        <div class="container">
          <Search
            variation={"overlap"}
          />
          <div className='block-58 mb-5'>
            <div className={"results items grid"}>
              {
                jobs.map(job => {
                  let url = job.external_application_url

                  if(!window.CURRENT_WEBSITE.search_result_redirect && job.business){
                    url = `/companies/${job.business.slug}/jobs/${job.slug}`
                  }
                  else if(!url){
                    url = job.redirect || `https://jobbiodev.com/talent/register?search_job&job=${job.id}&source=amply`
                  }

                  return (
                    <a
                      className='item'
                      href={url}
                      onClick={e => {
                        if(!window.CURRENT_WEBSITE.search_result_redirect || !job.external_application_url){
                          return
                        }
                        e.preventDefault();
                        Backend.saveExternalJobApplication(job)
                        window.open(job.external_application_url, '_blank')
                      }}
                    >
                      { job.external_impression_url &&
                        <img
                          width={1}
                          height={1}
                          className="indeed-tmn-pxl"
                          data-src={job.external_impression_url}
                          style={{display: 'block', marginBottom:-1}}
                        />
                      }
                      <span className='c1'>
                        <span className='title'>{job.title}</span>
                        {!window.CURRENT_WEBSITE.hide_company_names &&
                          <span className='name'>{job.business?.name || job.company?.name}</span>
                        }
                        <span className='location'>{JobUtils.getLocation(job)}</span>
                        <span className='published'>{job.published}</span>
                      </span>
                      <button className='btn c-btn'>{window.Branding.Terminology.view_job}</button>
                    </a>
                  )
                })
              }
            </div>

            {
              (nextPage && isMobile) &&
              <a
                onClick={() => this._loadJobs()}
                className={"btn btn--outline-primary type--uppercase w-100 mt-5"}
                style={{border: "none"}}
                disabled={isLoading}
              >
                <span className="btn__text">
                  { isLoading ? "Loading..." : "Load More" }
                </span>
              </a>
            }
            {
              (totalJobsNo > PAGE_SIZE && !isMobile) &&
              <div>
                <Pagination
                  activePage={parseInt(pageNo)}
                  itemsCountPerPage={PAGE_SIZE}
                  totalItemsCount={totalJobsNo}
                  pageRangeDisplayed={5}
                  hideFirstLastPages={true}
                  hideDisabled={true}
                  innerClass={"pagination react-js-pagination"}
                  itemClass={"page-item"}
                  linkClass={"page-link"}
                  activeClass={"active"}
                  onChange={(page) => this._handleNextPage(page)}
                  getPageUrl={page => {
                    return this._getPageUrl(page)
                  }}
                />
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
