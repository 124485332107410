import React from "react";

import $ from "jquery";

import { GiftedChat, Bubble } from "react-gifted-chat";

import moment from "moment";

import LazyLoader from "./common/LazyLoader";
import General from "../utils/General";

export default class Chat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      website: props.website,
      editable: props.editable,
      messages: [],
      text: "",
      loading: true,
      open: false,
    };

    this.lazyLoader = React.createRef();
    this.messagesEnd = React.createRef();
  }

  componentDidMount() {
    this._setup();
    this._unmounted = false;
  }

  componentWillUnmount() {
    this._unmounted = true;
    this._closeWebSocket();
  }

  _parseMessages(messages) {
    return messages.map((message) => {
      let senderId = message.sender?.user?.id || window.CURRENT_VISITOR_TOKEN;

      return {
        _id: message.id,
        text: message.text,
        isVisitor: message.sender?.user != null,
        createdAt: message.created_at,
        user: {
          _id: senderId,
          name: message.sender?.user?.first_name || `You`,
          avatar: null,
        },
      };
    });
  }

  _setup(attemptNo) {
    let baseUrl = window.Api.Base;
    let protocol = baseUrl.indexOf("https://") > -1 ? "wss://" : "ws://";
    baseUrl = baseUrl.replace("https://", "").replace("http://", "");
    this.chatSocket = new WebSocket(
      protocol + baseUrl + "/ws/" + window.CURRENT_VISITOR_TOKEN
    );

    this.chatSocket.onopen = (e) => {
      this.setState({ loading: false });
    };

    this.chatSocket.onmessage = (e) => {
      const data = JSON.parse(e.data);

      if(data.sender.visitor){
        return
      }

      data.user = true;
      let { messages } = this.state;
      messages.unshift(data);
      this.setState(
        {
          messages,
          open: true,
        },
        () => this._scrollToBottom()
      );
    };

    this.chatSocket.onclose = (e) => {
      console.log("CLOSED", e);
      setTimeout(() => {
        if(this._unmounted){
          console.log("Reattempting connection to websocket not attempted as chat is unmounted");
          return
        }
        console.log("Reattempting connection to websocket", e);
        this._setup(attemptNo + 1)
      }, 3000 * attemptNo)
      console.error("Chat socket closed unexpectedly, will re attempt to connect");
    };
  }

  _closeWebSocket() {
    if (this.chatSocket) {
      this.chatSocket.close();
    }
  }

  _scrollToBottom() {
    if (window.HIDE_LIVE_CHAT) {
      window.HIDE_LIVE_CHAT = false;
      this.setState({ update: true }, () => this._scrollToBottom());
      return;
    }
    this.messagesEnd.current.scrollIntoView({ behavior: "smooth" });
  }

  _sendMessage() {
    let { text, messages } = this.state;
    let data = {
      text,
    };
    this.chatSocket.send(JSON.stringify(data));
    messages.unshift({
      ...data,
      id: General.uuid(),
      sender: null,
      createdAt: moment().toDate(),
    });
    this.setState(
      {
        messages,
        text: "",
      },
      () => this._scrollToBottom()
    );
  }

  _renderBubble(props) {
    let currentMessageDate = moment(props.currentMessage.createdAt).fromNow();

    let sender = props.currentMessage.user.name;

    let isVisitor = props.currentMessage.isVisitor;

    let position = isVisitor ? "justify-text-start" : "justify-text-end";

    let renderSender =
      props.previousMessage?.user?._id != props.currentMessage.user._id;

    return (
      <>
        <div className="col">
          {renderSender && (
            <div className={`message-row ${position}`}>
              {!isVisitor ? (
                <>
                  <span className="message-date mr-2">
                    {currentMessageDate}
                  </span>
                  <span className="sender-info">{sender}</span>
                </>
              ) : (
                <>
                  <span className="sender-info mr-2">{sender}</span>
                  <span className="message-date">{currentMessageDate}</span>
                </>
              )}
            </div>
          )}

          <Bubble
            {...props}
            position={isVisitor ? "left" : "right"}
            textStyle={{
              right: {
                wordWrap: "breakWord",
                color: "white",
                fontWeight: 500,
                fontSize: "14px",
                padding: "17px",
                fontFamily: "Poppins,Helvetica,sans-serif",
              },
              left: {
                wordWrap: "breakWord",
                color: "white",
                fontWeight: 500,
                fontSize: "14px",
                padding: "17px",
                fontFamily: "Poppins,Helvetica,sans-serif",
              },
            }}
            wrapperStyle={{
              left: {
                maxWidth: "60%",
                backgroundColor: 'var(--website-primary-color)',
                borderRadius: "0px",
                borderRadius: "4px",
              },
              right: {
                maxWidth: "60%",
                backgroundColor: "#222222",
                borderRadius: "0px",
                borderRadius: "4px",
              },
            }}
          />

        </div>
      </>
    );
  }

  _renderFooter(props) {
    let { text } = this.state;

    return null;
  }

  _renderTime(props) {
    return null;
  }

  _renderDay(props) {
    let currentMessageDate = moment(props.currentMessage.createdAt);

    let previousMessageDate = props.previousMessage?.createdAt
      ? moment(props.previousMessage?.createdAt)
      : null;

    if (
      previousMessageDate &&
      currentMessageDate.isSame(previousMessageDate, "date")
    ) {
      return null;
    }

    let formattedDate = currentMessageDate.format("DD MMM YYYY");

    if (currentMessageDate.isSame(moment(), "date")) {
      formattedDate = "Today";
    }

    return <div className="m-auto">{formattedDate}</div>;
  }

  _renderInputToolbar(props) {
    let { text } = this.state;

    return null;
  }

  render() {
    let { open, website, messages, editable, text } = this.state;

    if (editable || window.HIDE_LIVE_CHAT) {
      return null;
    }

    let favIconUrl = website.favicon
      ? website.favicon.original
      : "/amply_favicon_32x32px.png";

    let liveChatActiveClassName = open ? "open" : "close";

    if (this.state.open == true && $(document).width() < 994) {
      // disable the parent/body scroll
      $("body").addClass("disable-scroll");

      // Hide the Chat Footer, when virtual keyboard is on in the Mobiles
      var _originalSize = $(window).width() + $(window).height();
      $(window).resize(function () {
        if ($(window).width() + $(window).height() != _originalSize) {
          $(".chat-footer").addClass("d-none");
        } else {
          $(".chat-footer").removeClass("d-none");
        }
      });
    } else if ($("body").hasClass("disable-scroll")) {
      $("body").removeClass("disable-scroll");
    }

    return (
      <div className={`live-chat main-container ${liveChatActiveClassName}`}>
        <div className="chat-box">
          <div className="chat-head p-4 text-white">
            <div className="row justify-content-center align-items-center">
              <div className="col-auto logo pr-0">
                <img src={favIconUrl} />
              </div>
              <div className="col name">
                <h3 className="m-0 text-white">{website.name}</h3>
              </div>
              <a
                className="col-auto text-white close material-icons"
                href="javascript:void(0)"
                onClick={(e) => this.setState({ open: !open })}
              >
                close
              </a>
            </div>

            <div className="row title-text mt-4">
              <div className="col">
                <h2 className="text-white m-0">Hi, There!... 👋</h2>
                <p>We are live, ask us any question.</p>
              </div>
            </div>
          </div>

          <div className="chat-body">
            <div className="d-flex flex-column chat-form">
              <LazyLoader
                ref={this.lazyLoader}
                endpoint={`${window.Api.Messages}?visitor_token=${window.CURRENT_VISITOR_TOKEN}&paginated=true`}
                onItemsUpdated={(messages) => {
                  this.setState({ messages, isInitialLoading: false });
                }}
              >
                <GiftedChat
                  messages={this._parseMessages(messages)}
                  onSend={() => this._sendMessage()}
                  user={{
                    _id: window.CURRENT_VISITOR_TOKEN,
                  }}
                  renderBubble={(props) => this._renderBubble(props)}
                  renderTime={(props) => this._renderTime(props)}
                  renderDay={(props) => this._renderDay(props)}
                  renderInputToolbar={(props) =>
                    this._renderInputToolbar(props)
                  }
                  renderFooter={(props) => this._renderFooter(props)}
                  loadEarlier={false}
                  onLoadEarlier={() => {
                    if (this.lazyLoader.current) {
                      this.lazyLoader.current._loadMore();
                    }
                  }}
                  parsePatterns={() => [
                    { type: 'phone', style: {
                      textDecorationLine: 'none',
                    }},
                    { type: 'url', style: {
                      color: "#fff",
                      textDecorationLine: 'underline',
                      cursor: 'pointer'
                    }},
                    { type: 'email',
                      style: {
                        color: "#fff",
                        textDecorationLine: 'underline',
                        cursor: 'pointer'
                      }
                    },
                  ]}
                />
              </LazyLoader>
              <div ref={this.messagesEnd} />
              <div className="send row form-row align-items-center p-4 pt-0">
                <div className="col">
                  <input
                    className="h-100-p"
                    type="text"
                    value={text}
                    onChange={(e) => this.setState({ text: e.target.value })}
                    onKeyPress={(e) => {
                      if (e.which === 13 && text) {
                        this._sendMessage();
                      }
                    }}
                  />
                </div>
                <div className="col-auto">
                  <button
                    className="btn btn--primary btn--sm type--uppercase m-0"
                    style={{ width: 50 }}
                    disabled={!text.trim()}
                    onClick={() => this._sendMessage()}
                  >
                    <span className="btn__text">SEND</span>
                  </button>
                </div>
              </div>

              <div className="chat-footer d-flex justify-content-center align-items-center">
                Powered By &nbsp;
                <a
                  className="link--primary fw-4"
                  href="https://amply.site/"
                  target="_blank"
                >
                  Amply
                </a>
              </div>
            </div>
          </div>
        </div>

        <a
          className="live-chat-icon"
          href="javascript:void(0)"
          onClick={(e) => this.setState({ open: !open })}
        >
          <svg
            className="default"
            id="Layer_1"
            x="0"
            y="0"
            viewBox="0 0 15 16"
            xmlSpace="preserve"
            aria-hidden="true"
          >
            <path d="M1.3,16c-0.7,0-1.1-0.3-1.2-0.8c-0.3-0.8,0.5-1.3,0.8-1.5c0.6-0.4,0.9-0.7,1-1c0-0.2-0.1-0.4-0.3-0.7c0,0,0-0.1-0.1-0.1 C0.5,10.6,0,9,0,7.4C0,3.3,3.4,0,7.5,0C11.6,0,15,3.3,15,7.4s-3.4,7.4-7.5,7.4c-0.5,0-1-0.1-1.5-0.2C3.4,15.9,1.5,16,1.5,16 C1.4,16,1.4,16,1.3,16z M3.3,10.9c0.5,0.7,0.7,1.5,0.6,2.2c0,0.1-0.1,0.3-0.1,0.4c0.5-0.2,1-0.4,1.6-0.7c0.2-0.1,0.4-0.2,0.6-0.1 c0,0,0.1,0,0.1,0c0.4,0.1,0.9,0.2,1.4,0.2c3,0,5.5-2.4,5.5-5.4S10.5,2,7.5,2C4.5,2,2,4.4,2,7.4c0,1.2,0.4,2.4,1.2,3.3 C3.2,10.8,3.3,10.8,3.3,10.9z"></path>
          </svg>
          <span className="close material-icons">close</span>
          We Are Live
        </a>
      </div>
    );
  }
}

let st = (
  <>
    {/* starts, system messsages */}
    <div className="system-messages">
      {/* starts, 01: offline message  */}
      <div className={`message from-support`}>
        <div className="msg">
          splink [TEST] typically replies in a few hours.
        </div>
      </div>
      {/* end, 01: offline message  */}

      {/* starts, 02: collect email  */}
      <div className={`message from-support`}>
        <div className="msg">Give the team a way to reach you:</div>

        <div className="row collect-email">
          <div className="col-auto">
            <span class="material-icons-outlined inbox">inbox</span>
          </div>
          <div className="col form-email">
            <div className="title">Get notified by email</div>
            <div className="email">
              <input type="email" placeholder="email@example.com"></input>
              <button className="submit">
                <svg
                  focusable="false"
                  aria-hidden="true"
                  class="intercom-168qcfi e1a9wl010"
                >
                  <path
                    d="M1.5 13.864L7.864 7.5 1.5 1.136"
                    stroke-width="2.5"
                    fill="none"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* end, 02: notify by email  */}
    </div>
    {/* end, system messsages */}
  </>
);
