import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.Api = {};

window.Api.Base = env.REACT_APP_API_BASE

window.Api.User = {};

window.Api.User.Base = window.Api.Base + "/user"

window.Api.User.Login                 = window.Api.User.Base + "/login"
window.Api.User.Logout                = window.Api.User.Base + "/logout"
window.Api.User.Info                  = window.Api.User.Base + "/info"
window.Api.User.RequestResetPassword  = window.Api.User.Base + "/requestResetPassword"
window.Api.User.ResetPassword         = window.Api.User.Base + "/resetPassword"
window.Api.User.RefreshToken          = window.Api.User.Base + "/login/refresh"

window.Api.Register                   = window.Api.Base + "/companies/members"

window.Api.Submissions                = window.Api.Base + "/submissions"

window.Api.Images                     = window.Api.Base + "/images"
window.Api.ImagesSearch               = window.Api.Base + "/images-search"

window.Api.ContentBlocks              = window.Api.Base + "/content-blocks"

window.Api.DemoContentBlocks          = window.Api.Base + "/demoContentBlocks"

window.Api.Menus                      = window.Api.Base + "/menus"
window.Api.Footers                    = window.Api.Base + "/footers"
window.Api.Forms                      = window.Api.Base + "/forms"
window.Api.Pages                      = window.Api.Base + "/pages"
window.Api.Websites                   = window.Api.Base + "/websites"
window.Api.Hashes                     = window.Api.Base + "/hashes"
window.Api.Contacts                   = window.Api.Base + "/contacts"
window.Api.Articles                   = window.Api.Base + "/articles"
window.Api.CartItems                  = window.Api.Base + "/cart-items"
window.Api.Packages                   = window.Api.Base + "/packages"
window.Api.Fonts                      = window.Api.Base + "/fonts"
window.Api.Messages                   = window.Api.Base + "/messages"
window.Api.Files                      = window.Api.Base + "/files"
window.Api.Vouchers                   = window.Api.Base + "/vouchers"

window.Api.StripePubishableKey        = env.REACT_APP_STRIPE_PUBLISHABLE_KEY
window.Api.RecaptchaSiteKey           = env.REACT_APP_RECAPTCHA_SITE_KEY

window.Api.AddBlocksPageId            = env.REACT_APP_BLOCKS_PAGE_ID

window.Api.GTMID                      = env.REACT_APP_GTM_ID

window.Api.Jobs                       = window.Api.Base + "/jobs"
window.Api.JobbioJobs                 = env.REACT_APP_JOBBIO_JOBS_API_BASE
