import React from "react";

import General from "../../utils/General";
import Backend from "../../utils/Backend";

import Img from "./editable/Img";
import Section from "./editable/Section";

import Collapsible from "react-collapsible";
import Border from "../Border";

import ImageInputItem from "../sectioninputs/ImageInputItem";

import LinkSelect from "../LinkSelect";

import { DEFAULT_PRIMARY_BUTTON } from "./53";

import Titles from './editable/Titles';

export default class Block45 extends React.Component {
  constructor(props) {
    super(props);

    // key is incremented as the slider js functions
    // overwrite the html so we want to refresh it
    // as React is out of sync when
    // adding/editing/deleting the images
    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable,
      windowWidth: window.innerWidth,
    };

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(
      () => {
        Backend.updateContentBlock(this.state.data);
      },
      1000,
      false
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _update(data, callback) {
    this.setState({ ...data }, () => {
      if (callback) {
        callback();
      }
      this.updateContentBlock();
    });
  }

  _renderModalContent(data) {
    return (
      <Border>
        <Collapsible trigger="Logos">
          <br />
          {this._renderModalContentImages(data)}
        </Collapsible>
      </Border>
    );
  }

  _renderModalContentImages(data) {
    let imageInfos = data?.image_infos;

    return imageInfos.map((imageInfo, index) => {
      return (
        <>
          <ImageInputItem
            key={imageInfo.image.id}
            name="Logo"
            index={index}
            image={imageInfo.image}
            onUpdated={(uploadedImage) => {
              imageInfo.image = uploadedImage;
              imageInfos[index] = imageInfo;
              data.image_infos = imageInfos;

              this._update(
                {
                  data,
                  key: this.state.key + 1,
                },
                () => General.updateSlides()
              );
            }}
            onDelete={() => {
              if (data.image_infos.length == 2) {
                alert("There must be at least 2 logos");
                return;
              }

              imageInfos.splice(index, 1);
              data.image_infos = imageInfos;

              this._update(
                {
                  data,
                  key: this.state.key + 1,
                },
                () => General.updateSlides()
              );
            }}
            renderHeader={() => this._renderLogosLink(data, index)}
          />
        </>
      );
    });
  }

  _renderLogosLink(data, index) {
    let imageInfo = data.image_infos[index];

    return (
      <>
        <div className="mb--1">
          <span>Link</span>
          <LinkSelect
            url={imageInfo?.button?.url}
            onUpdated={(url) => {
              if (!imageInfo.button) {
                imageInfo.button = DEFAULT_PRIMARY_BUTTON;
              }
              imageInfo.button.url = url;
              data.image_infos[index] = imageInfo;

              this._update(data);
            }}
          />
        </div>
        {imageInfo.button && !imageInfo.button?.url?.startsWith("/#") && (
          <>
            <span>Open In New Tab</span>
            <select
              className="mb-3"
              value={imageInfo?.button?.open_in_new_tab}
              onChange={(event) => {
                if (!imageInfo.button) {
                  imageInfo.button = General.clone(DEFAULT_PRIMARY_BUTTON);
                }
                imageInfo.button.open_in_new_tab =
                  event.target.value === "true";
                data.image_infos[index] = imageInfo;

                this._update(data);
              }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </>
        )}
      </>
    );
  }

  _renderImages(data, editable) {
    let imageInfos = data?.image_infos;

    if (!imageInfos) {
      return null;
    }

    return imageInfos.map((imageInfo, index) => {
      return (
        <li
          key={imageInfo.image.id}
          class="col-md-3 col-6"
          style={{
            cursor: imageInfo.button?.url ? "pointer" : "",
          }}
          onClick={() => {
            if (!imageInfo.button?.url) {
              return;
            }

            if (imageInfo.button?.open_in_new_tab) {
              window.open(imageInfo.button?.url);
            } else {
              window.location.href = imageInfo.button?.url;
            }
          }}
        >
          <Img
            alt="Image"
            class="image--xl"
            img={imageInfo.image}
            block={55}
            priority={this.props.index}
          />
        </li>
      );
    });
  }

  _handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
    setTimeout(() => {
      General.updateSlides();
    }, 200);
  };

  componentDidMount() {
    window.addEventListener("resize", this._handleResize);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this._handleResize);
  }

  render() {
    let { data, key, editable, windowWidth } = this.state;

    return (
      <Section
        ref={this.section}
        data={data}
        index={this.props.index}
        className={" block-44 block-55 " + this.props.className}
        editable={editable}
        onUpdated={(data) => this._update(data)}
        onDelete={() => this.props.onDelete()}
        onAddContentBlockPressed={(contentBlock) =>
          this.props.onAddContentBlockPressed(contentBlock)
        }
        renderModalContent={() => this._renderModalContent(data)}
        primaryColor={false}
        secondaryColor={false}
        imageTitle={"Add Logo"}
        onImageUpdated={(image) => {

          let imageInfo = {
            image,
            title: "Title goes here",
            subtitle: "Subtitle goes here",
            settings: {},
            button: General.clone(DEFAULT_PRIMARY_BUTTON),
          };

          data.image_infos.push(imageInfo);

          this._update(
            {
              data,
              key: this.state.key + 1,
            },
            () => General.updateSlides()
          );
        }}
        canMoveUp={this.props.canMoveUp}
        canMoveDown={this.props.canMoveDown}
        onMoveUpPressed={() => this.props.onMoveUpPressed()}
        onMoveDownPressed={() => this.props.onMoveDownPressed()}
      >
        <div className="container">

          <Titles 
            data={ data }
            editable={ editable }
            onUpdated={data => this._update(data)}
            headingNumber={2}
          />

          <div className="row">
            <div className="col">
              {/* tablets + desktop : min screen width should be above 767px  */}
              {windowWidth > 767 && (
                <>
                  {/* if images are below 5, setting slider data values to false */}
                  <div
                    key={key}
                    className="slider slider--inline-arrows slider--arrows-hover text-center"
                    data-arrows={data.image_infos.length > 4 ? "true" : "false"}
                    data-paging={data.image_infos.length > 4 ? "true" : "false"}
                    data-autoplay={
                      data.image_infos.length > 4 ? "true" : "false"
                    }
                    data-draggable={
                      data.image_infos.length > 4 ? "true" : "false"
                    }
                  >
                    <ul className="slides">
                      {this._renderImages(data, editable)}
                    </ul>
                  </div>
                </>
              )}

              {/* mobiles : max screen width should be below 768px  */}
              {windowWidth < 768 && (
                <>
                  {/* if images are below 3, setting slider data values to false */}
                  <div
                    key={key}
                    className="slider slider--inline-arrows slider--arrows-hover text-center"
                    data-arrows={data.image_infos.length > 2 ? "true" : "false"}
                    data-paging={data.image_infos.length > 2 ? "true" : "false"}
                    data-autoplay={
                      data.image_infos.length > 2 ? "true" : "false"
                    }
                    data-draggable={
                      data.image_infos.length > 2 ? "true" : "false"
                    }
                  >
                    <ul className="slides">
                      {this._renderImages(data, editable)}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Section>
    );
  }
}
