import React from "react";

import { Helmet } from "react-helmet";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import FlipMove from "react-flip-move";

import ContentBlock from "./ContentBlock";
import ContentBlockLoader from "./ContentBlockLoader";
import AddContentBlockButton from "./AddContentBlockButton";

import SubscriptionModal from "./modal/SubscriptionModal";
import LeadModal from "./modal/LeadModal";

import ScriptCache from "../utils/ScriptCache";

import Backend from "../utils/Backend";
import General from "../utils/General";
import Website from "../utils/Website";
import Event from "../utils/Event";

import { CLOSE_TOUR } from "../constants/Events";

import $ from "jquery";

var qs = require("qs");

export default class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      editable: this.props.editable,
      website: props.website,
      page: props.page,
      canAnimate: false,
      isTourOpen: false,
      animatingContentBlockKey: null,
      canShowDashboardButtons: props.canShowDashboardButtons,
      afterTourCloses : false
    };
  }

  componentDidMount() {
    this._setup();

    Event.on(CLOSE_TOUR, () => {
        this.setState({
          isTourOpen: false,
          afterTourCloses: true
        });
        this._updateOnboarded();
      }
    );

  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  componentWillUnmount() {
    Event.off(CLOSE_TOUR);
  }

  disableBody = (target) => disableBodyScroll(target);
  enableBody = (target) => enableBodyScroll(target);

  _setup() {
    let { page, show404, website } = this.state;

    if (this.state.show404) {
      return;
    }

    let contentBlocks = page.content_blocks.map((contentBlock) => {
      contentBlock.uuid = General.uuid();
      return contentBlock;
    });

    this.setState(
      {
        contentBlocks,
        page_id: page.id,
        websiteId: website.id,
        loading: false,
        website: website,
        page: page,
      },
      () => this._handleHash()
    );

    // prefetch demo blocks
    if (this.state.editable) {
      Backend.getDemoContentBlocks();
      Backend.getPages(window.CURRENT_WEBSITE_ID);
    }
  }

  _onTourClicked() {
    if (this.state.isTourOpen) {
      this.setState({ isTourOpen: false });
    } else {
      let { website } = this.state;
      this.setState({ website, isTourOpen: true });
      this._updateOnboarded();
    }
  }

  _updateOnboarded(){
    let { website } = this.state;
    if (website.onboarded) {
      return
    }
    website.onboarded = true;
    Backend.markOnboarded(this.state.website);
  }

  _handleHash() {
    let hash = window.location.hash;

    if (hash === "") {
      return;
    }

    if ($(hash).length === 0) {
      return;
    }

    setTimeout(() => {
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $(hash).offset().top - 100,
          },
          800,
          "swing"
        );
    }, 1200);
  }

  _loadJs() {
    ScriptCache.loadDefaults();
  }

  _moveUp(index) {
    this._move(index, false);
  }

  _moveDown(index) {
    this._move(index, true);
  }

  _onDashboardClicked() {
    let url = Website.getDashboardUrl(this.state.website);

    let editWebsiteTab = window.open();
    this.setState({ loadingHash: true });
    return Backend.createLoginHash()
      .then((hash) => {
        editWebsiteTab.location.href = url + "?h=" + hash.value + "&wid=" + this.state.website.id
        this.setState({ loadingHash: false });
      })
      .catch((error) => {
        window.open(url);
        this.setState({ loadingHash: false });
      });
  }

  _move(sourceIndex, down = true) {
    let contentBlocks = [...this.state.contentBlocks];

    let contentBlock = contentBlocks[sourceIndex];

    let destinationIndex = down ? sourceIndex + 1 : sourceIndex - 1;

    contentBlocks.splice(sourceIndex, 1);
    contentBlocks.splice(destinationIndex, 0, contentBlock);

    this.setState({
      contentBlocks,
      canAnimate: true,
      animatingContentBlockKey: contentBlock.uuid,
      showCustomModal: false,
    }, () => {
      setTimeout(() => this._onFinishFlipMove(), 500)
    });
    Backend.updateOrder(contentBlocks, this.state.page_id);
  }

  _addContentBlock(contentBlock, index) {
    let { page, contentBlocks } = this.state;

    let data = {
      ...contentBlock,
      anchor: contentBlock.anchor + "_" + new Date().getTime(),
      order: index + 1,
      page_id: this.state.page_id,
    };

    Backend.addContentBlock(data).then((newContentBlock) => {
      contentBlock.uuid = General.uuid();
      contentBlocks.splice(index, 0, newContentBlock);
      page.content_blocks = contentBlocks;
      this.setState(
        {
          page,
          contentBlocks,
        },
        () => General.updateAll()
      );
    });
  }

  _onDeleteContentBlock(contentBlock, index) {
    let { contentBlocks } = this.state;

    contentBlocks.splice(index, 1);
    this.setState(
      {
        contentBlocks,
        showAddContentBlockModal: false,
        showCustomModal: false,
      },
      this._onContentBlocksUpdated()

    );

    Backend.deleteContentBlock(contentBlock);
  }

  renderModal(content) {
    this.setState({
      content,
      showCustomModal: true,
    });
  }

  _onContentBlocksUpdated() {
    General.updateAll()

    let { contentBlocks } = this.state;
    let previewImage = null;
    if (contentBlocks && contentBlocks.length > 0) {
      let contentBlock = contentBlocks[0];
      window.CURRENT_PAGE_PREVIEW = contentBlock.preview_image;
    }
  }

  _onFinishFlipMove = General.debounce(
    () => {
      this.setState(
        { animatingContentBlockKey: null },
        this._onContentBlocksUpdated()
      );
    },
    50,
    false
  );

  _renderContentBlocks() {
    let { websiteId, contentBlocks, editable, animatingContentBlockKey } =
      this.state;

    if (!contentBlocks || contentBlocks.length === 0) {
      return this._renderAddContentBlockButton(editable);
    }

    let contentBlocksAnimating = animatingContentBlockKey != null;

    this._loadJs();

    return contentBlocks.map((contentBlock, index) => {
      let canMoveUp = index !== 0 && !contentBlocksAnimating;
      let canMoveDown =
        index !== contentBlocks.length - 1 && !contentBlocksAnimating;

      let className = contentBlocksAnimating ? "animating" : "";
      if (this.state.animatingContentBlockKey === contentBlock.uuid) {
        className += " animating-focus";
      }

      return (
          <ContentBlock
            key={contentBlock.uuid}
            data={contentBlock}
            websiteId={websiteId}
            className={className}
            editable={editable}
            index={index}
            onDelete={() => this._onDeleteContentBlock(contentBlock, index)}
            onAddContentBlockPressed={(newContentBlock) =>
              this._addContentBlock(newContentBlock, index + 1)
            }
            onAddToCartPressed={(product, variant) =>
              this.props.onAddToCartPressed(product, variant)
            }
            renderModal={(content) => this.renderModal(content)}
            canMoveUp={canMoveUp}
            canMoveDown={canMoveDown}
            onMoveUpPressed={() => {
              this._moveUp(index);
            }}
            onMoveDownPressed={() => {
              this._moveDown(index);
            }}
          />
      );
    });
  }

  _renderAddContentBlockButton(editable) {
    if (!editable) {
      return null;
    }
    return (
      <AddContentBlockButton
        asSection={true}
        onAddContentBlockPressed={(newContentBlock) =>
          this._addContentBlock(newContentBlock, 0)
        }
      />
    );
  }

  _renderPortalPlaceholder(id, className) {
    return <div id={id} className={className}></div>;
  }

  _renderDashboardButton() {
    let {
      editable,
      loading,
      loadingHash,
      canShowDashboardButtons,
      isTourOpen,
      website,
      afterTourCloses
    } = this.state;

    if (!editable || loading || !canShowDashboardButtons) {
      return;
    }

    let className = "btn-fixed-left-container ignore-user-set-font";

    if (isTourOpen) {
      className += " show-tour";
    }

    if (afterTourCloses) {
      setTimeout(() => {
        this.setState({
          afterTourCloses: false,
        })
      }, 3000);
    }

    return (
      <div className={className}>
        {/* Starts : Button 01 */}
        <div className="d-flex">
          <button
            className="btn"
            title="Dashboard"
            disabled={loadingHash}
            onClick={() => this._onDashboardClicked()}
          >
            <span className="btn__text">
              <span class="material-icons">timeline_outline</span>
              <span class="slide-text">Dashboard</span>
            </span>
          </button>
          <div className="c-tool-tips seven">
            {General.renderTourButtonTitle("right", "Dashboard")}
          </div>
        </div>
        {/* End : Button 01 */}

        {/* Starts : Button 02 */}
        <div className="d-flex">
          <button
            className={`btn tour-start-button ${ ( afterTourCloses || !website.onboarded ) ? "active" : ""}`}
            title="Info"
            onClick={() => this._onTourClicked()}
          >
            <span className="btn__text">
              <span class="material-icons">star_outline</span>
              <span class="slide-text">
                {afterTourCloses ? "I'm Always Here" : (isTourOpen ? "Hide Quick Tips" : "Show Quick Tips")}
              </span>
            </span>
          </button>
          <div
            className={`c-tool-tips one ${ !website.onboarded ? "show" : ""}`}
          >
            {General.renderTourButtonTitle(
              "right",
              isTourOpen ? "Close Tips" : "Click Me"
            )}
          </div>
        </div>
        {/* End : Button 02 */}

        {/* Starts : Button 03 */}
        <div className="d-flex">
          <a
            className="btn styling-button"
            title="Styling"
            onClick={() => this.props.onShowWebsiteModalPressed()}
          >
            <span className="btn__text">
              <span class="material-icons">brush_outline</span>
              <span class="slide-text">Styling</span>
            </span>
          </a>
          <div className="c-tool-tips six">
            {General.renderTourButtonTitle("right", "Control Style")}
          </div>
        </div>
        {/* End : Button 03 */}
      </div>
    );
  }

  _renderMeta() {
    let { page, website, contentBlocks } = this.state;

    if (website == null) {
      return null;
    }

    let name = website.name;
    if (!page.is_homepage) {
      name = page.title + " | " + website.name;
    }

    let image = null;
    if (contentBlocks.length > 0) {
      let firstBlock = contentBlocks[0];
      image = firstBlock.background_image;
    }

    let favIconUrl = website.favicon
      ? website.favicon.original
      : "/amply_favicon_32x32px.png";

    return (
      <Helmet>
        <title>{name}</title>
        <meta
          name="description"
          content={page.description || website.descrption || name}
        />
        <meta name="keywords" content={page.keywords || website.keywords} />
        <link rel="icon" href={favIconUrl} />
        <meta property="og:title" content={name} />

        {image && <meta property="og:image" content={image.thumbnail} />}

        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
    );
  }

  render() {
    let { page, editable, tourStep, website, contentBlocks, canAnimate, isTourOpen } =
      this.state;

    if (!website || !contentBlocks) {
      return null;
    }

    let className = "main-container";

    if (window.IS_CARD_STYLE_WEBSITE) {
      className += " website-style-card";
    } else {
      className += " website-style-flat";
    }

    if (isTourOpen) {
      className += " show-tour";
    }

    return (
      <>
        {this._renderMeta()}
        <FlipMove
          id="main-container"
          className={className}
          duration={400}
          enterAnimation={canAnimate}
          onFinishAll={(children, nodes) => {
            // onFinishAll is sometimes not called
            // https://github.com/joshwcomeau/react-flip-move/issues/209
            // so we debounce onFinish instead
          }}
          onFinish={(child, node) => this._onFinishFlipMove()}
        >
          {this._renderContentBlocks()}
        </FlipMove>
        {this._renderPortalPlaceholder("portal-modal")}
        {this._renderPortalPlaceholder("icon-picker-modal")}
        <div id="notification-container" class="text-center">
          <div
            id="notification-content"
            onClick={() => {
              window.location =
                window.General.ClientDashboardUrl + "?subscribe=true";
            }}
          ></div>
        </div>
        {this._renderDashboardButton()}
        <SubscriptionModal website={website} editable={editable}/>
        <LeadModal website={website} />
      </>
    );
  }
}

Page.defaultProps = {
  canShowDashboardButtons: true,
};
