import React from 'react'
import Collapsible from 'react-collapsible';

import moment from 'moment'
import ReCAPTCHA from "react-google-recaptcha";

import Button from '../Button'
import Border from '../Border'
import ColorPicker from '../ColorPicker'
import Map from '../Map'

import H1 from './editable/H1'
import P from './editable/P'
import Img from './editable/Img'
import Section from './editable/Section'

import General from '../../utils/General'
import Backend from '../../utils/Backend'
import Notify from '../../utils/Notify'

import Event from '../../utils/Event'
import Form from "../form/Form";
import Dropdown from "../Dropdown";
import ImageUploader from "./editable/ImageUploader";

import Titles from './editable/Titles';

let VARIANTS = [
  {
    value: "standard",
    label: "Standard"
  },
  {
    value: "image_left",
    label: "Image Left"
  },
  {
    value: "image_right",
    label: "Image Right"
  },
  {
    value: "map_left",
    label: "Map Left"
  },
  {
    value: "map_right",
    label: "Map Right"
  },
]

export default class Block42 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      websiteId: props.websiteId,
      editable: props.editable,
    }

    this.recaptcha = React.createRef()
    this.updateContentBlock = General.debounce(() => {
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentDidMount(){
    let {
      websiteId
    } = this.state

    Backend.getCRMForm(websiteId)
    .then(forms => {
      this.setState({
        crmForm: forms[0],
        loadingWebsiteForms: false
      }, () => General.updateImageBackgrounds())
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    this.setState({ ...data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _updateSetting(key, value){
    let { data } = this.state
    data.settings[key] = value

    data.settings.msc_enabled = false
    this._update(data)
  }

  _getModalContent(data){

    let variation = data.variation || "standard"

    let imageTitle = "Left Image"
    if(variation === "image_right"){
      imageTitle = "Right Image"
    }

    return (
      <>
        <Border>
          <Collapsible
            trigger="Form Colours"
          >
            { this._renderColor('quaternary_color', 'quaternaryColorTitle', "Form Background Colour", data) }
            { 
              //default text color of the Entire Form
              this._renderColor('form_text_color', 'formTextColour', "Form Text Colour", data) 
            }
            {
              //use on placeholders || labels for inputs
              this._renderColor('form_fields_placeholder_color', 'formFieldsPlaceholderColour', "Form Fields Placeholder Colour", data) 
            } 
            { 
              //use when text is enter into input / in dropdown menu
              this._renderColor('form_fields_text_color', 'formFieldsTextColour', "Form Fields Text Colour", data) 
            }
            { this._renderColor('quinary_color', 'quinaryColorTitle', "Form Button Title Color", data) }
            { this._renderColor('senary_color', 'senaryColorTitle', "Form Button Background Color", data) }
          </Collapsible>
        </Border>

        <Border>
          <Collapsible trigger="Form Style">
            <br/>
            <span>Form Style</span>
            <Dropdown
              options={VARIANTS}
              value={VARIANTS.find(mode => mode.value === data.variation)}
              onChange={option => {
                data.variation = option.value
                if(data.variation.indexOf("map") > -1){
                  data.map = JSON.parse(JSON.stringify(DEFAULT_MAP))
                }
                else{
                  data.map = null
                }
                this._update(data, () => General.updateImageBackgrounds())
              }}
              placeholder="Form Style"
            />
          </Collapsible>
        </Border>
        {variation.indexOf("image") > -1 &&
          <Border>
            <Collapsible
              trigger={imageTitle}
            >
              <br/>
              <ImageUploader
                title={""}
                src={data.foreground_image?.original}
                onUploaded={image => {
                  data.foreground_image = image
                  this._update(data, () => General.updateImageBackgrounds())
                }}
              />
            </Collapsible>
          </Border>
        }

      </>
    )

  }

  _renderColor(colorKey, titleKey, defaultTitle, data){
    if(this.props[titleKey] === false){
      return null
    }

    return (
      <>
        <ColorPicker
          title={ this.props[titleKey] || defaultTitle }
          color={ data.settings[colorKey] }
          onColorSelected={color => {
            this._updateSetting(colorKey, color)
          }}
        />

        <br/>
      </>
    )
  }

  _renderFormStyles(){
    let {
      data
    } = this.state

    let form_fields_text_color_opacity;
    if(data.settings.form_fields_text_color){
      form_fields_text_color_opacity = `${data.settings.form_fields_text_color}03`
    } 

    return (
      <style dangerouslySetInnerHTML={{__html: `
        .MuiButton-textPrimary{
          color:${data.settings.senary_color} !important;
        }
        #${data.anchor} .form-crm .MuiFormControlLabel-label a,
        #${data.anchor} .form-crm .MuiIconButton-root{
          color:${data.settings.senary_color} !important;
        }

        /* New */

        /* use for form default text color */
        #${data.anchor} .form-crm,
        #${data.anchor} .form-crm .custom-file-label{
          color:${data.settings.form_text_color} !important;
        }
        
        /* use on placeholders for inputs */
        #${data.anchor} .form-crm .MuiInputLabel-formControl,
        #${data.anchor} .form-crm .custom-file-label.placeholder{ 
          color:${data.settings.form_fields_placeholder_color} !important;
        }
        
        /* use when text is enter into input / in dropdown menu and checked colors */
        #${data.anchor} .form-crm .MuiInputBase-input,
        #${data.anchor} .form-crm .custom-file-label.value,
        #${data.anchor} .form-crm .MuiCheckbox-colorSecondary.Mui-checked,
        #${data.anchor} .form-crm .MuiRadio-colorSecondary.Mui-checked,
        #${data.anchor} .form-crm .MuiSwitch-colorSecondary.Mui-checked{
          color:${data.settings.form_fields_text_color} !important;
        }
        #${data.anchor} .form-crm .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: ${data.settings.form_fields_text_color} !important;
        }

        /* feilds hover || checked background color with opacaity */
        #${data.anchor} .form-crm .MuiRadio-colorSecondary.Mui-checked:hover,
        #${data.anchor} .form-crm .MuiIconButton-colorSecondary:hover,
        #${data.anchor} .form-crm .MuiSwitch-colorSecondary.Mui-checked:hover,
        #${data.anchor} .form-crm .MuiCheckbox-colorSecondary.Mui-checked:hover{
          background-color: ${form_fields_text_color_opacity} !important;
        }
        
        /* feilds background color  */
        #${data.anchor} .form-crm .MuiSwitch-colorSecondary + .MuiSwitch-track{
          background-color: ${data.settings.senary_color}B3 !important;
        }
        .MuiPickersDay-daySelected{
          background-color: ${data.settings.form_fields_text_color} !important;
        }

      `}}
    />
    )
  }

  _renderForm(){
    let {
      data,
      crmForm,
    } = this.state

    return (
      <>
        <Form
          data={data}
          form={crmForm}
        />
        { this._renderFormStyles() }
      </>
    )
  }

  _renderFormTitle(){
    let {
      data,
      editable,
    } = this.state

    return (
      <Titles 
        data={ data }
        editable={ editable }
        onUpdated={data => this._update(data)}
        columnCssClasses={"col"}
        headingCssClasses={"h2 h1-font-size"}
      />
    )
  }

  _renderFormAside(){
    let {
      data,
    } = this.state
    
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-7">
            {this._renderFormTitle()}
            <div
              className="form-container form-crm"
              style={{
                backgroundColor: data.settings.quaternary_color
              }}
            >
              {this._renderForm()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderFormImage(){
    let {
      data,
    } = this.state

    let backgroundImageCSS = "background-image-holder"
    if(data.variation.indexOf("left") > -1){
      backgroundImageCSS += ' align-left'
    } else if(data.variation.indexOf("right") > -1){
      backgroundImageCSS += ' align-right'
    }

    return (
      <>
        <div className="imageblock__content col-lg-6 col-md-4 pos-right">
          <div className={backgroundImageCSS}>
            <Img
              alt=""
              img={ data.foreground_image }
              block={42}
              priority={this.props.index}
            />
          </div>
        </div>
        { this._renderFormAside() }
      </>
    )
  }

  _renderFormMap(){
    let {
      data,
    } = this.state

    let mapContainerCSS = "map-container"
    if(data.variation.indexOf("left") > -1){
      mapContainerCSS += ' align-left'
    } else if(data.variation.indexOf("right") > -1){
      mapContainerCSS += ' align-right'
    }

    return (
      <>
        <div className="imageblock__content col-lg-6 col-md-4 col-12 pos-right">
          <div className={mapContainerCSS}>
                <Map
                  map={ data.map }
                  google={ this.props.google }/>
            </div>
        </div>
        { this._renderFormAside() }
      </>
    )
  }

  render(){
    let {
      data,
      editable,
      crmForm,
      loading
    } = this.state

    if(data.settings.msc_enabled){
      data.settings.senary_color = window.CURRENT_PAGE_PRIMARY_COLOR
    }

    let formClass = 'text-center'

    let variation = data.variation || "standard"

    if(variation.indexOf("left") > -1){
      formClass = 'block-13 imageblock switchable feature-large'
    }
    else if(variation.indexOf("right") > -1){
      formClass = 'imageblock block-14 switchable feature-large switchable--switch'
    }

    let showImageForm = crmForm && (variation === "image_left" || variation === "image_right")
    let showMapForm = crmForm && (variation === "map_left" || variation === "map_right")

    return (
      <Section
        data={ data }
        index={ this.props.index }
        className={` block-42 ${formClass} ${this.props.className}`}
        editable={ editable }
        onUpdated={data => this._update(data)}
        onDelete={() => this.props.onDelete()}
        primaryColor={false}
        secondaryColor={false}
        onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
        renderModalContent={() => this._getModalContent(data)}
        onUpdateSettings={settings => {
          data.settings = settings
          this._update(data)
        }}
        canMoveUp={ this.props.canMoveUp }
        canMoveDown={ this.props.canMoveDown }
        onMoveUpPressed={ () => this.props.onMoveUpPressed() }
        onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >

        { (crmForm && !showImageForm && !showMapForm) &&
          <div className="container">
            <div className="row justify-content-center no-gutters">
              <div className="col-md-10 col-lg-8">
                {this._renderFormTitle()}
                <div
                  className="boxed boxed--border form-container form-crm"
                  style={{
                    backgroundColor: data.settings.quaternary_color
                  }}
                >
                  {this._renderForm()}
                </div>
              </div>
            </div>
          </div>
        }

        { (crmForm && showImageForm) &&
          this._renderFormImage()
        }

        { (crmForm && showMapForm) &&
          this._renderFormMap()
        }

      </Section>
    )
  }
}

const DEFAULT_MAP = {
  location: {
    raw: "32 Baggot Street Upper, Dublin, D04 AX88, Ireland",
    address_line_1: "32 Baggot Street Upper",
    address_line_2: "Dublin",
    address_line_3: "D04 AX88",
    city: "Dublin",
    country: "Ireland",
    country_short: "IE",
    latitude: 53.33353650000001,
    longitude: -6.243303399999999,
    postal_code: "D04 AX88",
    state: "County Dublin",
  },
  settings: {
  }
}
