import React from "react";

import H2 from "./editable/H2";
import P from "./editable/P";
import Img, { Sizes } from "./editable/Img";
import Section from "./editable/Section";

import Play from "../Play";

import General from "../../utils/General";
import Backend from "../../utils/Backend";

export default class Block19 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      editable: props.editable,
    };

    this.updateContentBlock = General.debounce(
      () => {
        console.log("3333", this.state.data);
        Backend.updateContentBlock(this.state.data);
      },
      1000,
      false
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _update(data, callback) {
    this.setState({ data }, () => {
      if (callback) {
        callback();
      }
      this.updateContentBlock();
    });
  }

  render() {
    let { data, editable } = this.state;
    let showTitle = data.title

    return (
      <Section
        data={data}
        index={this.props.index}
        className={
          "imageblock block-19 switchable feature-large " + this.props.className
        }
        editable={editable}
        onUpdated={(data) => this._update(data)}
        onDelete={() => this.props.onDelete()}
        onAddContentBlockPressed={(contentBlock) =>
          this.props.onAddContentBlockPressed(contentBlock)
        }
        renderModal={(content) => this.props.renderModal(content)}
        image={data.foreground_image}
        imageTitle={"Left Image"}
        onImageUpdated={(image) => {
          data.foreground_image = image;
          this._update(data, () => General.updateImageBackgrounds());
        }}
        primaryColor={false}
        canMoveUp={this.props.canMoveUp}
        canMoveDown={this.props.canMoveDown}
        onMoveUpPressed={() => this.props.onMoveUpPressed()}
        onMoveDownPressed={() => this.props.onMoveDownPressed()}
      >
        <div className="imageblock__content col-lg-6 col-md-4 pos-right">
          <div className="background-image-holder align-left lazy-background">
            <Img
              alt=""
              img={data.foreground_image}
              block={19}
              priority={this.props.index}
            />
          </div>
          <Play
            overlay={data.video_button.settings.overlay}
            icon={data.video_button.icon}
            iconImage={data.video_button.icon_image}
            videoUrl={data.video_button.url}
            editable={editable}
            onUpdated={(videoUrl, icon, iconImage, overlay) => {
              data.video_button.url = videoUrl;
              data.video_button.icon = icon;
              data.video_button.icon_image = iconImage;
              data.video_button.settings.overlay = overlay;
              this._update(data);
            }}
          />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-7">

              {showTitle && 
                <H2
                  text={data.title}
                  color={data.title_color}
                  editable={editable}
                  textAlign="left"
                  onUpdated={(text) => {
                    data.title = text;
                    this._update(data);
                  }}
                />
              }

              <P
                text={data.body}
                color={data.settings.secondary_color}
                editable={editable}
                textAlign="left"
                onUpdated={(text) => {
                  data.body = text;
                  this._update(data);
                }}
              />
            </div>
          </div>
        </div>
      </Section>
    );
  }
}
