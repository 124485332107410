import React from "react";

import IconSettingsModal from "./modal/settings/IconSettingsModal";

import PortalModal from "./modal/PortalModal";

import H4 from "./blocks/editable/H4";
import P from "./blocks/editable/P";

import Button from "./Button";
import { data } from "jquery";

export default class IconInfos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      iconInfos: props.iconInfos,
      iconColor: props.iconColor,
      titleColor: props.titleColor,
      subtitleColor: props.subtitleColor,
      editable: props.editable,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderIconInfoButton(iconInfo, iconInfos, index, editable, color) {
    if (!iconInfo.button) {
      return null;
    }

    return (
      <Button
        data={iconInfo.button}
        type="link"
        editable={editable}
        color={color}
        onUpdated={(button) => {
          iconInfo.button = button;
          iconInfos[index] = iconInfo;
          this.setState({ iconInfos });
          this.props.onUpdated(iconInfos);
        }}
      />
    );
  }

  _renderEditable(iconInfo, iconInfos, index, editable) {
    if (!editable) {
      return null;
    }

    return (
      <IconSettingsModal
        iconInfo={iconInfo}
        onUpdated={(iconInfo) => {
          iconInfos[index] = iconInfo;
          this.props.onUpdated(iconInfos);
        }}
      />
    );
  }

  render() {
    let { iconInfos, iconColor, titleColor, subtitleColor, editable } =
      this.state;

    if (!iconInfos) {
      return null;
    }

    return iconInfos.map((iconInfo, index) => {
      let icon = iconInfo.icon.replace(
        "icon--lg",
        this.props.iconSizeClassName
      );

      let iconAlignmentClassName = "";
      if (this.props.iconAlign === "center") {
        iconAlignmentClassName = "text-center";
      }
      return (
        <>
          <div key={iconInfo.id} className={this.props.containerClassName}>
            <div
              className={this.props.iconContainerClassName}
              style={{
                background: "transparent",
                cursor:
                  !this.state.editable && iconInfo.button?.url ? "pointer" : "",
              }}
              onClick={() => {
                if (this.state.editable || !iconInfo.button?.url) {
                  return;
                }

                if (iconInfo.button?.open_in_new_tab) {
                  window.open(iconInfo.button?.url);
                } else {
                  window.location.href = iconInfo.button?.url;
                }
              }}
            >
              <div className={`icon-div ${iconAlignmentClassName}`}>
                <span className="position-relative inline-block">
                  <i style={{ color: iconColor }} className={icon}></i>
                  {this._renderEditable(iconInfo, iconInfos, index, editable)}
                </span>
              </div>

              <H4
                text={iconInfo.title}
                color={titleColor}
                editable={editable}
                textAlign={this.props.textAlign}
                onUpdated={(text) => {
                  iconInfo.title = text;
                  iconInfos[index] = iconInfo;
                  this.props.onUpdated(iconInfos);
                }}
              />
              <P
                className={this.props.textAlign == "center" ? "mx-auto" : ""}
                text={iconInfo.subtitle}
                color={subtitleColor}
                editable={editable}
                textAlign={this.props.textAlign}
                lead={false}
                onUpdated={(text) => {
                  iconInfo.subtitle = text;
                  iconInfos[index] = iconInfo;
                  this.props.onUpdated(iconInfos);
                }}
              />
              {this._renderIconInfoButton(
                iconInfo,
                iconInfos,
                index,
                editable,
                titleColor
              )}
            </div>
          </div>
        </>
      );
    });
  }
}

IconInfos.defaultProps = {
  iconSizeClassName: "icon--lg",
  containerClassName: "col-md-4",
  textAlign: "center",
  iconAlign: "left",
  iconContainerClassName: "feature feature-3 boxed boxed--lg boxed--border",
};
