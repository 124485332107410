import React from "react";
import {Link} from "react-router-dom"
import moment from "moment";


import Img, { Sizes } from '../blocks/editable/Img'


export default class ArticleItem extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      article: props.article
    }
  }

  render() {
    let {
      article
    } = this.state

    let date = moment(article.published_at).format('MMMM Do YYYY')

    return (
      <>
        <div className="col-md-4 article-item item">
          <article className="feature feature-1 position-relative">
            {
              article.featured &&
              <span class="label product-sale-price">Featured</span>
            }
            <a
              href={"/news/article/" + article.slug}
              className="block"
            >
              <Img 
                className="bg-img-rounded" 
                alt="Image" 
                img={article.thumbnail} 
                size={Sizes.MEDIUM}
                block={"articleitem"}
                priority={this.props.index}
              />
            </a>
            <div className="feature__body boxed boxed--border">
              <h5 className="mb-0" style={{ minHeight: 0 }}>{article.title}</h5>
              <span className="block mb-4">{date}</span>
              <a
                href={"/news/article/" + article.slug}
                className="link--primary"
              >
                Read Full Article
              </a>
            </div>
          </article>
        </div>
      </>
    )
  }
}