import React from 'react'

import Collapsible from 'react-collapsible';

import Border from '../Border'

import Section from './editable/Section'

import IconInfos from '../IconInfos'

import Toggle from '../sectioninputs/Toggle';

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

export default class Block10 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable
    }

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _getModalContent(data){
    return (
      <>
        <Border>
          <Collapsible
            trigger="Buttons / CTAs"
          >
            <br></br>
            <Toggle
              id="buttons_toggle"
              checked={data.icon_infos[0].button != null}
              title="Display Buttons / CTAs"
              onChange={checked => {
                let defaultButton = DEFAULT_BUTTON
                defaultButton.settings.primary_color = window.CURRENT_PAGE_PRIMARY_COLOR
                data.icon_infos = data.icon_infos.map(iconInfo => {
                  iconInfo.button = checked ? defaultButton : null
                  return iconInfo
                })
                this._update(data)
              }}
            />
          </Collapsible>
        </Border>
      </>
    )
  }

  render(){
    let { data, editable } = this.state

    let blockClassName = " block-10 text-center ";

    if (window.IS_CARD_STYLE_WEBSITE) {
      blockClassName += " deep-cardify";
    }

    return (
      <Section
          data={ data }
          index={ this.props.index }
          className={ blockClassName +this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          primaryColorTitle="Icon Colour"
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          renderModalBottomContent={() => this._getModalContent(data)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="container">

            <Titles 
              data={ data }
              editable={ editable }
              onUpdated={data => this._update(data)}
              headingNumber={2}
            />

              <div className="row items">
                  <IconInfos
                    iconInfos={data.icon_infos}
                    iconColor={ data.settings.primary_color }
                    titleColor={ data.settings.primary_color }
                    subtitleColor={ data.settings.secondary_color }
                    editable={ editable }
                    onUpdated={iconInfos => {
                      data.icon_infos = iconInfos
                      this._update(data)
                    }}
                    containerClassName={ "col-md item" }
                  />
              </div>
          </div>
      </Section>
    )
  }
}

const DEFAULT_BUTTON = {
  badge: null,
  badge_settings: null,
  icon: null,
  settings: {
  },
  msc_enabled: true,
  title: "Learn More",
  url: null
}
