import React from 'react'
import Collapsible from 'react-collapsible';
import Border from '../Border'

import Section from './editable/Section'
import H2 from './editable/H2'
import P from './editable/P'

import Form from '../form/Form'

import FormSelect from '../FormSelect'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

export default class Block17 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      websiteId: props.websiteId,
      selectedForm: null,
      selectedFormIndex: 0,
      editable: props.editable,
      websiteForms: [],
      loadingWebsiteForms: false
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      let data = JSON.parse(JSON.stringify(this.state.data))
      let formIds = data.forms.map(form => {
        return form.id
      })
      data.forms = formIds
      console.log("3333", data)
      Backend.updateContentBlock(data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback, save=true){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }

      if(save){
        this.updateContentBlock()
      }
    })
  }

  componentDidMount(){
    let { data, editable, websiteId } = this.state
    if(!editable){
      return
    }

    this.setState({ loadingWebsiteForms: true })
    Backend.getForms(websiteId)
    .then(websiteForms => {
      this.setState({
        websiteForms,
        loadingWebsiteForms: false
      })
    })
  }

  _addForm(data, form){
      data.forms.push(form)
      this._update(data, () => this.setState({ selectedForm: null, selectedFormIndex: 0 }))
  }

  _getModalContent(data){
    return (
      <>
        <Border>
          <Collapsible
            trigger="Titles"
          >
            <br></br>
            <span>
              Title
            </span>
            <input
                type="text"
                defaultValue={ data.title }
                onChange={event => {
                  data.title = event.target.value
                  this._update(data)
                }}
            />
            <br/><br/>
            <span>
              Subtitle
            </span>
            <input
                type="text"
                defaultValue={ data.subtitle }
                onChange={event => {
                  data.subtitle = event.target.value
                  this._update(data)
                }}
            />
          </Collapsible>
        </Border>
        <Border>
          <Collapsible
            trigger="Forms"
          >
            {this._getAdvancedSettings(data)}
          </Collapsible>
        </Border>
      </>
    )
  }

  _getAdvancedSettings(data){
    return (
      <>
        <br/><br/>
        <h3>Add New Form</h3>
        <p>All forms that are available on this page, are listed below:</p>
        { this._renderCurrentForms(data) }
        { this._renderAddForm() }
        { this._renderPickerTitle(data) }
      </>
    )
  }

  _renderPickerTitle(data){
    if(data.forms.length < 2){
      return null
    }

    return (
      <>
        <br/><br/>
        <span>
          Form Picker Title
        </span>
        <input
            type="text"
            defaultValue={ data.body }
            onChange={event => {
              data.body = event.target.value
              this._update(data)
            }}
        />
        <br/><br/>
      </>
    )
  }

  _renderCurrentForms(data){
    return data.forms.map((form, index) => {
      return (
        <>

          <div key={form.id} className="row no-gutters">
            <div className="col-12">
              <div class="input-icon input-icon--right">

                <i>
                  <a
                    href="javascript:void(0)"
                    className="d-block lh-44"
                    onClick = {() => {
                      if(data.forms.length == 1){
                      alert("There must be at least 1 form in this block")
                      return
                    }
                      data.forms.splice(index, 1)
                      this._update(data, () => {
                        // TODO: Proper fix to wait for render to finish, perhaps componentDidUpdate?
                        setTimeout(() => General.updateFormListeners(), 600)
                      })
                    }}>
                      <img loading="lazy" height="32" src="assets/icons/cross.png" />
                  </a>
                </i>

                <input
                  type="text"
                  defaultValue={form.name}
                  onChange={event => {
                    let name = event.target.value
                    data.forms[index].name = name
                    Backend.updateForm(data.forms[index])
                    .then(form => {
                      this._update(data, null, false)
                    })
                  }}
                />

              </div>
            </div>
          </div>

          <br/>
        </>
      )
    })
  }

  _renderAddForm(){
    let {
      data,
      editable,
      websiteId,
      websiteForms,
      loadingWebsiteForms
    } = this.state

    if(loadingWebsiteForms){
      return null
    }

    let formsNotInBlock = websiteForms.filter(websiteForm => {
      return data.forms.find(form => form.id === websiteForm.id) === undefined
    })

    return (
      <FormSelect
        forms={formsNotInBlock}
        onFormSelected={form => {
          this._addForm(data, form)
        }}
        onAddNewFormSelected={() => {
          let newFormData = {
            "website": websiteId,
            "name": "New Event Session",
            "submit_text": "REGISTER",
            "form_fields": [
              [
                {
                  "title": "First Name",
                  "type": "text",
                  "is_required": true
                },
                {
                  "title": "Second Name",
                  "type": "text",
                  "is_required": true
                }
              ],
              [
                {
                  "title": "Email",
                  "type": "email",
                  "send_emails": true,
                  "is_required": true
                },
              ]
            ],
            "terms_text": null,
            "force_invite": false,
            "count_no": 0,
            "send_confirmation_email": true,
            "allow_updates": true,
            "send_updated_email": true,
          }
          Backend.addForm(newFormData)
          .then(newForm => {
            this._addForm(data, newForm)
          })
        }}
      />
    )

  }

  _renderDropdownOptions(forms){
    return forms.map(form => {
      return (
        <option key={ form.id } value={ form.id }>
          { form.name }
        </option>
      )
    })
  }

  _renderFormPicker(data){
    if(data.forms.length === 1){
      return null
    }

    return (
      <div class="input-select">
        <select
          defaultValue=""
          className="validate-required"
          onChange={event => {
            let formId = parseInt(event.target.value)
            let selectedForm = data.forms.find(form => form.id === formId)

            let selectedFormIndex = 0
            for(var i=0; i < data.forms.length; i++){
              if(selectedForm.id === data.forms[i].id){
                selectedFormIndex = i
                break;
              }
            }
            this.setState({
              selectedForm,
              selectedFormIndex
            }, () => General.updateFormListeners())
          }}
        >
            <option value="" disabled>{ data.body }</option>
            { this._renderDropdownOptions(data.forms) }
        </select>
        <br/><br/>
      </div>
    )
  }

  _renderForms(){
    let {
      data,
      editable,
      selectedForm,
      selectedFormIndex
    } = this.state

    let { forms } = data

    if(!forms || forms.length === 0){
      return null
    }
    else if(forms.length === 1){
      selectedForm = data.forms[0]
    }

    return (
      <div className="row justify-content-center no-gutters">
          <div className="col-md-10 col-lg-8">
              { this._renderFormPicker(data) }
              { selectedForm &&
                <div
                  className="boxed boxed--border form-container form-crm"
                  style={{
                    backgroundColor: data.settings.quaternary_color
                  }}
                >
                  <Form
                    data={data}
                    form={selectedForm}
                  />
                </div>
              }
              { General.renderFormStyles(data) }
          </div>
      </div>
    )
  }

  render(){
    let {
      data,
      editable,
      selectedForm,
      selectedFormIndex
    } = this.state

    return (
      <Section
          ref={this.section}
          data={ data }
          classNmae={ this.props.className }
          index={ this.props.index }
          className="section-17 block-17"
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() => this._getModalContent(data)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="container">
              <div className="row justify-content-center m-b-100">
                  <div className="col-md-10 col-lg-8 text-center">
                      <H2
                        text={ data.title }
                        color={ data.settings.primary_color }
                        editable={ editable }
                        onUpdated={text => {
                          data.title = text
                          this._update(data)
                        }}
                      />

                      <P
                        text={ data.subtitle }
                        color={ data.settings.secondary_color }
                        editable={ editable }
                        onUpdated={text => {
                          data.subtitle = text
                          this._update(data)
                        }}
                      />
                  </div>
              </div>
              { this._renderForms() }
          </div>
      </Section>
    )
  }
}
