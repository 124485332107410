import React from 'react'

import Collapsible from 'react-collapsible';
import Border from '../Border'
import ColorPicker from '../ColorPicker'

import Section from './editable/Section'

import H1 from './editable/H1'
import Toggle from '../sectioninputs/Toggle';

import { SocialIcon } from 'react-social-icons';

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

export default class Block55 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable,
      uuid: "cb_"+General.uuid(),
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)

      this.setState({
        key: this.state.key + 1
      }, () => General.updateSlides())
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    let {
      key
    } = this.state

    this.setState({ ...data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _getModalContent(data){

    return (
      <>

        <Border>
          <Collapsible trigger="Social Icons">

            <br/>
            { this._renderSocialInput("instagram", "Enter Instagram URL") }

            { this._renderSocialInput("twitter", "Enter X URL") }

            { this._renderSocialInput("facebook", "Enter Facebook URL") }

            { this._renderSocialInput("linkedIn", "Enter LinkedIn URL") }

            { this._renderSocialInput("tiktok", "Enter TikTok URL") }

            { this._renderSocialInput("youtube", "Enter YouTube URL") }

            { this._renderSocialInput("mail", "Enter Email") }

          </Collapsible>
        </Border>

      </>
    )
  }

  _renderSocialInput(key, placeholder){
    let { data } = this.state
    let socials = data.socials || {
      settings: {
        tertiary_color: "#000"
      }
    }

    let value = socials[key]
    let enabled = false
    if(value || value == ""){
      enabled = true
    }

    return (
      <>
        <Toggle
          id={key}
          checked={enabled}
          title={placeholder.replace("Enter", "Your")}
          onChange={checked => {
            socials[key] = checked ? "" : null
            data.socials = socials
            this._update(data)
          }}
        />
        {enabled && (
          <input
              type="text"
              defaultValue={value}
              placeholder={ placeholder }
              onChange={event => {
                socials[key] = event.target.value
                data.socials = socials
                this._update(data)
              }}
            />
          )}
        <br/><br/>
      </>
    )
  }

  _renderColor(colorKey, titleKey, defaultTitle){
    let { data } = this.state
    if(this.props[titleKey] === false){
      return null
    }

    let socials = data.socials || {
      settings: {
        tertiary_color: "#000"
      }
    }


    return (
      <>
        <ColorPicker
          title={ "Icons Background Colour" }
          color={ socials.settings.tertiary_color }
          onColorSelected={color => {
            socials.settings.tertiary_color = color
            socials.settings.msc_enabled = false
            data.socials = socials
            this._update(data)
          }}
        />

        <br/>
      </>
    )
  }

  _renderStyles(){
    let {
      uuid,
      data,
    } = this.state

    if(!data.socials){
      return null
    }

    let color = data.socials.settings.tertiary_color
    if(data.socials.settings.msc_enabled){
      color = window.CURRENT_PAGE_PRIMARY_COLOR
    }

    return (
      <style dangerouslySetInnerHTML={{__html: `
        .block-57 #${uuid} .items .item svg .social-svg-mask{
          fill: ${color} !important;
        }
      `}}
    />
    )
  }


  render(){
    let { data, editable, uuid, key } = this.state
    let textAlign = "center"

    let socials = [
      { key: "instagram", icon: "instagram"},
      { key: "twitter", icon: "twitter"},
      { key: "facebook", icon: "facebook"},
      { key: "linkedIn", icon: "linkedin"},
      { key: "tiktok", icon: "tiktok"},
      { key: "youtube", icon: "youtube"},
      { key: "mail", icon: "email"},
    ]

    return (
      <Section
          ref={this.section}
          data={ data }
          index={ this.props.index }
          className={ " block-57 text-center " + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          primaryColor={false}
          secondaryColor={false}
          renderModalContent={() => this._getModalContent(data)}
          renderSupplimentaryColors={() => this._renderColor()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div class="container" id={uuid}>

            <Titles
              data={ data }
              editable={ editable }
              onUpdated={data => this._update(data)}
            />

            {/* Stats : Row : Share  */}
            <div className='row'>
              <div className='col-md-8 col-lg-6'>

                  <div className='items'>

                    { socials.map(social => {
                        if(!data.socials || !data.socials[social.key]){
                          return null
                        }
                        if(social.key == "twitter"){
                          return (
                            <div className='item'>
                              <a
                                href={data.socials[social.key]}
                                class="social-icon"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="X"
                                style={{
                                  display: "inline-block",
                                  width: "50px",
                                  height: "50px",
                                  position: "relative",
                                  overflow: "hidden",
                                  verticalAlign: "middle"
                                }}
                              >
                                {X_ICON}
                              </a>
                            </div>
                          )
                        }
                        return (
                          <div className='item'>
                            <SocialIcon
                              network={social.icon}
                              url={data.socials[social.key]}
                              target="_blank"
                              rel="noopener noreferrer"
                            />
                          </div>
                        )
                      })
                    }


                  </div>

              </div>
            </div>
            {/* End : Row : Share  */}

            { this._renderStyles() }

          </div>
      </Section>
    )
  }
}

const X_ICON = (
  <span
    class="social-container"
    style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}
  >
    <svg
      role="img"
      aria-label="x social icon"
      class="social-svg"
      viewBox="0 0 64 64"
      style={{position: "absolute; top: 0px; left: 0px", width: "100%", height: "100%", borderRadius: "50%", fillRule: "evenodd;"}}
    >
      <g
        class="social-svg-icon"
        style={{transition: "fill 170ms ease-in-out 0s", fill: "white"}}
      >
          <path d="M0,0H64V64H0ZM0 0v64h64V0zm16 17.537h10.125l6.992 9.242 8.084-9.242h4.908L35.39 29.79 48 46.463h-9.875l-7.734-10.111-8.85 10.11h-4.908l11.465-13.105zm5.73 2.783 17.75 23.205h2.72L24.647 20.32z">
          </path>
      </g>
      <g
        class="social-svg-mask"
        style={{
          transition: "fill 170ms ease-in-out 0s", fill: "rgb(0, 0, 0);"
        }}
      >
        <path d="M0 0v64h64V0zm16 17.537h10.125l6.992 9.242 8.084-9.242h4.908L35.39 29.79 48 46.463h-9.875l-7.734-10.111-8.85 10.11h-4.908l11.465-13.105zm5.73 2.783 17.75 23.205h2.72L24.647 20.32z">
        </path>
      </g>
    </svg>
  </span>
)
