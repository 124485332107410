import React from "react";

import Collapsible from "react-collapsible";
import Border from "../Border";

import PortalModal from "../modal/PortalModal";

import Span from "./editable/Span";
import Section from "./editable/Section";

import ColorPicker from "../ColorPicker";

import General from "../../utils/General";
import Backend from "../../utils/Backend";

import Youtube from '../Youtube'

export default class Block20 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      editable: props.editable,
      showVideoModal: false,
      showEditModal: false,
    };

    this.updateContentBlock = General.debounce(
      () => {
        console.log("3333", this.state.data);
        Backend.updateContentBlock(this.state.data);
      },
      1000,
      false
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _update(data, callback) {
    this.setState({ data }, () => {
      if (callback) {
        callback();
      }
      this.updateContentBlock();
    });
  }

  _renderVideoModal(data) {
    let overlay = data.video_button.settings.overlay;

    let videoId = General.getYoutubeVideoId(data.video_button.url);

    let videoSize = "yt-video-md";

    if (overlay == 3) {
      videoSize = "yt-video-sm";
    } else if (overlay == 9) {
      videoSize = "yt-video-lg";
    }

    return (
      <PortalModal
        show={this.state.showVideoModal}
        showAsSideMenu="false"
        contentSuperContainerClassName={`bg-dark ${videoSize}`}
        contentContainerClassName=""
        onHide={() => this.setState({ showVideoModal: false })}
      >
        <Youtube
          videoId={videoId}
        />
      </PortalModal>
    );
  }

  _renderEditModal(data) {
    return (
      <PortalModal
        show={this.state.showEditModal}
        onHide={() => this.setState({ showEditModal: false })}
      >
        <h2>Video Settings</h2>

        <Border>
          <Collapsible trigger="Youtube Button Text">
            <br></br>
            <input
              type="text"
              defaultValue={data.video_button.title}
              onChange={(event) => {
                data.video_button.title = event.target.value;
                this._update(data);
              }}
            />
          </Collapsible>
        </Border>

        <Border>
          <Collapsible trigger="Youtube Id">
            <br></br>
            <input
              type="text"
              defaultValue={data.video_button.url}
              onChange={(event) => {
                let url = event.target.value;
                data.video_button.url = url;
                this._update(data);
              }}
            />
          </Collapsible>
        </Border>

        <Border>
          <Collapsible trigger="Youtube Video Size">
            <br></br>

            <select
              value={data.video_button.settings.overlay || "6"}
              onChange={(event) => {
                let overlay = event.target.value;

                data.video_button.settings.overlay = overlay;
                this._update(data);
              }}
            >
              <option value="3">Small</option>
              <option value="6">Medium</option>
              <option value="9">Large</option>
            </select>
          </Collapsible>
        </Border>

        <Border>
          <Collapsible trigger="Colours">
            <br></br>
            <ColorPicker
              title={"Button Text Color"}
              color={data.video_button.settings.primary_color}
              onColorSelected={(color) => {
                data.video_button.settings.primary_color = color;
                this._update(data);
              }}
            />
            <br />
            <ColorPicker
              title={"Button Background Color"}
              color={data.video_button.settings.secondary_color}
              onColorSelected={(color) => {
                data.video_button.settings.secondary_color = color;
                this._update(data);
              }}
            />
            <br />
            <ColorPicker
              title={"Button Border Color"}
              color={data.video_button.settings.tertiary_color}
              onColorSelected={(color) => {
                data.video_button.settings.tertiary_color = color;
                this._update(data);
              }}
            />
          </Collapsible>
        </Border>
      </PortalModal>
    );
  }

  render() {
    let { data, editable } = this.state;
    let showSubtitle = data.subtitle
    let showTitle = data.title != null

    return (
      <Section
        data={data}
        index={this.props.index}
        className={
          " block-20 border--bottom space--xxs text-center bg--secondary" +
          this.props.className
        }
        editable={editable}
        onUpdated={(data) => this._update(data)}
        onDelete={() => this.props.onDelete()}
        blockTitleColor={false}
        primaryColor={false}
        secondaryColor={false}
        onAddContentBlockPressed={(contentBlock) =>
          this.props.onAddContentBlockPressed(contentBlock)
        }
        canMoveUp={this.props.canMoveUp}
        canMoveDown={this.props.canMoveDown}
        onMoveUpPressed={() => this.props.onMoveUpPressed()}
        onMoveDownPressed={() => this.props.onMoveDownPressed()}
      >
        <div className="container section_20">
          <div className="row">
            <div className="col-md-12">
              <div className="d-md-flex justify-content-center align-items-center">
                <div className="modal-instance btn-video-play">
                  <a
                    className="btn type--uppercase"
                    onClick={() => this.setState({ showVideoModal: true })}
                    style={{
                      borderColor: data.video_button.settings.tertiary_color,
                      backgroundColor:
                        data.video_button.settings.secondary_color,
                    }}
                  >
                    <span
                      className="btn__text"
                      style={{
                        color: data.video_button.settings.primary_color,
                      }}
                    >
                      ▶ {showTitle && data.video_button.title}
                    </span>
                  </a>
                  {editable && (
                    <a onClick={() => this.setState({ showEditModal: true })}>
                      <div className="edit edit-button">
                        <i className="icon icon--xs icon-Pencil" />
                      </div>
                    </a>
                  )}
                </div>

                {showSubtitle &&
                  <Span
                    text={data.subtitle}
                    color={data.subtitle_color}
                    editable={editable}
                    className="editable-span block--xs col-auto col-md-3 text-center text-md-left"
                    textAlign="left"
                    onUpdated={(text) => {
                      data.subtitle = text;
                      this._update(data);
                    }}
                  />
                }

              </div>
            </div>
          </div>
        </div>
        {this._renderVideoModal(data)}
        {this._renderEditModal(data)}
      </Section>
    );
  }
}
