import React from 'react'

import Collapsible from 'react-collapsible';
import Border from '../Border'

import Section from './editable/Section'
import { getImageUrl, Sizes } from './editable/Img'

import ImageInputItem from '../sectioninputs/ImageInputItem'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

export default class Block23 extends React.Component {
  constructor(props){
    super(props)

    // key is incremented as the slider js functions
    // overwrite the html so we want to refresh it
    // as React is out of sync when
    // adding/editing/deleting the images
    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    this.setState({ ...data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _renderModalContent(data){
    return (
      <Border>
        <Collapsible
          trigger="Images"
        >
          <br/>
          { this._renderModalContentImages(data) }
        </Collapsible>
      </Border>
    )
  }

  _renderModalContentImages(data){
    let images = data.images
    return images.map((image, index) => {
      return (
        <ImageInputItem
          key={image.id}
          index={index}
          image={image}
          onUpdated={uploadedImage => {
            data.images[index] = uploadedImage
            this._update({
              data,
              key: this.state.key + 1
            }, () => General.updateSlides())
          }}
          onDelete={() => {
            if(data.images.length == 2){
              alert("There must be at least 2 images")
              return
            }
            images.splice(index, 1)
            data.images = images
            this._update({
              data,
              key: this.state.key + 1
            }, () => General.updateSlides())
          }}
        />
      )
    })
  }


  _renderImages(data, editable){
    let images = data.images
    if(!images){
      return null
    }

    return images.map((image, index) => {
      return (
        <li
          className="lazy-background"
          key={ image.id } 
          style ={{ backgroundImage: "url("+getImageUrl(image, 23)+")" }}
          priority={this.props.index}
        ></li>
      )
    })
  }

  render(){
    let { data, key, editable } = this.state
    return (
      <Section
          ref={ this.section }
          data={ data }
          index={ this.props.index }
          className={ ' block-23 ' + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() =>  this._renderModalContent(data)}
          primaryColor={false}
          secondaryColor={false}
          imageTitle={"Add Image"}
          onImageUpdated={image => {
            data.images.push(image)
            this._update({
              data,
              key: this.state.key + 1
            }, () => General.updateSlides())
          }}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
          >
          <div className="container">

            <Titles 
              data={ data }
              editable={ editable }
              onUpdated={data => this._update(data)}
              headingNumber={2}
            />

              <div className="row">
                  <div className="col">
                      <div key ={ key } className="slider" data-arrows="true" data-paging="true">
                          <ul className="slides">
                              { this._renderImages(data, editable) }
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </Section>
    )
  }
}
