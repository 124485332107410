import React from "react";

import NativeListener from "react-native-listener";

import IconPickerModal from "./modal/IconPickerModal";

import ButtonSettingsModal from "./modal/settings/ButtonSettingsModal";

import PortalModal from "./modal/PortalModal";

import General from "../utils/General";
import Color from "../utils/Color";

import Youtube from './Youtube'

import $ from "jquery";

export default class Button extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      type: props.type,
      style: props.style,
      editable: props.editable,
      showModal: false,
      showVideoModal: false,
      uuid: General.uuid(),
    };

    this.buttonSettingsModal = React.createRef();
  }

  componentDidMount() {
    let { uuid } = this.state;
    $("#" + uuid).on("click", function () {
      return false;
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderVideoModal(data) {
    let overlay = data.settings.overlay

    let videoId = General.getYoutubeVideoId(data.url);

    let videoSize = "yt-video-md";

    if (overlay == 3) {
      videoSize = "yt-video-sm";
    } else if (overlay == 9) {
      videoSize = "yt-video-lg";
    }

    return (
      <PortalModal
        show={this.state.showVideoModal}
        showAsSideMenu="false"
        contentSuperContainerClassName={`bg-dark ${videoSize}`}
        contentContainerClassName=""
        onHide={() => this.setState({ showVideoModal: false })}
      >
        <Youtube
          videoId={videoId}
        />
      </PortalModal>
    );
  }


  _renderVideoButton(data, editable) {
    return (
      <div className="modal-instance block">
        <NativeListener onClick={this._handleVideoClick.bind(this)}>
          <a className="btn btn-video">
            <div
              className="video-play-icon video-play-icon--xs modal-trigger bg--primary"
              style={{
                background: data.settings.secondary_color,
              }}
            ></div>
            <span
              className="btn__text"
              style={{ color: data.settings.primary_color }}
            >
              {data.title}
            </span>
            {this._renderEditable(data, editable, "video")}
          </a>
        </NativeListener>
        {this._renderVideoModal(data)}
      </div>
    );
  }

  _updateColorsForMSC(settings, type) {
    if (!settings.msc_enabled) {
      return settings;
    }

    if (type === "primary") {
      settings.secondary_color = window.CURRENT_PAGE_PRIMARY_COLOR;
      settings.tertiary_color = window.CURRENT_PAGE_PRIMARY_COLOR;
    }
    if (type === "secondary" || type === "text_underline") {
      // disable msc for secondary buttons
      // settings.primary_color = window.CURRENT_PAGE_PRIMARY_COLOR
    }
    if (type === "link") {
      // disable msc for secondary buttons
      settings.primary_color = window.CURRENT_PAGE_PRIMARY_COLOR;
    }
    if (type === "video") {
      // settings.primary_color = window.CURRENT_PAGE_PRIMARY_COLOR
      settings.secondary_color = window.CURRENT_PAGE_PRIMARY_COLOR;
      settings.tertiary_color = window.CURRENT_PAGE_PRIMARY_COLOR;
    }
    return settings;
  }

  _updateBadgeColorsForMSC(settings, type) {
    if (!settings.msc_enabled) {
      return settings;
    }

    settings.secondary_color = window.CURRENT_PAGE_PRIMARY_COLOR;
    settings.tertiary_color = window.CURRENT_PAGE_PRIMARY_COLOR;

    return settings;
  }

  _getStyle(settings) {
    let style = {};

    if (settings.primary_color) {
      style.color = settings.primary_color;
    }
    if (settings.secondary_color) {
      style.backgroundColor = settings.secondary_color;
    }
    if (settings.tertiary_color) {
      style.borderColor = settings.tertiary_color;
    }

    return style;
  }

  _getBadgeStyle(settings) {
    settings = settings || {};

    if (settings.msc_enabled) {
      settings.secondary_color = window.CURRENT_PAGE_PRIMARY_COLOR_DARKENED;
      settings.tertiary_color = window.CURRENT_PAGE_PRIMARY_COLOR_DARKENED;
    }

    let style = {
      color: settings.primary_color,
      borderColor: settings.secondary_color,
      backgroundColor: settings.tertiary_color,
    };

    return style;
  }

  _handleClick(event) {
    let { data } = this.state;

    let className = event.target.className;
    if (className.indexOf("btn") > -1) {
      this._click(data.url, data.open_in_new_tab);
      return;
    }

    this.setState({ showModal: true });
  }

  _handleVideoClick(event) {
    let { data } = this.state;

    let className = event.target.className;
    if (className.indexOf("btn") > -1) {
      this.setState({ showVideoModal: true });
      return;
    }

    this.setState({ showModal: true });
  }

  _click(url, openInNewTab = false) {
    if (!url) {
      return;
    }

    let hash = url;

    if (!url?.startsWith("#") || $(hash).length === 0) {
      openInNewTab ? window.open(url) : (window.location.href = url);
      return;
    }

    $("html, body").animate(
      {
        scrollTop: $(hash).offset().top,
      },
      800,
      function () {
        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      }
    );
  }

  _renderBadge(data) {
    let { badge } = data;
    if (!badge) {
      return null;
    }

    let badgeSettings = {};
    if (data.badge_settings) {
      data.badge_settings = this._updateBadgeColorsForMSC(data.badge_settings);
      badgeSettings = data.badge_settings;
    }

    let className = badgeSettings.msc_enabled ? "label-msc-enabled" : "";
    let badgeStyle = this._getBadgeStyle(badgeSettings);
    return (
      <span className={`label ${className}`} style={badgeStyle}>
        {badge}
      </span>
    );
  }

  _renderIcon(data) {
    let { type } = this.state;

    if (!data.icon || (type !== "primary" && type !== "secondary")) {
      return null;
    }

    return <i className={data.icon}></i>;
  }

  _renderEditable(data, editable, type) {
    if (!editable) {
      return;
    }

    return (
      <ButtonSettingsModal
        ref={this.buttonSettingsModal}
        data={data}
        type={type}
        hideIcon={this.props.hideIcon}
        showYoutubeURL={this.props.showYoutubeURL}
        showModal={this.state.showModal}
        backgroundColor={
          type === "primary" || type === "secondary" || type === "video"
        }
        borderColor={type === "primary" || type === "secondary"}
        backgroundColorTitle={type === "video" ? "Icon Colour" : null}
        secondaryColorsTitle={this.props.secondaryColorsTitle}
        secondaryColorsSubtitle={this.props.secondaryColorsSubtitle}
        onUpdated={(data) => this.props.onUpdated(data)}
        renderModal={(content) => this.props.renderModal(content)}
        onHide={() => this.setState({ showModal: false })}

      />
    );
  }

  render() {
    let { data, type, textAlign, editable, uuid, style } = this.state;

    if (!data) {
      return null;
    }

    data.settings = this._updateColorsForMSC(data.settings, type);

    let classNames = "btn btn--primary type--uppercase";
    let spanClassNames = "btn__text";

    if (type === "secondary") {
      classNames = "btn btn--secondary type--uppercase";
    } else if (type === "video") {
      return this._renderVideoButton(data, editable);
    } else if (type === "text") {
      classNames = "btn type--uppercase";
      data.settings.tertiary_color = "transparent";
      data.settings.secondary_color = "transparent";
    } else if (type === "link") {
      classNames = "link-text";
    }

    if (this.props.size === "small") {
      classNames += " btn--sm";
    }

    if (data.icon && (type == "primary" || type == "secondary")) {
      classNames += " btn--icon";
    }

    style = {
      ...this._getStyle(data.settings, type),
      ...style,
    };

    if (type === "text_underline") {
      classNames = "link-text";
      style.textDecoration = "underline";
    }

    return (
      <NativeListener onClick={this._handleClick.bind(this)}>
        <a
          href="javascript:void(0);"
          id={uuid}
          className={classNames}
          style={style}
        >
          <span className="btn__text" style={{ color: style.color }}>
            {this._renderIcon(data)}
            {data.title}
          </span>
          {this._renderBadge(data)}
          {this._renderEditable(data, editable, type)}
        </a>
      </NativeListener>
    );
  }
}

Button.defaultProps = {
  type: "primary",
  style: {},
  showYoutubeURL: false,
};
