import React from 'react'

import H2 from './editable/H2'
import P from './editable/P'
import Img, { Sizes } from './editable/Img'
import Section from './editable/Section'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

export default class Block44 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable,
    }

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  render(){
    let {
      data,
      editable,
    } = this.state

    let showTitle = data.title

    return (
      <Section
          data={ data }
          index={ this.props.index }
          className={ "block-44 imagebg feature-large-7 switchable "+this.props.className }
          editable={ editable }
          image={data.background_image}
          imageTitle={"Background Image"}
          onImageUpdated={image => {
            data.background_image = image
            this._update(data, () => General.updateImageBackgrounds())
          }}
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          primaryColor={false}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
        <div class="background-image-holder lazy-background">
          { data.background_image &&
            <Img
              key={ data.background_image.id }
              img={ data.background_image }
              block={44}
              priority={this.props.index}
              alt="background"
            />
          }
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-7 col-lg-5">
                    <div class="boxed boxed--lg border--round bg--white row justify-content-center mb-0"
                      style={{
                        backgroundColor: data.settings.tertiary_color
                      }}
                    >
                        <div class="col-lg-10 col-md-12">
                          {showTitle &&
                            <H2
                              text={ data.title}
                              color={ data.title_color }
                              editable={ editable }
                              textAlign="left"
                              onUpdated={text => {
                                data.title = text
                                this._update(data)
                              }}
                            />
                          }
                            <P
                              text={ data.body }
                              color={ data.settings.secondary_color }
                              textAlign="left"
                              editable={ editable }
                              onUpdated={text => {
                                data.body = text
                                this._update(data)
                              }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </Section>
    )
  }
}
