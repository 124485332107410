import React from 'react'

import Row from './Row'

import General from '../../utils/General'
import ReCAPTCHA from "react-google-recaptcha";
import Backend from "../../utils/Backend";
import Notify from "../../utils/Notify";
import Email from "../../utils/Email";
import SuccessModal from "../modal/SuccessModal";

import moment from "moment";

window.recaptchaOptions = {
  useRecaptchaNet: true,
};

export default class Form extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      form: props.form,
      answers: []
    }

    this.recaptcha = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _setDefaultValues(formField){
    let {
      answers
    } = this.state

    if(formField.type === "boolean" && !formField.is_required){
      if(!answers.some(answer => answer.field === formField.id)){
        answers.push({field: formField.id, bool: false})
      }
    }

    this.setState({answers})
  }

  _handleSubmit(){
    let {
      form,
      answers
    } = this.state

    let error = null

    this.setState({loading: true})

    form.form_fields.map(formRow => {
      formRow.map(formField => {

        this._setDefaultValues(formField)

        if(formField.is_required){
          if(!answers.some(answer => answer.field === formField.id)){
            formField.error = true
            error = true
          }
        }

        if(formField.type === "email" || formField.type === "contact_email"){
          if(!formField.error){
            let answerIndex = answers.map(answer => {return answer.field}).indexOf(formField.id)
            if(!Email.isValid(answers[answerIndex].value)){
              formField.error = true
              error = true
            }
          }
        }

        if(formField.type === "phone" || formField.type === "contact_phone"){
          if(!formField.error){
            let answerIndex = answers.map(answer => {return answer.field}).indexOf(formField.id)
            if(answers[answerIndex].value.length < 6){
              formField.error = true
              error = true
            }
          }
        }

      })
    })

    this.setState({form})

    if(!error){
      let formData = {
        form: form.id,
        answers
      }
      this._submitForm(formData)
    }else{
      this.setState({loading: false})
    }
  }

  _submitForm(formData){
    this.recaptcha.current.executeAsync().then(token => {
      formData.recaptcha = token
      return Backend.submitForm(formData)
    }).then(() => {
      this._clearForm()
      this.recaptcha.current.reset()
      this.setState({showSubmissionSuccessModal: true, loading: false})
    }).catch(e => {
      this.recaptcha.current.reset()
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  _clearForm(){
    let {
      form,
      answers
    } = this.state

    answers = []
    form.form_fields.map(formRow => {
      formRow.map(formField => {
        delete formField.answer
      })
    })

    this.setState({form, answers})
  }

  _renderTerms(termsText, tertiaryColor){
    if(!termsText){
      return null
    }

    return (
      <div className="col-md-12 text-center">
        <div className="modal-instance">
          <div className="input-checkbox">
            <input id="check-test" type="checkbox" name="agree" className="validate-required"/>
            <label htmlFor="check-test"></label>
          </div>
          <span>{ "I have read and agree to the "}
            <a
              href={termsText}
              target="_blank"
              className="modal-trigger"
            >
              terms and conditions
            </a>
          </span>
        </div>
      </div>
    )
  }

  _renderRows(rows){
    let {
      form,
      answers,
    } = this.state

    // keep track of the startt index for
    var fieldStartIndex = 0
    return rows.map((fields, rowIndex) => {
      if(rowIndex > 0){
        fieldStartIndex += rows[rowIndex - 1].length
      }

      return (
        <>
          <Row
            key={ fields.map(field => field.id).join('_') }
            data={ fields }
            rowIndex={rowIndex}
            fieldStartIndex={ fieldStartIndex }
            onUpdated={(index, value) => {

              if(!answers.some(answer => answer.field === value.field)){
                answers.push(value)
              }else{
                let answerIndex = answers.map(answer => {return answer.field}).indexOf(value.field)
                answers[answerIndex] = value
              }

              form.form_fields[rowIndex].map(field => {
                if(field.id === value.field){
                  field.answer = value
                }
              })

              this.setState({answers, form})
            }}
          />
        </>
      )
    })
  }

  _renderSuccessModal(){
    let {
      form,
      showSubmissionSuccessModal
    } = this.state

    return (
      <SuccessModal
        show={showSubmissionSuccessModal}
        title={form.popup_title}
        message={form.popup_body}
        onHide={() => this.setState({showSubmissionSuccessModal: false})}
      />
    )
  }

  render(){
    let {
      data,
      form,
      loading,
      showSubmissionSuccessModal
    } = this.state

    if(!form){
      return null
    }

    let key = form.form_fields.map(formField => {
      return formField.map(field => field.id || field.uuid).join("_")
    }).join("_")

    let anchorStyle = {
      color: data.settings.senary_color,
    }

    return (
      <>
        <form
          action={window.Api.Submissions}
          key={key}
          className="text-left form-email row mx-0"
          data-success="Thanks for your enquiry, we'll be in touch shortly."
          data-error="Please fill in all fields correctly."
          noValidate={true}
        >
          <div
            className="container"
          >
            { this._renderRows(form.form_fields) }
          </div>

          <ReCAPTCHA
            ref={this.recaptcha}
            sitekey={window.Api.RecaptchaSiteKey}
            size="invisible"
          />

          <div className="col-md-12">
            <div className="boxed px-0 bg-transparent">
              <button
                type="submit"
                className="btn btn--primary type--uppercase w-100"
                disabled={loading}
                style={{
                  backgroundColor: data.settings.senary_color,
                  borderColor: data.settings.senary_color
                }}
                onClick={e => {
                  e.preventDefault()
                  this._handleSubmit()
                }}
              >
                <span
                  className="btn__text"
                  style={{
                    color: data.settings.quinary_color
                  }}
                >
                  {form.submit_text}
                </span>
              </button>
            </div>
          </div>

          { this._renderTerms(form.terms_text, data.settings.tertiary_color) }
          <div className="col-md-12 text-center">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy" style={anchorStyle}> Privacy Policy</a> and
            <a href="https://policies.google.com/terms" style={anchorStyle}> Terms of Service</a> apply.
          </div>
        </form>
        {
          showSubmissionSuccessModal && this._renderSuccessModal()
        }
      </>
    )
  }

}
