
export default class Job {
  static getLocation(job) {
    let location = "-"
    if(job.location?.country){
      location = job.location.country
    }
    else if(job.address?.city){
      let city = job.address.city
      location = `${city.name}, ${city.state.name}, ${city.state.country.name}`
    }
    return location
  }
}
