import React from 'react'

import Img, { Sizes } from './editable/Img'
import Section from './editable/Section'

import Collapsible from 'react-collapsible';
import Border from '../Border'

import ImageInputItem from '../sectioninputs/ImageInputItem'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

export default class Block45 extends React.Component {
  constructor(props){
    super(props)

    // key is incremented as the slider js functions
    // overwrite the html so we want to refresh it
    // as React is out of sync when
    // adding/editing/deleting the images
    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable,
      windowWidth: window.innerWidth
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)    
  }  

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }
  
  _update(data, callback){
    this.setState({ ...data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _renderModalContent(data){
    return (
      <Border>
        <Collapsible
          trigger="Logos"
        >
          <br/>
          { this._renderModalContentImages(data) }
        </Collapsible>
      </Border>
    )
  }

  _renderModalContentImages(data){
    let images = data.images
    return images.map((image, index) => {
      return (
        <ImageInputItem
          key={image.id}
          name="Logo"
          index={index}
          image={image}
          onUpdated={uploadedImage => {
            data.images[index] = uploadedImage
            this._update({
              data,
              key: this.state.key + 1
            }, () => General.updateSlides())
          }}
          onDelete={() => {
            if(data.images.length == 2){
              alert("There must be at least 2 logos")
              return
            }
            images.splice(index, 1)
            data.images = images
            this._update({
              data,
              key: this.state.key + 1
            }, () => General.updateSlides())
          }}
        />
      )
    })
  }


  _renderImages(data, editable){
    let images = data.images
    if(!images){
      return null
    }

    return images.map((image, index) => {
      return (
        <li key={ image.id } class="col-md-3 col-6">
          <Img 
            alt="Image" 
            class="image--xl" 
            img={image}
            block={45}
            priority={this.props.index}
          />
        </li>
      )
    })
  }

  _handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
    setTimeout(() => {
      General.updateSlides() 
    }, 200)
   };

  componentDidMount() {
    window.addEventListener("resize",  this._handleResize);
   } 

   componentWillUnmount() {
    window.addEventListener("resize",  this._handleResize);
   } 

  render(){
    let { data, key, editable, windowWidth } = this.state
    return (
      <Section
          ref={ this.section }
          data={ data }
          index={ this.props.index }
          className={ ' block-44 block-45 ' + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() =>  this._renderModalContent(data)}
          primaryColor={false}
          secondaryColor={false}
          imageTitle={"Add Logo"}
          onImageUpdated={image => {
            data.images.push(image)
            this._update({
              data,
              key: this.state.key + 1
            }, () => General.updateSlides())
          }}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
          >
          <div className="container">
              <div className="row">
                  <div className="col">
                  
                    {/* tablets + desktop : min screen width should be above 767px  */}
                    { (windowWidth > 767 ) &&
                      <>
                        {/* if images are below 5, setting slider data values to false */}
                        <div 
                          key ={ key } 
                          className="slider slider--inline-arrows slider--arrows-hover text-center"
                          data-arrows={ (data.images.length>4) ? 'true' : 'false' } 
                          data-paging={ (data.images.length>4) ? 'true' : 'false' }
                          data-autoplay={ (data.images.length>4) ? 'true' : 'false' }
                          data-draggable={ (data.images.length>4) ? 'true' : 'false' }
                        >
                          <ul className="slides">
                              { this._renderImages(data, editable) }
                          </ul>
                        </div>
                      </>
                    }

                    {/* mobiles : max screen width should be below 768px  */}
                    { (windowWidth < 768 ) &&
                      <>
                        {/* if images are below 3, setting slider data values to false */}
                        <div 
                          key ={ key } 
                          className="slider slider--inline-arrows slider--arrows-hover text-center"
                          data-arrows={ (data.images.length>2) ? 'true' : 'false' } 
                          data-paging={ (data.images.length>2) ? 'true' : 'false' }
                          data-autoplay={ (data.images.length>2) ? 'true' : 'false' }
                          data-draggable={ (data.images.length>2) ? 'true' : 'false' }
                        >
                          <ul className="slides">
                              { this._renderImages(data, editable) }
                          </ul>
                        </div>
                      </>
                    }
                      
                  </div>
              </div>
          </div>
      </Section>
    )
  }
}
