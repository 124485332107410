import React from "react";

import H2 from "./editable/H2";
import P from "./editable/P";
import Section from "./editable/Section";

import Play from "../Play";

import General from "../../utils/General";
import Backend from "../../utils/Backend";

export default class Block21 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      editable: props.editable,
    };

    this.updateContentBlock = General.debounce(
      () => {
        console.log("3333", this.state.data);
        Backend.updateContentBlock(this.state.data);
      },
      1000,
      false
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _update(data, callback) {
    this.setState({ data }, () => {
      if (callback) {
        callback();
      }
      this.updateContentBlock();
    });
  }

  render() {
    let { data, editable } = this.state;
    let showtitle = data.title

    if (data.settings.msc_enabled) {
      data.settings.tertiary_color = window.CURRENT_PAGE_PRIMARY_COLOR;
    }

    return (
      <Section
        data={data}
        index={this.props.index}
        className={
          " block-21 video video-1 text-center bg--primary " +
          this.props.className
        }
        editable={editable}
        primaryColor={false}
        secondaryColor={false}
        onUpdated={(data) => {
          data.settings.msc_enabled = false;
          this._update(data);
        }}
        onDelete={() => this.props.onDelete()}
        onAddContentBlockPressed={(contentBlock) =>
          this.props.onAddContentBlockPressed(contentBlock)
        }
        canMoveUp={this.props.canMoveUp}
        canMoveDown={this.props.canMoveDown}
        onMoveUpPressed={() => this.props.onMoveUpPressed()}
        onMoveDownPressed={() => this.props.onMoveDownPressed()}
      >
        <div className="container section_21">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                <div 
                  className={`video-settings ${showtitle?'with-title':'no-title'}`}
                >
                  <Play
                    className="video-play-icon--sm"
                    overlay={data.video_button.settings.overlay}
                    icon={data.video_button.icon}
                    iconSize={"icon--lg"}
                    iconImage={data.video_button.icon_image}
                    videoUrl={data.video_button.url}
                    editable={editable}
                    onUpdated={(videoUrl, icon, iconImage, overlay) => {
                      data.video_button.url = videoUrl;
                      data.video_button.icon = icon;
                      data.video_button.icon_image = iconImage;
                      data.video_button.settings.overlay = overlay;
                      this._update(data);
                    }}
                  />
                </div>
                {showtitle &&
                  <H2
                    text={data.title}
                    className="col-auto p-0"
                    textAlign="left"
                    color={data.title_color}
                    editable={editable}
                    onUpdated={(text) => {
                      data.title = text;
                      this._update(data);
                    }}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </Section>
    );
  }
}
