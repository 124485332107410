import React from "react";

import Border from "../../Border";

import Collapsible from "react-collapsible";

import IconPickerGrid from "../../IconPickerGrid";
import ColorPicker from "../../ColorPicker";
import LinkSelect from "../../LinkSelect";

import PortalModal from "../PortalModal";

import Toggle from "../../sectioninputs/Toggle";
import General from "../../../utils/General";

const COLORS = ["#FF6900", "#FCB900", "#7BDCB5"];

export default class Button extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      type: props.type,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _update(key, value) {
    let { data } = this.state;
    data[key] = value;

    if (key == "url" && value.startsWith("/#")) {
      data.open_in_new_tab = false;
    }

    this.setState({ data });
    this.props.onUpdated(data);
  }

  _updateSetting(key, value) {
    let { data } = this.state;
    data.settings[key] = value;

    data.settings.msc_enabled = false;

    this.setState({ data });
    this.props.onUpdated(data);
  }

  _updateBadgeSetting(key, value) {
    let { data } = this.state;
    if (data.badge_settings == null) {
      data.badge_settings = {};
    }

    data.badge_settings[key] = value;

    data.badge_settings.msc_enabled = false;

    this.setState({ data });
    this.props.onUpdated(data);
  }

  _renderModal(data) {
    const { type } = this.state;

    return (
      <PortalModal
        show={this.props.showModal}
        onHide={() => this.props.onHide()}
      >
        <h2>Button Settings</h2>

        {this.props.showYoutubeURL ? (
          <Border>
            <Collapsible trigger="Youtube Video Url">
              <br></br>
              <input
                type="text"
                defaultValue={data.url}
                placeholder="https://www.youtube.com/watch?v=DGQwd1_dpuc"
                onChange={(event) => {
                  this._update("url", event.target.value);
                }}
              />
            </Collapsible>
          </Border>
        ) : (
          <Border>
            <Collapsible trigger="Button Link">
              <br></br>
              <span>Title</span>
              <input
                type="text"
                defaultValue={data.title}
                onChange={(event) => {
                  this._update("title", event.target.value);
                }}
              />

              <br />
              <br />

              <span>Link</span>
              <LinkSelect
                url={data.url}
                onUpdated={(url) => {
                  this._update("url", url);
                }}
              />

              {!data.url?.startsWith("/#") && (
                <>
                  <span>Open In New Tab</span>
                  <select
                    value={data.open_in_new_tab}
                    onChange={(event) => {
                      data.open_in_new_tab = event.target.value === "true";

                      this._update("open_in_new_tab", data.open_in_new_tab);
                    }}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </>
              )}
            </Collapsible>
          </Border>
        )}

        {type == "video" && (
          <Border>
            <Collapsible trigger="Youtube Video Size">
              <br></br>

              <select
                value={data.settings.overlay || "6"}
                onChange={(event) => {
                  this._updateSetting("overlay", event.target.value);
                }}
              >
                <option value="3">Small</option>
                <option value="6">Medium</option>
                <option value="9">Large</option>
              </select>
            </Collapsible>
          </Border>
        )}

        {!this.props.hideIcon && (
          <Border>
            <Collapsible trigger="Button Icon">
              <br></br>

              <Toggle
                id="button_icon_toggle"
                checked={data.icon != null}
                title="Enable Icon"
                onChange={(checked) => {
                  if (checked) {
                    this._update("icon", "icon icon--sm icon-Add-File");
                  } else {
                    this._update("icon", null);
                  }
                }}
              />

              {data.icon && (
                <>
                  <div className="row no-gutters align-items-center">
                    <div className="col-auto">Default / Selected Icon : </div>
                    <div className="col-auto pl-2">
                      <i
                        class={`${data.icon} color--dark`}
                        style={{ fontSize: 30 }}
                      />
                    </div>
                  </div>

                  <div className="button-icon-picker mt-4">
                    <IconPickerGrid
                      columnWidth={110}
                      className=""
                      width={356}
                      rowHeight={110}
                      onSelected={(icon) => {
                        data.icon = "icon icon--lg icon-" + icon.name;
                        this.props.onUpdated(data);
                        this.setState({ showIconPickerModal: false });
                      }}
                    />
                  </div>
                </>
              )}
            </Collapsible>
          </Border>
        )}

        <Border>
          <Collapsible trigger="Button Colours">
            <br></br>

            <ColorPicker
              title="Title Colour"
              color={data.settings.primary_color}
              onColorSelected={(color) => {
                this._updateSetting("primary_color", color);
              }}
            />

            <br />
            {this._renderBackgroundColor(data)}
            {this._renderBorderColor(data)}
            {this._renderSecondaryColorSettings(data)}
          </Collapsible>
        </Border>

        {this._renderBadgeSettings(data)}
      </PortalModal>
    );
  }

  _renderBackgroundColor(data) {
    if (this.props.backgroundColor === false) {
      return null;
    }

    return (
      <>
        <ColorPicker
          title={this.props.backgroundColorTitle || "Background Colour"}
          color={data.settings.secondary_color}
          onColorSelected={(color) => {
            this._updateSetting("secondary_color", color);
          }}
        />

        <br />
      </>
    );
  }

  _renderBorderColor(data) {
    if (this.props.borderColor === false) {
      return null;
    }

    return (
      <ColorPicker
        title="Border Colour"
        color={data.settings.tertiary_color}
        onColorSelected={(color) => {
          this._updateSetting("tertiary_color", color);
        }}
      />
    );
  }

  _renderSecondaryColorSettings(data) {
    if (!this.props.secondaryColorsTitle) {
      return null;
    }

    let titleClassName = this.props.secondaryColorsSubtitle ? "mb-0" : "";

    return (
      <>
        {this.props.secondaryColorsSubtitle && (
          <label className="mt-3 mb-3">
            {this.props.secondaryColorsSubtitle}
          </label>
        )}
        {this._renderColor(
          "quaternary_color",
          "quaternaryColorTitle",
          "Title Colour (On Sticky Nav Bar)",
          data
        )}
        {this._renderColor(
          "quinary_color",
          "quinaryColorTitle",
          "Background Color (On Sticky Nav Bar)",
          data
        )}
        {this._renderColor(
          "senary_color",
          "senaryColorTitle",
          "Border Color (On Sticky Nav Bar)",
          data
        )}
      </>
    );
  }

  _renderColor(colorKey, titleKey, defaultTitle, data) {
    if (this.props[titleKey] === false) {
      return null;
    }

    return (
      <>
        <ColorPicker
          title={this.props[titleKey] || defaultTitle}
          color={data.settings[colorKey]}
          onColorSelected={(color) => {
            this._updateSetting(colorKey, color);
          }}
        />

        <br />
      </>
    );
  }

  _renderBadgeSettings(data) {
    if (this.props.badge === false) {
      return null;
    }

    return (
      <Border>
        <Collapsible trigger="Button Badge Option">
          <br></br>

          <Toggle
            id="button_badge_toggle"
            checked={data.badge != null}
            title="Enable Badge"
            onChange={(checked) => {
              if (checked) {
                this._update("badge", "Badge");
              } else {
                this._update("badge", null);
              }
            }}
          />

          {data.badge && (
            <>
              <span>Title</span>
              <input
                type="text"
                defaultValue={data.badge}
                onChange={(event) => {
                  if (event.target.value) {
                    this._update("badge", event.target.value);
                  } else {
                    this._update("badge", null);
                  }
                }}
              />

              <br />
              <br />

              <ColorPicker
                title="Title Colour"
                color={
                  data.badge_settings ? data.badge_settings.primary_color : null
                }
                onColorSelected={(color) => {
                  this._updateBadgeSetting("primary_color", color);
                }}
              />

              <br />

              <ColorPicker
                title="Background Colour"
                color={
                  data.badge_settings
                    ? data.badge_settings.tertiary_color
                    : null
                }
                onColorSelected={(color) => {
                  this._updateBadgeSetting("tertiary_color", color);
                }}
              />
            </>
          )}
        </Collapsible>
      </Border>
    );
  }

  render() {
    let { data} = this.state;

    return (
      <>
        <a onClick={() => this.setState({ showModal: true })}>
          <div className="edit edit-button">
            <i className="icon icon--xs icon-Pencil edit-button-i" />
          </div>
        </a>

        {this._renderModal(data)}
      </>
    );
  }
}
