import React from "react";

export default class Search extends React.Component {
    constructor(props){
      super(props)

      const search = window.location.search;
      const params = new URLSearchParams(search);
      const searchTerm = params.get('searchTerm')
      this.state = {
        searchTerm,
        variation: props.variation
      }
    }
    render() {
        let {
            searchTerm,
            variation
        } = this.state

        let cssClasses = "components search ";
        let buttonCssClasses = "btn c-btn col-4 ";
        cssClasses += 'style-'+window.CURRENT_WEBSITE_BUTTON_STYLE

        if(variation == "overlap"){
          cssClasses += ' overlap'
        }

        if(variation == "full-with-sticky"){
          cssClasses += ' full-with-sticky'
          buttonCssClasses += ' col-md-2 c-btn-secondary'
        } else {
          buttonCssClasses += ' col-md-3'
        }

        let placeholder = window.Branding.Terminology.search_jobs_placeholder

        return (
            <div className={cssClasses}>
              <div className={variation == "full-with-sticky" ? "container":""}>
                <div className="row">

                    {/* Starts : Keyword Field */}
                    <div className="col field">
                        <div className="form-group">
                            <div className="input-group">
                                <input
                                    type="text"
                                    class="form-control"
                                    name="search"
                                    placeholder={placeholder}
                                    defaultValue={searchTerm}
                                    onChange={e => {
                                      this.setState({
                                        searchTerm: e.target.value || null
                                      })
                                    }}
                                />
                                <span class="material-icons-sharp c-icon">
                                    search
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* End : Keyword Field */}

                    {/* Starts : button */}
                    <button
                      className={buttonCssClasses}
                      onClick={() => {
                        window.location.href = `/jobs?searchTerm=${searchTerm || ""}`
                      }}
                    >
                       {window.Branding.Terminology.search_jobs}
                    </button>
                    {/* End : button */}

                </div>
              </div>
            </div>
        );
    }

}
