import FetchHelper from './FetchHelper'

import moment from 'moment'
import Notify from "./Notify";
import Currency from "./Currency";

let cart = null

const DEFAULT_CART = {
  items: [],
  shop: null,
  subtotal: 0,
  shipping_price: 0,
}

export default class Cart {
  static init(shop) {
    if (Cart.cart == null) {
      let cart = localStorage.getItem('cart')
      cart = cart ? JSON.parse(cart) : { ...DEFAULT_CART }
      Cart.cart = cart
    }

    Cart.cart.items = Cart.cart.items.filter(item => {
      if(item.release_at){
        return moment(item.release_at).isAfter()
      }
      return true
    })

    Cart.cart.shop = JSON.parse(JSON.stringify(shop))

    Cart.cart.subtotal = Cart._calculateSubtotal()
    Cart.cart.shipping_price = Cart._calculateShippingPrice()
    Cart.save()
    return Cart.cart
  }

  static get() {
    if (Cart.cart == null) {
      let cart = localStorage.getItem('cart')
      cart = cart ? JSON.parse(cart) : { ...DEFAULT_CART }
      Cart.cart = cart
    }
    return Cart.cart
  }

  static async add(product, variant, quantity){
    let cart = Cart.get();
    let item = cart.items.find(eItem => Cart.isMatch(product, variant, eItem))

    let stock = product.stock
    let totalQuantity = item ? item.quantity + quantity : quantity
    if(variant){
      stock = variant.stock
    }

    if(totalQuantity > stock && Cart.cart.shop.stock_enabled){
      Notify.error("Sorry, no more stock.")
      return Cart.cart
    }

    if (item) {
      item.quantity += quantity;
      item.product = product

    } else {

      item = {
        product,
        quantity,
        variant
      }
      cart.items.push(item)
    }

    return Cart._reserveCartItem(item)
    .then(() => {
      cart.subtotal = Cart._calculateSubtotal()
      cart.shipping_price = Cart._calculateShippingPrice()

      Cart.save()
      return Cart.cart
    })
    .catch(error => {
      item.quantity -= quantity
      throw error
    })
  }

  static async set(product, variant, quantity){

    let cart = Cart.get();
    let item = cart.items.find(eItem => Cart.isMatch(product, variant, eItem))

    if (!item) {
      return
    }
    let oldQuantity = item.quantity
    item.quantity = quantity
    let index = cart.items.findIndex(item => Cart.isMatch(product, variant, item))
    if(quantity == 0){
      try{
        let response = await Cart._unreserveCartItem(item)
      }
      catch(error){
        item.quantity = oldQuantity
        throw error
      }
      cart.items.splice(index, 1)
    }
    else{
      try{
        let response = await Cart._reserveCartItem(item)
      }
      catch(error){
        item.quantity = oldQuantity
        throw error
      }
    }

    cart.subtotal = Cart._calculateSubtotal()
    cart.shipping_price = Cart._calculateShippingPrice()

    Cart.save()
    return Cart.cart
  }

  static async remove(product, variant, quantity) {

    let cart = Cart.get();

    let index = cart.items.findIndex(item => Cart.isMatch(product, variant, item))

    if(index === -1){
      return
    }

    let item = cart.items[index]

    item.quantity -= quantity
    if(item.quantity == 0){
      try{
        let response = await Cart._unreserveCartItem(item)
      }
      catch(error){
        item.quantity += quantity
        throw error
      }
      cart.items.splice(index, 1)
    }

    cart.subtotal = Cart._calculateSubtotal()
    cart.shipping_price = Cart._calculateShippingPrice()

    Cart.save()
    return Cart.cart
  }

  static save() {
    localStorage.setItem('cart', JSON.stringify(Cart.cart))
  }

  static reset() {
    Cart.cart = { ...DEFAULT_CART }
    Cart.save()
    return Cart.cart
  }

  static _calculateSubtotal(){
    let price = 0

    Cart.cart.items.forEach((item, i) => {
      if(item.product.on_sale){
        price += item.variant ? item.variant.price * item.quantity : item.product.sale_price * item.quantity
      }
      else{
        price += item.variant ? item.variant.price * item.quantity : item.product.price * item.quantity
      }
    });

    return price
  }

  static _calculateShippingPrice(){
    let shippingPrice = 0

    Cart.cart.items.forEach((item, i) => {
      shippingPrice += item.product.shipping_price * item.quantity
    });

    if(Cart.cart.shop.shipping_fees_type === "flat"){
      shippingPrice = Cart.cart.shop.flat_shipping_fee || 0
    }

    return shippingPrice
  }

  static getTotalItemsNo(cart){
    return cart.items.reduce((totalItemsNo, item) => {
      return totalItemsNo + item.quantity
    }, 0)
  }

  static isMatch(product, variant, item){
    let productMatch = item.product.id == product.id
    if(item.variant == null && variant == null){
      return productMatch
    }
    else if(item.variant != null && variant != null){
      return productMatch && item.variant.id == variant.id
    }
    return false
  }

  static async _reserveCartItem(item){

    if(!Cart.cart.shop.stock_enabled || !Cart.cart.shop.reservation_delta){
      return item
    }

    let response = null
    if(item.cart_item_uuid){
      try {
        response = await FetchHelper.patch(`${window.Api.CartItems}/${item.cart_item_uuid}`, {
          quantity: item.quantity,
        }, true, false)
      }
      catch(error) {
        throw error
      }
    }
    else{
      let data = {
        quantity: item.quantity,
        product: item.product.id
      }

      if(item.variant){
        data["product_variant"] = item.variant.id
      }

      try {
        response = await FetchHelper.post(`${window.Api.CartItems}`, data, false, false)
      }
      catch(error){
        throw error
      }
    }

    item.cart_item_uuid = response.uuid
    item.release_at = response.release_at
  }

  static async _unreserveCartItem(item){

    let response = null
    if(!item.cart_item_uuid){
      return
    }

    try {
      response = await FetchHelper.delete(`${window.Api.CartItems}/${item.cart_item_uuid}`, {}, false)
    }
    catch(error) {
      throw error
    }

    item.cart_item_uuid = null
    item.release_at = null
  }

  static outOfStock(product, selectedOptions){
    let shop = Cart.cart?.shop
    if(shop && !shop.stock_enabled){
      return false
    }

    let cloneSelectedOptions = selectedOptions ? Array.from(selectedOptions) : []
    let cloneProduct = JSON.parse(JSON.stringify(product))

    if(cloneSelectedOptions.length < 1 || cloneProduct.options.length < 1){
      return cloneProduct.stock <= 0
    }

    let variant = cloneProduct.variants.find(productVariant => {
      return productVariant.options.sort().join(',') === cloneSelectedOptions.sort().join(',')
    })

    if(variant.options.length < cloneProduct.options.length){
      return false
    }

    return variant.stock <= 0
  }

  static showAddToCart(product, selectedOptions){
    let optionsNo = product.options.length
    let variantsNo = product.variants.length

    let showAddToCart = true

    if (variantsNo > 0){
      showAddToCart = false
      let selectedOptionsNo = selectedOptions.reduce((total, option) => {
        return option != null ? total + 1 : total;
      }, 0);
      showAddToCart = selectedOptionsNo === optionsNo
    }

    return showAddToCart
  }

  static getVariant(product, selectedOptions){
    let cloneSelectedOptions = Array.from(selectedOptions)
    let cloneProduct = JSON.parse(JSON.stringify(product))

    if(cloneProduct.variants.length < 1){
      return null
    }

    return cloneProduct.variants.find(productVariant => {
      return productVariant.options.sort().join(',') === cloneSelectedOptions.sort().join(',')
    })
  }

  static applyCoupon(subtotal, coupon, currency){
    let value = 0
    let couponText = ''
    if(coupon.type === "percentage"){
      let amountOff = ((subtotal * coupon.amount) / 100)
      value = amountOff
      couponText = `- ${Currency.format(amountOff, currency)}`
    }else if(coupon.type === "fixed_amount"){
      if(subtotal > 0){
        value = coupon.amount
        couponText = `- ${Currency.format(coupon.amount, currency)}`
      }
    }
    return {value, couponText}
  }

  static isCouponValid(subtotal, coupon){
    if(coupon.min_threshold){
      if(coupon.min_threshold > subtotal){
        return false
      }
    }
    if(coupon.max_threshold){
      if(coupon.max_threshold < subtotal){
        return false
      }
    }
    return true
  }
}
