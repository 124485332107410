import React from "react";
import "../assets/css/metronic/pages/login/login-1.css";

import { Helmet } from "react-helmet";

import Image from "../components/blocks/editable/Image";

import Img, { getImageUrl, Sizes } from "../components/blocks/editable/Img";

import Div from "../components/layouts/Div";

import ImagePickerModal from "../components/modal/ImagePickerModal";

import ColorPicker from "./ColorPicker";

import General from "../utils/General";
import Backend from "../utils/Backend";
import ScriptCache from "../utils/ScriptCache";
import Notify from "../utils/Notify";
import Website from "../utils/Website";
import AuthManager from "../utils/AuthManager";

const LOGO = require("../assets/img/signup/logo.png");
const LOGIN_VISUAL = require("../assets/img/signup/login-visual-1.svg");

export default class Package extends React.Component {
  constructor(props) {
    super(props);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const source = params.get("s") || "organic";
    const premium = params.get("p") != null;
    const u = params.get("u") || "s";

    this.state = {
      loading: true,

      premium,
      source,
      color: "#3d85c6",

      pkg: props.pkg,
      website: props.website,
    };
  }

  componentDidMount() {
    this._setup();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _setup() {
    let { pkg } = this.state;

    this.setState(
      {
        pkg,
        loading: false,
      },
      () => this._loadJs()
    );
  }

  _loadJs() {
    ScriptCache.loadDefaults();
    setTimeout(() => {
      General.updateAll();
    }, 1000);
  }

  _renderMeta() {
    let { pkg, website } = this.state;

    if (pkg == null) {
      return null;
    }

    let favIconUrl = website.favicon
      ? website.favicon.original
      : "/amply_favicon_32x32px.png";

    let title = `${website.name} | ${pkg.name}`;
    return (
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={pkg.cta || "Make Any Type Of Site In 3 Minutes"}
        />
        <link rel="icon" href={favIconUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={favIconUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location.href} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:image" content={favIconUrl} />
      </Helmet>
    );
  }

  _setSlug = General.debounce(() => {
    let { slug, company_name } = this.state;

    if (slug) {
      return;
    }

    return Backend.slugify(company_name)
      .then((response) => {
        let errorSlug = false;
        if (slug && slug !== company_name) {
          errorSlug = true;
        }
        if (!slug) {
          slug = response.slug;
        }
        this.setState({ errorSlug, slug, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }, 500);

  _checkSlug() {
    let { slug } = this.state;

    this.setState({ isLoadingSlug: true });
    Backend.slugify(slug)
      .then((response) => {
        if (response.slug != slug) {
          this.setState({ errorSlug: true, isLoadingSlug: false });
        } else {
          this.setState({ errorSlug: false, isLoadingSlug: false });
        }
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoadingSlug: false });
      });
  }

  _isSignUpFormValid() {
    let {
      first_name,
      last_name,
      email,
      phone_number,
      agree,
      password,
      company_name,
      image,
      slug,
      errorSlug,
      color,
    } = this.state;

    let error = null;

    if (company_name == null || company_name.length === 0) {
      error = "Please enter a valid company name";
    } else if (errorSlug) {
      error = "This website url is not available, please try a different one";
    } else if (!slug) {
      error = "Please enter a valid website url";
    } else if (!image) {
      error = "Please select an image";
    } else if (!color) {
      error = "Please select a website theme colour";
    } else if (first_name == null || first_name.length === 0) {
      error = "Please enter a valid first name";
    } else if (last_name == null || last_name.length === 0) {
      error = "Please enter a valid last name";
    } else if (email == null || email.length === 0) {
      error = "Please enter a valid email";
    } else if (phone_number == null || phone_number.length < 6) {
      error = "Please enter a valid phone number";
    } else if (password == null || password.length === 0) {
      error = "Please enter a valid password";
    } else if (agree == null || !agree) {
      error = "You must agree to the terms and conditions";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _signUpPressed(e) {
    e.preventDefault();

    if (!this._isSignUpFormValid()) {
      return;
    }

    let {
      first_name,
      last_name,
      company_name,
      email,
      phone_number,
      password,
      premium,
      source,
      slug,
      image,
      color,
      pkg,
    } = this.state;

    this.setState({ isLoading: true });

    let data = {
      first_name,
      last_name,
      company_name,
      email,
      phone_number,
      password,
      premium,
      source,
      package: pkg.id,
      associate: pkg.associate.company.id,
      website: {
        slug,
        name: company_name,
        primary_color: color,
        banner_image_id: image.id,
        template_id: 1,
        status: "live",
      },
    };
    console.log("+++", data);
    let website = null;
    AuthManager.register(data)
      .then((response) => {
        website = response.company_member.company.websites[0];
        return Backend.createLoginHash();
      })
      .then((hash) => {
        AuthManager.removeCredentials();
        let url =
          Website.getRelativeUrl(website, website.slug) +
          "?edit=true&h=" +
          hash.value;
        window.location = url;
      })
      .catch((error) => {
        AuthManager.removeCredentials();
        this.setState({
          isLoading: false,
        });
        Notify.error(error.message);
      });
  }

  _renderSignUpStep1() {
    let {
      pkg,
      mode,
      signUpStep,
      slug,
      errorSlug,
      isLoading,
      isLoadingSlug,
      image,
      color,
      imageLoading,
    } = this.state;

    let className = "input-group-append-bg-dark ";

    if (slug) {
      className += errorSlug
        ? "input-group-append-bg-dark-error"
        : "input-group-append-bg-dark";
    }

    let subtitle = pkg.subtitle || "Create Your Site";
    let cta = pkg.submit_cta || "Create My Site";

    return (
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        noValidate="novalidate"
      >
        <Div
          disabled={isLoading}
          spinner={true}
          disableViewClassName="disable-view-absolute"
          loadingText="Creating Your Site..."
        >
          <div className="pb-13 pt-lg-0 pt- d-none d-md-block">
            <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg mb-2">
              <span style={{ color: pkg.title_color }}>{subtitle}</span>
            </h3>
          </div>

          <div className="form-group fv-plugins-icon-container mb-5">
            <label className="mb-2">
              <span className="font-size-h6 font-weight-bolder text-dark">
                01 - Company / Site Name
              </span>
            </label>
            <input
              className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
              type="text"
              placeholder="Company / Site Name"
              name="company_name"
              autocomplete="off"
              onChange={(e) => this.setState({ company_name: e.target.value })}
              onBlur={() => this._setSlug()}
              autoFocus
            />
            <div className="fv-plugins-message-container"></div>
          </div>

          {/*
          <div className="form-group fv-plugins-icon-container mb-5">
            <label className="mb-2"><span className='font-size-h6 font-weight-bolder text-dark'>02 - Your Free Amply Site Link</span> (You Can Change Later)</label>
            <div className="input-group">
              <input
                type="text"
                name="slug"
                className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6 login-slug-field text-right"
                value={ slug }
                placeholder="mywebsite"
                onChange={e => this.setState({ slug: e.target.value })}
                onBlur={e => {
                  this._checkSlug()
                }}
              />
              <div class={`input-group-append ${className}`}>
                <span class="input-group-text font-size-h6">.amply.site</span>
              </div>
            </div>
          </div>
          */}

          <div class="form-group fv-plugins-icon-container mb-5">
            <label className="mb-2">
              <span className="font-size-h6 font-weight-bolder text-dark">
                02 - Pick A Random Image
              </span>{" "}
              (You Can Change Later)
            </label>
            <div
              className="form-control form-control-solid text-center p-0 d-flex justify-content-center align-items-center cursor-pointer choose-image"
              style={{ height: 300, overflow: "hidden" }}
              onClick={() => this.setState({ showImagePicker: true })}
            >
              {!image && (
                <>
                  <div className="choose-image-overlay" />
                  <div className="bg-text">
                    <p className="my-auto">Select Random Image</p>
                  </div>
                </>
              )}
              {(image || imageLoading) && (
                <div className="w-100 shine shine-overlay">
                  {image && (
                    <>
                      <div
                        className="uploaded-image"
                        style={{
                          backgroundImage: "url(" + image.original + ")",
                        }}
                      ></div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          <ColorPicker
            title={
              <>
                <span className="font-size-h6 font-weight-bolder text-dark">
                  03 - Your Site Theme Colour{" "}
                </span>{" "}
                (You Can Change Later)
              </>
            }
            color={color}
            onColorSelected={(color) => this.setState({ color })}
          />

          <div className="row mb-3">
            <div className="col-lg-6">
              <div class="form-group fv-plugins-icon-container">
                <label className="mb-2">First Name</label>
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  autocomplete="off"
                  onChange={(e) =>
                    this.setState({ first_name: e.target.value })
                  }
                />
                <div class="fv-plugins-message-container"></div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="form-group fv-plugins-icon-container">
                <label className="mb-2">Last Name</label>
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  autocomplete="off"
                  onChange={(e) => this.setState({ last_name: e.target.value })}
                />
                <div class="fv-plugins-message-container"></div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div className="col-lg-6">
              <div class="form-group fv-plugins-icon-container">
                <label className="mb-2">Email</label>
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  autocomplete="off"
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
                <div class="fv-plugins-message-container"></div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="form-group fv-plugins-icon-container">
                <label className="mb-2">Phone</label>
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Phone Number"
                  name="phone_number"
                  autocomplete="off"
                  onChange={(e) =>
                    this.setState({ phone_number: e.target.value })
                  }
                />
                <div class="fv-plugins-message-container"></div>
              </div>
            </div>
          </div>

          <div class="form-group fv-plugins-icon-container">
            <label className="mb-2">Password</label>
            <input
              class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
              type="password"
              placeholder="Password"
              name="password"
              autocomplete="off"
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            <div class="fv-plugins-message-container"></div>
          </div>

          <div class="form-group fv-plugins-icon-container">
            <label class="kt-checkbox c-checkbox mb-0">
              <input
                type="checkbox"
                name="agree"
                className="mr-1"
                onClick={(e) => this.setState({ agree: e.target.checked })}
              />
              I Agree to the
              <a
                href={pkg.associate.terms_url}
                target="_blank"
                className="ml-1"
                style={{
                  color: pkg.primary_color,
                }}
              >
                terms and conditions
              </a>
              <span
                style={{
                  borderColor: this.state.agree ? pkg.primary_color : null,
                  backgroundColor: this.state.agree ? pkg.primary_color : null,
                }}
              ></span>
            </label>

            <div class="fv-plugins-message-container"></div>
          </div>

          <div class="form-group d-flex flex-wrap pb-lg-0 pb-3 btn-m-block">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 w-100"
              onClick={(e) => this._signUpPressed(e)}
              style={{
                borderColor:  pkg.cta_background_color || pkg.primary_color,
                backgroundColor:  pkg.cta_background_color || pkg.primary_color,
              }}
              disabled={isLoading}
            >
              <span style={{ color: pkg.cta_text_color || "#ffffff" }}>
                {cta}
              </span>
            </button>
          </div>
        </Div>
      </form>
    );
  }

  render() {
    let { pkg, website, loading, showImagePicker } = this.state;

    let loginContentClassName =
      "login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 pt-38 pb-38 mx-auto  w-100";
    let loginAsideClassName = "login-aside d-flex flex-column flex-row-auto ";

    let title = pkg.title || "Make Any Type Of Site In 3 Minutes";

    let isFeaturedImagePhotoStyle =
      pkg.featured_image_type == "photo" ? true : false;
    let imageUrl = isFeaturedImagePhotoStyle
      ? pkg.featured_image?.original
      : pkg.featured_image?.original || LOGIN_VISUAL;

    if (isFeaturedImagePhotoStyle) {
      loginAsideClassName += " justify-content-center style photo";
    } else {
      loginAsideClassName += " justify-content-between style illustration";
    }

    if(pkg.overlay){
      loginAsideClassName += " dark-overlay"
    }

    return (
      <div className="pkg main-container page package">
        {this._renderMeta()}
        <div className="metr_v702 d-flex flex-column flex-root">
          <div
            className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
            id="kt_login"
          >
            {/* begin : Login Aside */}
            <div
              className={loginAsideClassName}
              style={{
                backgroundColor: pkg.secondary_color,
                backgroundImage:
                  isFeaturedImagePhotoStyle && "url(" + imageUrl + ")",
              }}
            >
              <div
                className={`details d-flex flex-column-auto flex-column pl-25 pr-25 text-center`}
              >
                {pkg.associate.company.logo && (
                  <a href="/" className="mb-10">
                    <Image
                      src={pkg.associate.company.logo.thumbnail}
                      className="max-h-100px"
                      alt=""
                    />
                  </a>
                )}
                <h3 className="font-weight-bolder text-center font-size-h4 font-size-h1-lg mb-10">
                  <span>{title}</span>
                </h3>
                <h4 class="font-weight-bold font-size-h4 mb-2">
                  {pkg.subline}
                </h4>

                <div class="mt-5 mb-2 d-flex flex-center">
                  <ul class="list-unstyled text-left mb-0 ps-0 list-benefits">
                    {pkg.benefits.map((benefit) => {
                      return (
                        <li class="mb-0 p">
                          <i class="symbol flaticon2-check-mark"></i>
                          <span>{benefit.title}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              {!isFeaturedImagePhotoStyle && (
                <div className="d-none d-md-flex p-3 aside-img-div">
                  <Image
                    src={imageUrl}
                    className="aside-img"
                    alt=""
                    type="illustration"
                    primaryColor={pkg.primary_color}
                  />
                </div>
              )}

              {pkg.primary_color && (
                <style
                  dangerouslySetInnerHTML={{
                    __html: `
                  .page.package .login-aside h3,
                  .page.package .login-aside h4,
                  .page.package .login-aside li,
                  .page.package .login-aside.style.photo h3, 
                  .page.package .login-aside.style.photo h4, 
                  .page.package .login-aside.style.photo li {
                      color: ${pkg.primary_color};
                    }
                  `,
                  }}
                />
              )}
            </div>
            {/* end : Login Aside */}

            {/* <!--begin::Content--> */}
            <div className={loginContentClassName}>
              <div className="d-flex flex-column-fluid flex-center">
                {/* <!--begin::Signup--> */}
                <div class="login-form login-signup">
                  {this._renderSignUpStep1()}
                </div>
                {/* <!--end::Signup--> */}
              </div>
            </div>
            {/* end::Content */}
          </div>
        </div>
        <ImagePickerModal
          show={showImagePicker}
          onSelected={(image) => {
            this.setState({ imageLoading: true, showImagePicker: false });
            Backend.addImage(image, false)
              .then((image) => {
                this.setState({ image, imageLoading: false });
              })
              .catch((error) => {
                Notify.error(error.message);
                this.setState({ imageLoading: false });
              });
          }}
          onHide={() => this.setState({ showImagePicker: false })}
        />
      </div>
    );
  }
}
