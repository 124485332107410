import React from 'react'

import Collapsible from 'react-collapsible';
import Dropdown from '../Dropdown';

import PortalModal from './PortalModal'

import Border from '../Border'
import ColorPicker from '../ColorPicker';
import FontSelect from '../FontSelect';
import LogoEditor from '../LogoEditor';

import Toggle from '../sectioninputs/Toggle';

import Color from '../../utils/Color'
import General from '../../utils/General'
import Backend from '../../utils/Backend'
import Notify from "../../utils/Notify";
import Fonts from "../../utils/Fonts"

let OPTIONS_WEBSITE_DESIGN_STYLES = [
  {
    value: "flat",
    label: "Normal Flat Style"
  },
  {
    value: "card",
    label: "Card Style"
  }
]

let OPTIONS_BUTTON_STYLES = [
  {
    value: "square",
    label: "Square (no rounding)"
  },
  {
    value: "standard",
    label: "Lightly Rounded Rectangle"
  },
  {
    value: "rounded",
    label: "Fully Rounded Rectangle"
  },
]

let OPTIONS_MENU_STYLES = [
  {
    value: "bar",
    label: "Standard Menu With Buttons & Logo"
  },
  {
    value: "hamburger",
    label: "Hamburger Menu"
  },
]

let OPTIONS_EDITOR_BRANDING_MODES = [
  {
    value: "company",
    label: "Yes, Match Main Site Colour"
  },
  {
    value: "dark",
    label: "No, Use Dark Overlay Styling"
  },
]

export default class WebsiteModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      page: props.page,
      menu: props.menu,
      website: props.website
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _updateMSC = General.debounce((color, website, page, menu) => {
    website.primary_color = color
    window.CURRENT_PAGE_PRIMARY_COLOR = color
    window.CURRENT_PAGE_PRIMARY_COLOR_DARKENED = Color.brightness(window.CURRENT_PAGE_PRIMARY_COLOR, -20)

    if(menu.primary_logo){
      menu.primary_logo.icon_color = color
      menu.primary_logo.text_color = color
    }

    website = this._resetMSC(website)
    page = this._resetMSC(page)
    menu = this._resetMSC(menu)

    this.props.onUpdated(website)
    this.props.onPageSoftUpdated(page)
    this.props.onMenuSoftUpdated(menu)
  }, 1000, false)

  _onFontChange(e){
    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    let file = filesArr[0]


    this.setState({ loading: true })
    Backend.uploadFont(file)
    .then(font => {
      this.setState({
        loading: false
      })
      Fonts.addUploaded(font)
      console.log("f", window.FONTS)
      this.props.onUpdated(this.state.website)
      Notify.success("Font Uploaded Successfully!")
    })
    .catch(error => {
      this.setState({
         loading: false
      })
      Notify.success(error.message)
    })
  }

  _resetMSC(obj){
    if(!obj){
      return obj
    }

    Object.keys(obj).forEach(key => {
        if (typeof obj[key] === 'object') {
            return this._resetMSC(obj[key]);
        }
        if(key === "msc_enabled"){
          obj[key] = true;
        }
    });

    return obj
  }

  _renderTextSettings(name, key){
    let { website } = this.state
    website[key] = website[key] || {}
    let settings = website[key]

    let font = FontSelect.getOption(settings.family_name)
    let weights = []
    if(font){
      weights = font.weights.map(weight => {
        return {
          label: weight.name,
          value: weight.value,
          className: `${font.className} ${font.className}-${weight.value}`
        }
      })
    }

    let selectedWeight = weights.find(weight => weight.value === settings.weight)


    return (
      <Border darkMode={false}>

        <div className="row">
          <div className="col">

            <span>{ name }</span>
            <FontSelect
              value={settings.family_name}
              onUpdated={value => {
                website[key].family_name = value
                this.setState({ website })
                this.props.onUpdated(website)
              }}
            />
            <br></br>
            <span>Font Thickness</span>
            <Dropdown
                options={weights}
                value={selectedWeight}
                onChange={option => {
                  website[key].weight = option.value
                  this.setState({ website })
                  this.props.onUpdated(website)
                }}
                placeholder="Thickness"
                className="font-select"
                placeholderClassName={font ? `${font.className} ${font.className}-${settings.weight}` : null}
            />

          </div>
        </div>

      </Border>
    )
  }

  _renderSectionGeneral(){
    let { website, page, menu } = this.state

    let editorBrandingMode = OPTIONS_EDITOR_BRANDING_MODES.find(editorBrandingMode => editorBrandingMode.value === website.editor_branding_mode )
    return (
      <Border>

        <Collapsible
          trigger="Main Site Colour"
        >
          <br/>
          <ColorPicker
            title="Add a splash of colour other than white"
            color={ website.primary_color }
            mscPicker={true}
            onColorSelected={color => this._updateMSC(color, website, page, menu)}
          />
          <br/>
          <span>Style editing buttons to match Main Site Colour?</span>
          <Dropdown
              options={OPTIONS_EDITOR_BRANDING_MODES}
              value={editorBrandingMode}
              onChange={option => {
                website.editor_branding_mode = option.value
                this.setState({ website })
                this.props.onUpdated(website)
              }}
              placeholder="Select An Option"
          />
        </Collapsible>

      </Border>
    )
  }

  _renderSectionWebsiteDesignStyle(){
    let { website } = this.state

    let selectedWebsiteDesignStyle = OPTIONS_WEBSITE_DESIGN_STYLES.find(websiteDesignStyle => websiteDesignStyle.value === website.design_style )

    return (
      <Border>

        <Collapsible
          trigger="Website Design Style"
        >
          <br/>
          <span>What design / style would you like</span>
          <Dropdown
              options={OPTIONS_WEBSITE_DESIGN_STYLES}
              value={selectedWebsiteDesignStyle}
              onChange={option => {
                website.design_style = option.value
                this.props.onUpdated(website)
                window.CURRENT_WEBSITE_DESIGN_STYLE = option.value
                if( option.value === "card"){
                  window.IS_CARD_STYLE_WEBSITE = true
                } else {
                  window.IS_CARD_STYLE_WEBSITE = false
                }
                this.setState({ website }, () => 
                  {
                    General.updateHomePagePaddingTop()
                    General.updateBlockAnimateOnScroll()
                  }
                )
              }}
              placeholder="Select An Option"
          />
          <br />
          {website.design_style === "card" && (
            <>
              <ColorPicker
                title={ "Website Background Colour" }
                color={ window.CURRENT_WEBSITE_BACKGROUND_COLOR }
                onColorSelected={color => {
                  website.background_color = color;
                  this.setState({ website })
                  this.props.onUpdated(website);
                  window.CURRENT_WEBSITE_BACKGROUND_COLOR = color;
                }}
              />  
            </>
          )}
        </Collapsible>

      </Border>
    )
  }

  _renderSectionButton(){
    let { website } = this.state

    let selectedButtonStyle = OPTIONS_BUTTON_STYLES.find(buttonStyle => buttonStyle.value === website.button_style )

    return (
      <Border>

        <Collapsible
          trigger="Button Style"
        >
          <br/>
          <span>What style of buttons would you like</span>
          <Dropdown
              options={OPTIONS_BUTTON_STYLES}
              value={selectedButtonStyle}
              onChange={option => {
                website.button_style = option.value
                this.setState({ website })
                this.props.onUpdated(website)
                window.CURRENT_WEBSITE_BUTTON_STYLE = option.value
              }}
              placeholder="Select An Option"
          />
        </Collapsible>

      </Border>
    )
  }

  _renderSectionLogo(){
    let { page, menu } = this.state

    return (
      <LogoEditor
        page={page}
        menu={menu}
        onUpdated={menu => {
          this.setState({ menu })
          this.props.onMenuUpdated(menu)
        }}
      />
    )
  }

  _renderSectionFonts(){
    return (
      <Border>
        <Collapsible
          trigger="Fonts"
        >
          <br/>
          <div className="custom-file upload-my-font">
            <a
              href="javascript:void(0);"
              class="btn btn-block btn-outline-dark"
            >
              <span class="btn__text">Upload New Font</span>
            </a>
            <input
                type="file"
                className="custom-file-input"
                accept=".ttf,.otf,.eot,.woff,.woff2"
                onChange={e => this._onFontChange(e)}
            />
          </div>
          { this._renderTextSettings("Main Banner Large Title Font", "h1_font") }
          { this._renderTextSettings("Other Smaller Headings Fonts", "h2_font") }
          { this._renderTextSettings("Menu Font", "menu_font") }
          { this._renderTextSettings("Default Text Font", "p_font") }
          { this._renderTextSettings("Subtitle (H3)", "h3_font") }
          { this._renderTextSettings("Sub Header (H4)", "h4_font") }
          { this._renderTextSettings("Small Sub Header (H5)", "h5_font") }
          { this._renderTextSettings("Buttons", "btn_font") }
        </Collapsible>
      </Border>
    )
  }

  _renderSectionNavToggles(){
    let { website, menu } = this.state

    let selectedMenuStyle = OPTIONS_MENU_STYLES.find(headerStyle => {
      return headerStyle.value === (menu.style || "bar")
    })

    return (
      <Border>
        <Collapsible
          trigger="Menu & Header & Footer"
        >
          <br/>
          <Toggle
            id="header_toggle"
            checked={!website.hide_menu}
            title="Header & Menu Enabled"
            onChange={checked => {
              website.hide_menu = !checked
              this.props.onUpdated(website)
            }}
          />

          {!website.hide_menu &&
            <>
              <span>
                Menu Style
              </span>
              <Dropdown
                options={OPTIONS_MENU_STYLES}
                value={selectedMenuStyle}
                onChange={option => {
                  menu.style = option.value
                  this.setState({ menu })
                  this.props.onMenuUpdated(menu)
                  if(option.value === "hamburger"){
                    General.updateModals()
                  }
                }}
                placeholder="Menu Style"
              />
              <br/><br/>
            </>
          }

          <Toggle
            id="footer_toggle"
            checked={!website.hide_footer}
            title="Footer Enabled"
            onChange={checked => {
              website.hide_footer = !checked
              this.props.onUpdated(website)
            }}
          />
        </Collapsible>
      </Border>
    )
  }

  _renderSectionSEO(){
    let { website } = this.state

    return (
      <Border>
        <h3>SEO</h3>
        <span>
          Description
        </span>
        <textarea
          type="text"
          defaultValue={ website.description }
          onChange={e => {
            website.description = e.target.value
            this.setState({ website })
            this.props.onUpdated(website)
          }}
        />

        <br/>

        <span>
          Keywords
        </span>
        <textarea
          type="text"
          placeholder="Comma,separated,list"
          defaultValue={ website.keywords }
          onChange={e => {
            website.keywords = e.target.value
            this.setState({ website })
            this.props.onUpdated(website)
          }}
        />

        <br/>

        <span>
          Google Analytics
        </span>
        <input
          type="text"
          placeholder="UA-XXXXX-Y"
          defaultValue={ website.google_analytics }
          onChange={e => {
            website.google_analytics = e.target.value
            this.setState({ website })
            this.props.onUpdated(website)
          }}
        />

      </Border>
    )
  }

  _renderSectionDetails(){

    let { website } = this.state

    return (
      <Border>
          <Collapsible
            trigger="Info"
          >
            <br/>
            <span>
              Website Name
            </span>
            <input
              type="text"
              defaultValue={ website.name }
              onChange={e => {
                website.name = e.target.value
                this.setState({ website })
                this.props.onUpdated(website)
              }}
            />

            <br/><br/>

            <span>
              Tag Line
            </span>
            <input
              type="text"
              defaultValue={ website.tag_line }
              onChange={e => {
                website.tag_line = e.target.value
                this.setState({ website })
                this.props.onUpdated(website)
              }}
            />
        </Collapsible>
      </Border>
    )
  }

  render() {
    let {
      show,
      page,
      menu,
      website
     } = this.state

     if(!website || !menu){
       return null
     }

     return (
      <PortalModal
        show={show}
        loadSideMenuFromLeft="true"
        onHide={() => this.props.onHide()}
      >
        <h2 className="mb-0">
          Main Style Settings
        </h2>
        <label className="mb-5">Adjust your website's core style</label>

        { this._renderSectionWebsiteDesignStyle() }
        { this._renderSectionGeneral() }
        { this._renderSectionButton() }
        { this._renderSectionLogo() }
        { this._renderSectionFonts() }
        { this._renderSectionNavToggles() }
        { this._renderSectionDetails() }

      </PortalModal>
    )
  }
}
