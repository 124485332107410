import React from 'react'

import PortalModal from "./PortalModal"
import Backend from '../../utils/Backend'
import Notify from '../../utils/Notify'
import Cart from "../../utils/Cart";

export default class ProductVariantsModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      product: props.product,
      variants: [],
      selectedOptions: [],
      options: []
    }
  }

  componentDidMount(){
    this._load()
  }

  componentWillReceiveProps(nextProps){
    let refresh = false
    if(nextProps.product){
      refresh = nextProps.product.id != this.state.product.id
    }
    this.setState(nextProps, () => {
      if(refresh){
        this._load()
      }
    })
  }

  _load(){
    let {
      product,
    } = this.state

    if(!product){
      return
    }
    this.setState({ loading: true })
    Backend.getProductVariants(product)
    .then(variants => {
      this.setState({
        variants,
        loading: false
      }, () => this._setOptions())
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _setOptions(){
    let {
      variants,
      product,
      options,
    } = this.state

    options = product.options ? product.options.map(optionName => {return {name:optionName, values: []}}) : []

    variants.map(variant => {
      variant.options.map((option, index) => {
        options[index].values.push({name: option})
      })
    })

    options.map(productOption => {
      productOption.values = productOption.values.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.name === value.name
        ))
      )
    })

    this.setState({variants, options})
  }

  _onAddToCartPressed(){
    let {
      product,
      selectedOptions
    } = this.state

    if(selectedOptions.length !== product.options.length){
      Notify.error("Please select all product options")
      return
    }

    selectedOptions = Cart.getVariant(product, selectedOptions)
    this.props.onSelected(selectedOptions)
    this.setState({ selectedOptions: [] })

  }

  _renderAddToCart(){
    let {
      product,
      selectedOptions,
    } = this.state

    let outOfStock = Cart.outOfStock(product, selectedOptions)

    return (
      <>
        { !outOfStock &&
        <a
          className="btn btn--primary"
          style={{
            color: "white",
            borderColor: "#222",
            backgroundColor: "#222"
          }}
          onClick={() => this._onAddToCartPressed()}
        >
          Add To Cart
        </a>
        }
        { outOfStock &&
        <a
          class="btn btn--secondary"
          disabled={true}
          onClick={() => {}}
        >
          <span class="btn__text">
            Out Of Stock
          </span>
        </a>
        }
      </>
    )
  }

  render() {
    let {
      show,
      loading,
      product,
      options,
      variants,
      selectedOptions
    } = this.state

    if(!show){
      return null
    }

    product.variants = variants

    let showAddToCart = Cart.showAddToCart(product, selectedOptions)

    return (
      <PortalModal
        show={show}
        id='icon-picker-modal'
        contentSuperContainerClassName="image-picker-modal"
        onHide={() => this.props.onHide()}
      >
        <h3>{product.name}</h3>

        { loading &&
            <p>Loading</p>
        }
        { !loading &&
          <>
            {
              options.map((productOption, index) => {
                return (
                  <div className="input-select">
                    <select
                      value={selectedOptions[index]}
                      onChange={e => {
                        let selectedOptionName = e.target.value
                        if(selectedOptionName === productOption.name){
                          selectedOptions[index] = null
                        }else{
                          selectedOptions[index] = selectedOptionName
                        }
                        this.setState({ selectedOptions })
                      }}
                    >
                      <option>{productOption.name}</option>
                      {
                        productOption.values.map(option => (
                          <option value={option.name}>{option.name}</option>
                        ))
                      }
                    </select>
                  </div>
                )
              })
            }
          </>
        }

        <div className="c-btn-group text-right mt-5">
          <a
            className="btn btn--secondary"
            style={{
              color: "#222",
              borderColor: "#222",
              backgroundColor: "transparent"
            }}
            onClick={() => this.props.onHide()}
          >
              Cancel
          </a>
          {
            showAddToCart ?
             this._renderAddToCart()
            :
              <>
                <a
                  className="btn btn--primary"
                  style={{
                    color: "black",
                    borderColor: "#ccc",
                    backgroundColor: "#ccc"
                  }}
                >
                  Add To Cart
                </a>
              </>
          }


        </div>
      </PortalModal>
    )
  }
}
