import React from 'react'

import Button from '../Button'
import Collapsible from 'react-collapsible';
import Border from '../Border'
import Product from '../Product'
import AddProduct from '../AddProduct';

import H2 from './editable/H2'
import Img from './editable/Img'
import P from './editable/P'
import Section from './editable/Section'

import ScriptCache from "../../utils/ScriptCache"
import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

const PRODUCTS_NO_OPTIONS = [
  {
    label: "3",
    value: 3
  },
  {
    label: "6",
    value: 6
  },
  {
    label: "9",
    value: 9
  }
]

export default class Block39 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      products: [],
      editable: props.editable
    }

    this.updateContentBlock = General.debounce(() => {
      let data = { ...this.state.data }
      data.selected_products = data.selected_products.map(product => product.id)
      Backend.updateContentBlock(data)
    }, 1000, false)
  }

  componentDidMount(){
    this._load()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _load(){
    let {
      data
    } = this.state

    this.setState({ loading: true })
    Backend.getProducts()
    .then(products => {
      this.setState({ products, loading: false })
    })
    .catch(error => {
      this.setState({ loading: false })
    })
  }

  _update(data, callback){
    this.setState({ ...data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _getModalContent(data){
    let {
      products
    } = this.state
    return (
      <>
        <Border>
          <Collapsible
            trigger="Number of products to be displayed"
          >
            <br/>
            <div className="input-select">
              <select
                value={data.products_no}
                onChange={e => {
                  data.products_no = parseInt(e.target.value)

                  data.selected_products.splice(data.products_no, data.selected_products.length - data.products_no);
                  this._update({
                    data,
                    loading: true
                  }, () => this._load())
                }}
              >
                {
                  PRODUCTS_NO_OPTIONS.map(option => {
                    return (
                      <option value={option.value}>{ option.label }</option>
                    )
                  })
                }
              </select>
            </div>
          </Collapsible>
        </Border>
        { this._renderSelectProducts(data) }
      </>
    )
  }

  _renderSelectProducts(data){
    let {
      products
    } = this.state

    let content = []
    let selectedProductArray = Array.apply(null, Array(data.products_no))

    selectedProductArray.map((selectedProduct, index) => {
      if(index < data.selected_products.length){
        selectedProduct = data.selected_products[index]
      }
      content.push(
        <>
          <label>{`Select Product #${index + 1}`}</label>
          <div className="input-select">
            <select
              value={selectedProduct ? selectedProduct.id : null}
              onChange={e => {
                let id = parseInt(e.target.value)
                let product = products.find(p => p.id === id)

                if(index <= data.selected_products.length - 1){
                  data.selected_products.splice(index, 1, product)
                }
                else{
                  data.selected_products.push(product)
                }

                this._update({
                  data,
                })
              }}
            >
              { selectedProduct == null &&
              <option value="null">Select a Product</option>
              }
              {
                products.map(product => {
                  return (
                    <option value={product.id}>{ product.name }</option>
                  )
                })
              }
            </select>
          </div>
        </>
      )
    })

    return (
     <Border key={data.selected_products.length}>
        <Collapsible
          trigger="Select Products"
        >
          <br/>
          { content }
        </Collapsible>
      </Border>
    )
  }
  _renderProducts(products){
    let {
      loading
    } = this.state

    return products.map(product => {
      return (
        <Product
          key={product.id}
          product={product}
          onAddToCartPressed={(product, variant) => this.props.onAddToCartPressed(product, variant)}
        />
      )
    })
  }

  render(){
    let { loading, data, editable } = this.state

    if(!loading && !editable && data.selected_products.length === 0){
      return null
    }

    let selectedProducts = data.selected_products
      selectedProducts = data.selected_products.filter(product => product.active)
    
    if(!editable && selectedProducts.length == 0){
      return null
    }

    let blockClassName = " block-39 product-blocks  ";
    let viewAllClassName = " mt--2  ";
    if (window.IS_CARD_STYLE_WEBSITE) {
      blockClassName += " deep-cardify";
      viewAllClassName = " dc-mt-1 ";
    }

    return (

        <Section
          data={ data }
          index={ this.props.index }
          className={ blockClassName }
          editable={ editable }
          onUpdated={data => this._update({ data })}
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() => this._getModalContent(data)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
        >
          <div class="container">

            {selectedProducts.length === 0 &&
              <AddProduct/>
            }
            { selectedProducts.length >  0 &&
              <>
                <Titles 
                  data={ data }
                  editable={ editable }
                  onUpdated={data => this._update(data)}
                  rowCssClasses={"dc-mb-1"}
                  alignment={"text-left"}
                  headingNumber={2}
                  headingDefaultText={"Selected Products"}
                />

                <hr className='dc-d-none' />

                {/* starts, blocks  */}
                <div class="row mt-5 dc-row-v3">

                  { this._renderProducts(selectedProducts) }

                </div>
                {/* end, blocks  */}

                <div 
                  className={ viewAllClassName +' text-center ' }
                >
                  <a
                    class="btn btn--primary type--uppercase"
                    href="/shop"
                  >
                    <span class="btn__text">
                      View All Products
                    </span>
                    {/*
                    <span class="label product-sale-price product-sale-price-darken">
                      Sale
                    </span>
                    */}
                  </a>
                </div>
              </>
            }
          </div>
        </Section>

    )
  }
}