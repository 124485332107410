import React from "react";

import html2canvas from "@nidi/html2canvas";
import FetchHelper from "./FetchHelper";
import AuthManager from "./AuthManager";
import General from "./General";
import Event from "./Event";

import $ from "jquery";

export default class Backend {
  static async getDemoContentBlocks() {
    if (
      window.DEMO_CONTENT_BLOCKS != null &&
      window.DEMO_CONTENT_BLOCKS.length > 0
    ) {
      return window.DEMO_CONTENT_BLOCKS;
    }
    return FetchHelper.get(window.Api.DemoContentBlocks, false).then(
      (demoContentBlocks) => {
        window.DEMO_CONTENT_BLOCKS = demoContentBlocks;
        return demoContentBlocks;
      }
    );
  }

  static async getPages(websiteId = window.CURRENT_WEBSITE_ID) {
    if (window.PAGES != null && window.PAGES.length > 0) {
      return window.PAGES;
    }
    return FetchHelper.get(
      `${window.Api.Pages}?website_id=${websiteId}`,
      false
    ).then((pages) => {
      window.PAGES = pages.results;
      return pages.results;
    });
  }

  static getFooter(websiteUrl) {
    let endpoint = `${window.Api.Base}/pages/find/footer?website_url=${websiteUrl}`;
    return FetchHelper.get(endpoint, false);
  }

  static getMenu(websiteUrl) {
    let endpoint = `${window.Api.Base}/pages/find/menu?website_url=${websiteUrl}`;
    return FetchHelper.get(endpoint, false);
  }

  static getPage(websiteUrl, pageSlug) {
    let endpoint = `${window.Api.Base}/pages/find?website_url=${websiteUrl}`;

    if (pageSlug) {
      endpoint += `&page_slug=${pageSlug}`;
    }

    let password = localStorage.getItem("password");
    if (password) {
      endpoint += `&password=${password}`;
    }

    return FetchHelper.get(endpoint, false);
  }

  static async canView(websiteUrl, pageSlug = null) {
    let endpoint = `${window.Api.Base}/pages/find?website_url=${websiteUrl}&viewable=true`;
    if (pageSlug) {
      endpoint += `&page_slug=${pageSlug}`;
    }

    let password = localStorage.getItem("password");
    if (password) {
      endpoint += `&password=${password}`;
    }

    return FetchHelper.get(endpoint, false);
  }

  static async canEdit(websiteUrl, pageSlug = null) {
    let endpoint = `${window.Api.Base}/pages/find?website_url=${websiteUrl}&editable=true`;
    if (pageSlug) {
      endpoint += `&page_slug=${pageSlug}`;
    }

    return AuthManager.silentLogin().then(() => {
      return FetchHelper.get(endpoint);
    });
  }

  static async findWebsite(
    websiteUrl,
    pageSlug,
    articleSlug,
    productSlug,
    packageSlug,
    shop
  ) {
    let endpoint = `${window.Api.Base}/pages/find?website_url=${websiteUrl}`;
    if (pageSlug) {
      endpoint += `&page_slug=${pageSlug}`;
    }
    if (articleSlug) {
      endpoint += `&article_slug=${articleSlug}`;
    }
    if (productSlug) {
      endpoint += `&product_slug=${productSlug}`;
    }
    if (packageSlug) {
      endpoint += `&package_slug=${packageSlug}`;
    }
    if (shop) {
      endpoint += `&shop=true`;
    }

    let password = await localStorage.getItem("password");
    if (password) {
      endpoint += `&password=${password}`;
    }

    let visitorToken = await localStorage.getItem("visitorToken");
    if (visitorToken) {
      endpoint += `&visitor_token=${visitorToken}`;
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const hash = params.get("h");
    if (hash) {
      endpoint += `&h=${hash}`;
      window.history.replaceState(null, "", window.location.pathname);
    }

    const shareToken = params.get("st");

    if (shareToken) {
      endpoint += `&share_token=${shareToken}`;
    }

    return FetchHelper.get(endpoint, false).then((response) => {
      if (response.tokens) {
        AuthManager._updateTokens(response.tokens);
      }
      if (response.visitor_token) {
        localStorage.setItem("visitorToken", response.visitor_token);
      }
      return response;
    });
  }

  static uploadImage(image) {
    let data = new FormData();

    data.append("file", image, image.name);

    data.append("type", "photo");

    return FetchHelper.post(window.Api.Images, data, true);
  }

  static addImage(data, validateTokens = true) {
    if (!data.type) {
      data.type = "photo";
    }
    return FetchHelper.post(window.Api.Images, data, false, validateTokens);
  }

  static getFileContent(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onabort = () => reject({ message: "file reading was aborted" });
      reader.onerror = () => reject({ message: "file reading has failed" });
      reader.onload = () => {
        // Do whatever you want with the file contents
        resolve(reader.result);
      };

      reader.readAsDataURL(file);
    });
  }

  static updateContentBlock(data) {
    data["block_id"] = data.block ? data.block.id : null;

    if (true) {
      //window.CURRENT_PAGE_ID !== 1){
      return this._updateContentBlock(data).catch((error) => {
        General.showNotification(error.message, false);
      });
    }

    let div = $("#" + data.anchor)[0];
    return this._updateContentBlock(data)
      .then((data) => {
        return html2canvas(div, {
          allowTaint: false,
          useCORS: true,
          noCache: true,
          scrollY: -window.scrollY,
        });
      })
      .then((canvas) => {
        let type = "image/png";
        var imgString = canvas.toDataURL(type);
        return Backend.uriToFile(imgString, "image.png", type);
      })
      .then((file) => {
        return Backend.uploadImage(file);
      })
      .then((image) => {
        data.preview_image = image;
        return Backend.updatePreviewImage(data);
      })
      .catch((error) => {
        General.showNotification(error.message, false);
      });
  }

  static _updateContentBlock(data) {
    return FetchHelper.patch(
      `${window.Api.ContentBlocks}/${data.id}`,
      data
    ).then((data) => {
      let subscribed = AuthManager.currentUser.company.subscription?.active;
      let premium = AuthManager.currentUser.company.premium;

      if (!subscribed && premium) {
        Event.emit("SHOW_SUBSCRIPTION_MODAL");
      } else {
        General.showNotification("Saved", true);
      }

      return data;
    });
  }

  static updatePreviewImage(data) {
    let previewData = {
      preview_image_id: data.preview_image ? data.preview_image.id : null,
    };
    return FetchHelper.patch(
      `${window.Api.ContentBlocks}/${data.id}/preview`,
      previewData
    ).then((response) => {
      if (response.is_website_preview) {
        window.CURRENT_PAGE_PREVIEW = data.preview_image;
      }
    });
  }

  static addContentBlock(data) {
    return FetchHelper.post(`${window.Api.ContentBlocks}`, data)
      .then((data) => {
        General.showNotification("New Block Added");
        return data;
      })
      .catch((error) => {
        General.showNotification(error.message, false);
      });
  }

  static deleteContentBlock(contentBlock) {
    return FetchHelper.delete(`${window.Api.ContentBlocks}/${contentBlock.id}`)
      .then((data) => {
        General.showNotification("Deleted");
        return data;
      })
      .catch((error) => {
        General.showNotification(error.message, false);
      });
  }

  static updateOrder(contentBlocks, page_id) {
    let contentBlockIds = contentBlocks.map((contentBlock) => contentBlock.id);

    let data = {
      page_id,
      content_block_ids: contentBlockIds,
    };

    return FetchHelper.patch(`${window.Api.ContentBlocks}/order`, data)
      .then((data) => {
        General.showNotification("Reordered");
        return data;
      })
      .catch((error) => {
        General.showNotification(error.message, false);
      });
  }

  static updateMenu(menu) {
    let data = { ...menu };
    data.website = data.website.id;
    return FetchHelper.patch(`${window.Api.Menus}/${data.website}`, data)
      .then((data) => {
        General.showNotification("Saved");
        return data;
      })
      .catch((error) => {
        General.showNotification(error.message, false);
      });
  }

  static updateFooter(footer) {
    let data = { ...footer };
    return FetchHelper.patch(`${window.Api.Footers}/${data.website}`, data)
      .then((data) => {
        General.showNotification("Saved");
        return data;
      })
      .catch((error) => {
        General.showNotification(error.message, false);
      });
  }

  static updateWebsite(website) {
    let data = Backend._getWebsiteData(website);

    return FetchHelper.patch(`${window.Api.Websites}/${data.slug}`, data)
      .then((data) => {
        General.showNotification("Saved");
        return data;
      })
      .catch((error) => {
        General.showNotification(error.message, false);
      });
  }

  static _getWebsiteData(website) {
    let data = { ...website };
    let tags = ["h1", "h2", "h3", "h4", "h5", "h6", "menu", "btn", "p"];

    for (var i = 0; i < tags.length; i++) {
      let key = tags[i] + "_font";
      if (!data[key] || Object.keys(data[key]).length == 0) {
        data[key] = null;
      }
    }

    delete data["url"];

    return data;
  }

  static getForms(websiteId) {
    return FetchHelper.get(`${window.Api.Forms}?website_id=${websiteId}`).then(
      (response) => {
        return response.results;
      }
    );
  }

  static updateForm(data) {
    return FetchHelper.patch(`${window.Api.Forms}/${data.id}`, data)
      .then((data) => {
        General.showNotification("Saved");
        return data;
      })
      .catch((error) => {
        General.showNotification(error.message, false);
      });
  }

  static addForm(formData) {
    return FetchHelper.post(`${window.Api.Forms}`, formData);
  }

  static uriToFile(uri, filename, type) {
    return fetch(uri)
      .then((res) => res.blob())
      .then((blob) => {
        return new File([blob], filename, { type });
      });
  }

  static getShop(websiteUrl) {
    let endpoint = `${window.Api.Base}/shops/find?website_url=${websiteUrl}`;
    return FetchHelper.get(endpoint, false);
  }

  static getProductCategories() {
    let shop_id = window.CURRENT_WEBSITE_ID;
    return FetchHelper.get(
      `${window.Api.Base}/product-categories?shop_id=${shop_id}&paginated=false`,
      false
    );
  }

  static getProduct(productSlug, websiteUrl) {
    let endpoint = `${window.Api.Base}/products/find?product_slug=${productSlug}&website_url=${websiteUrl}`;
    return FetchHelper.get(endpoint, false);
  }

  static getProducts(pageSize = null, productCategory = null) {
    let endpoint = `${window.Api.Base}/products?shop_id=${window.CURRENT_WEBSITE_ID}&paginated=false&active=true`;
    if (pageSize) {
      endpoint += `&page_size=${pageSize}`;
    }
    if (productCategory) {
      endpoint += `&category_id=${productCategory.id}`;
    }
    return FetchHelper.get(endpoint, false);
  }

  static checkout(cart) {
    let data = this._getCheckoutData(cart);
    return FetchHelper.post(`${window.Api.Base}/sales`, data, false, false);
  }

  static _getCheckoutData(cart) {
    let data = { ...cart };
    data.shop = window.CURRENT_WEBSITE_ID;

    data.items = data.items.map((item) => {
      return {
        product: item.product.id,
        quantity: item.quantity,
        cart_item_uuid: item.cart_item_uuid,
        variant: item.variant ? item.variant.id : null,
      };
    });

    return data;
  }

  static getProductVariants(product) {
    return FetchHelper.get(
      `${window.Api.Base}/product-variants?product_id=${product.id}&paginated=false`,
      false
    );
  }

  static createLoginHash() {
    return FetchHelper.post(`${window.Api.Hashes}`, { type: "login" });
  }

  static createContact(data) {
    data.website = window.CURRENT_WEBSITE_ID;
    return FetchHelper.post(`${window.Api.Contacts}`, data, false, false);
  }

  static markOnboarded(website) {
    return FetchHelper.patch(`${window.Api.Websites}/${website.slug}`, {
      onboarded: true,
    });
  }

  static getArticles() {
    return FetchHelper.get(
      `${window.Api.Articles}?website_id=${window.CURRENT_WEBSITE_ID}&paginated=false&page_size=3&order_by=-published_at`,
      false
    );
  }

  static getArticle(articleSlug, websiteUrl) {
    return FetchHelper.get(
      `${window.Api.Articles}/find?article_slug=${articleSlug}&website_url=${websiteUrl}`,
      false
    );
  }

  static getPackages() {
    return FetchHelper.get(
      `${window.Api.Packages}?partner_id=${window.CURRENT_COMPANY_ID}&paginated=false&page_size=3&order_by=recurring_amount&active=true&featured=true`,
      false
    );
  }

  static getPackage(packageSlug, websiteUrl) {
    return FetchHelper.get(
      `${window.Api.Packages}/find?package_slug=${packageSlug}&website_url=${websiteUrl}&active=true`,
      false
    );
  }

  static slugify(name) {
    return FetchHelper.post(
      `${window.Api.Websites}/slugify`,
      { name },
      false,
      false
    );
  }

  static uploadFile(file, endpoint, params) {
    let data = new FormData();

    data.append("file", file, file.name);
    for (var key in params) {
      data.append(key, params[key]);
    }
    console.log("aaa", endpoint);
    return FetchHelper.post(endpoint, data, true, false);
  }

  static uploadFont(file) {
    let data = new FormData();

    data.append("file", file, file.name);

    data.append(
      "family_name",
      file.name
        .replace(".ttf", "")
        .replace(".otf", "")
        .replace(".woff", "")
        .replace(".woff2", "")
        .replace(".eot", "")
    );

    data.append("website", window.CURRENT_WEBSITE_ID);

    return FetchHelper.post(window.Api.Fonts, data, true);
  }

  static getMessages() {
    return FetchHelper.get(
      `${window.Api.Messages}?visitor_token=${window.CURRENT_VISITOR_TOKEN}&paginated=false`,
      false
    ).then((response) => response.results);
  }

  static FORMS = [
    {
      id: 10,
      website_id: 2,
      form_fields: [
        [
          {
            id: 66,
            options: null,
            option: null,
            title: "First Name",
            placeholder: null,
            type: "text",
            quantity: null,
            is_unique: false,
            is_required: false,
            send_emails: null,
            alignment: "horizontal",
            form: 10,
          },
          {
            id: 67,
            options: null,
            option: null,
            title: "Last Name",
            placeholder: null,
            type: "text",
            quantity: null,
            is_unique: false,
            is_required: false,
            send_emails: null,
            alignment: "horizontal",
            form: 10,
          },
        ],
        [
          {
            id: 68,
            options: null,
            option: null,
            title: "Email Address",
            placeholder: null,
            type: "email",
            quantity: null,
            is_unique: false,
            is_required: false,
            send_emails: true,
            alignment: "horizontal",
            form: 10,
          },
          {
            id: 69,
            options: null,
            option: null,
            title: "Phone",
            placeholder: null,
            type: "phone",
            quantity: null,
            is_unique: false,
            is_required: false,
            send_emails: null,
            alignment: "horizontal",
            form: 10,
          },
        ],
        [
          {
            id: 70,
            options: [
              {
                id: 129,
                field_id: 70,
                value_from: null,
                value_to: null,
                text: "Large",
              },
              {
                id: 130,
                field_id: 70,
                value_from: null,
                value_to: null,
                text: "Medium",
              },
              {
                id: 131,
                field_id: 70,
                value_from: null,
                value_to: null,
                text: "Small",
              },
            ],
            option: null,
            title: "Select An Option",
            placeholder: null,
            type: "single_select",
            quantity: null,
            is_unique: false,
            is_required: false,
            send_emails: null,
            alignment: "vertical",
            form: 10,
          },
        ],
        [
          {
            id: 71,
            options: null,
            option: null,
            title: "Notes / Requirements",
            placeholder: null,
            type: "textarea",
            quantity: null,
            is_unique: false,
            is_required: false,
            send_emails: null,
            alignment: "horizontal",
            form: 10,
          },
        ],
        [
          {
            id: 72,
            options: null,
            option: null,
            title: "I Would Like To Attend",
            placeholder: null,
            type: "label",
            quantity: null,
            is_unique: false,
            is_required: false,
            send_emails: null,
            alignment: "horizontal",
            form: 10,
          },
        ],
        [
          {
            id: 73,
            options: [
              {
                id: 132,
                field_id: 73,
                value_from: null,
                value_to: null,
                text: "Event C",
              },
              {
                id: 133,
                field_id: 73,
                value_from: null,
                value_to: null,
                text: "Event B",
              },
              {
                id: 134,
                field_id: 73,
                value_from: null,
                value_to: null,
                text: "Event A",
              },
            ],
            option: null,
            title: null,
            placeholder: null,
            type: "single_select",
            quantity: null,
            is_unique: false,
            is_required: false,
            send_emails: null,
            alignment: "horizontal",
            form: 10,
          },
        ],
        [
          {
            id: 74,
            options: null,
            option: null,
            title: "Dietery Requirements",
            placeholder: null,
            type: "label",
            quantity: null,
            is_unique: false,
            is_required: false,
            send_emails: null,
            alignment: "horizontal",
            form: 10,
          },
        ],
        [
          {
            id: 75,
            options: [
              {
                id: 135,
                field_id: 75,
                value_from: null,
                value_to: null,
                text: "Vegeterian",
              },
              {
                id: 136,
                field_id: 75,
                value_from: null,
                value_to: null,
                text: "Gluten Free",
              },
              {
                id: 137,
                field_id: 75,
                value_from: null,
                value_to: null,
                text: "Peanut Free",
              },
              {
                id: 138,
                field_id: 75,
                value_from: null,
                value_to: null,
                text: "Dairy Free",
              },
            ],
            option: null,
            title: null,
            placeholder: null,
            type: "multi_select",
            quantity: null,
            is_unique: false,
            is_required: false,
            send_emails: null,
            alignment: "horizontal",
            form: 10,
          },
        ],
        [
          {
            id: 76,
            options: null,
            option: null,
            title: "RSVP",
            placeholder: null,
            type: "label",
            quantity: null,
            is_unique: false,
            is_required: false,
            send_emails: null,
            alignment: "horizontal",
            form: 10,
          },
        ],
        [
          {
            id: 77,
            options: null,
            option: null,
            title: "I RSVP",
            placeholder: null,
            type: "boolean",
            quantity: null,
            is_unique: false,
            is_required: false,
            send_emails: null,
            alignment: "horizontal",
            form: 10,
          },
        ],
      ],
      name: "Signup Form",
      body: null,
      submit_text: "REGISTER",
      terms_text: null,
      force_invite: false,
      count_no: 0,
      send_confirmation_email: true,
      allow_updates: true,
      send_updated_email: true,
      updated_at: "2019-08-18T14:28:36.814956Z",
      created_at: "2019-08-18T14:28:36.814988Z",
    },
    {
      id: 8,
      website_id: 2,
      form_fields: [
        [
          {
            id: 66,
            options: null,
            option: null,
            title: "Full Name",
            placeholder: null,
            type: "text",
            quantity: null,
            is_unique: false,
            is_required: false,
            send_emails: null,
            alignment: "horizontal",
            form: 10,
          },
        ],
        [
          {
            id: 66,
            options: null,
            option: null,
            title: "Company Name",
            placeholder: null,
            type: "text",
            quantity: null,
            is_unique: false,
            is_required: false,
            send_emails: null,
            alignment: "horizontal",
            form: 10,
          },
        ],
        [
          {
            id: 66,
            options: null,
            option: null,
            title: "Job Title",
            placeholder: null,
            type: "text",
            quantity: null,
            is_unique: false,
            is_required: false,
            send_emails: null,
            alignment: "horizontal",
            form: 10,
          },
        ],
      ],
      name: "Session C",
      body: null,
      submit_text: "REGISTER",
      terms_text:
        "Some terms text that a client wants to make sure an attendee has agreed to",
      force_invite: false,
      count_no: 0,
      send_confirmation_email: true,
      allow_updates: true,
      send_updated_email: true,
      updated_at: "2019-08-15T07:04:14.310992Z",
      created_at: "2019-08-08T17:14:32.707133Z",
    },
    {
      id: 7,
      website_id: 2,
      form_fields: [],
      name: "Session B",
      body: null,
      submit_text: "REGISTER",
      terms_text:
        "Some terms text that a client wants to make sure an attendee has agreed to",
      force_invite: false,
      count_no: 0,
      send_confirmation_email: true,
      allow_updates: true,
      send_updated_email: true,
      updated_at: "2019-08-15T07:04:00.759084Z",
      created_at: "2019-08-08T17:14:32.707133Z",
    },
    {
      id: 4,
      website_id: 2,
      form_fields: [],
      name: "Session A",
      body: null,
      submit_text: "REGISTER",
      terms_text: null,
      force_invite: false,
      count_no: 0,
      send_confirmation_email: true,
      allow_updates: true,
      send_updated_email: true,
      updated_at: "2019-08-08T09:02:02.407331Z",
      created_at: "2019-08-08T09:02:02.407378Z",
    },
  ];

  static getCRMForm(websiteId) {
    let url = `${window.Api.Forms}?website_id=${websiteId}&type=crm`;
    return FetchHelper.get(url, false).then((response) => {
      return response.results;
    });
  }

  static submitForm(formData) {
    return FetchHelper.post(window.Api.Submissions, formData, false, false);
  }

  static getCoupon(couponCode, website) {
    console.log("fecth the coupon");
    return FetchHelper.get(
      `${window.Api.Vouchers}/${couponCode}?website_id=${website.id}`,
      false
    );
  }

  static getJob(slug, businessSlug){
    return FetchHelper.get(
      `${window.Api.Jobs}/${slug}?business_slug=${businessSlug}`,
      false
    );
  }

  static saveExternalJobApplication(job) {
    return FetchHelper.post(`${window.Api.Jobs}/${job.id}/external-application-click`, {}, false, false)
  }
}
